/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import UUID from './UUID.js'

function Role(source) {
  if (source instanceof Role) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = UUID.generate()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().getTime()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    return Immutable(this, { prototype: Role.prototype })
  }
}

Role.prototype.constructor = Role

Role.ADMIN = 'ADMIN'
Role.BARMAN = 'BARMAN'
Role.CASHIER = 'CASHIER'
Role.COOK = 'COOK'
Role.MANAGER = 'MANAGER'
Role.OWNER = 'OWNER'
Role.WAITER = 'WAITER'
Role.COURIER = 'COURIER'

Role.getAll = function getAllRoles() {
  return [
    Role.ADMIN,
    Role.BARMAN,
    Role.CASHIER,
    Role.COOK,
    Role.MANAGER,
    Role.OWNER,
    Role.WAITER,
    Role.COURIER,
  ]
}

export default Role
