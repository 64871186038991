/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import Modal from '@waiterio/components/Modal.js'
import ModalHeaderWhite from '@waiterio/components/ModalHeaderWhite.js'
import loader from '../styles/loader.js'

const LoadingDialog = ({ message, title }) => (
  <Modal>
    <ModalHeaderWhite>{title}</ModalHeaderWhite>
    <div css={{ display: 'flex', color: '#ababab', minHeight: 80 }}>
      <div
        css={[
          loader,
          {
            alignSelf: 'center',
            height: 40,
            width: 40,
            minWidth: 40,
            margin: 20,
          },
        ]}
        style={{ minHeight: 40 }}
      />
      <div
        css={{
          display: 'flex',
          alignSelf: 'center',
          alignItems: 'center',
          textAlign: 'left',
          width: 200,
          whiteSpace: 'pre-line',
          padding: 16,
          paddingLeft: 0,
        }}
      >
        {message}
      </div>
    </div>
  </Modal>
)

export default LoadingDialog
