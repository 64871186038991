/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'
import card from '@waiterio/styles/card.js'

export default css(card, {
  display: 'flex',
  color: 'white',
  background: 'var(--color-primary)',
  height: 56,
  alignItems: 'stretch',
})
