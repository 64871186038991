/* Copyright 2013 - 2024 Waiterio LLC */
import isAndroidNative from './isAndroidNative.js'
import isElectronNative from './isElectronNative.js'
import isWebkitNative from './isWebkitNative.js'
import sendMessage from './sendMessage.js'

const getTapToPayCapability = () => {
  if (isAndroidNative()) {
    window.android?.getTapToPayCapability?.()
  } else if (isElectronNative()) {
    return window.electron?.getTapToPayCapability?.()
  } else if (isWebkitNative()) {
    sendMessage({
      type: 'getTapToPayCapability',
    })
  }
}

export default getTapToPayCapability
