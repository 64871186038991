/* Copyright 2013 - 2024 Waiterio LLC */
// import getCookie from '@monorepo/shared/getCookie.js'
import setCookie from '@monorepo/shared/setCookie.js'

function getJsonFromUrl(query) {
  let result = {}
  query?.split('&')?.forEach(part => {
    try {
      let item = part.split('=')
      result[item[0]] = decodeURIComponent(item[1])
    } catch (error) {
      console.error(error)
    }
  })
  return result
}

export default async function onSetReferrer(data) {
  try {
    // console.log('onSetReferrer data', data)
    // alert('onSetReferrer data: ' + JSON.stringify(data))
    data ||= {}
    let { referrer } = data

    if (referrer) {
      let referrerJson = getJsonFromUrl(referrer)
      if (referrerJson.referrers) {
        let referrersCookie = referrerJson.referrers
        let splits = referrersCookie.split?.(',') ?? []

        let referrerCookie = splits.length > 0 ? splits[0] : referrer

        if (referrerCookie) {
          // alert('referrerCookie: ' + referrerCookie)
          setCookie('referrer', referrerCookie)
          // alert(`getCookie referrer '${getCookie('referrer')}'`)
        }
        if (referrersCookie) {
          // alert('referrersCookie: ' + referrersCookie)
          setCookie('referrers', referrersCookie)
          // alert(`getCookie referrers '${getCookie('referrers')}'`)
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
}
