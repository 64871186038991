/* Copyright 2013 - 2024 Waiterio LLC */
function Device(source) {
  Object.keys(source || {}).forEach(key => {
    this[key] = source[key]
  })

  if (!this.creationTime) {
    this.creationTime = 0
  }

  if (!this.lastEditTime) {
    this.lastEditTime = this.creationTime || 0
  }

  if (!this.networkAdapters) {
    this.networkAdapters = []
  }
}

Device.prototype.constructor = Device

Device.Type = {}
Device.Type.ANDROID_SMARTPHONE = 'android_smartphone'
Device.Type.ANDROID_TABLET = 'android_tablet'
Device.Type.IPHONE = 'iphone'
Device.Type.IPAD = 'ipad'

Device.OS = {}
Device.OS.ANDROID = 'Android'
Device.OS.IOS = 'iOS'
Device.OS.MACOS = 'macOS'
Device.OS.WINDOWS = 'Windows'
Device.OS.LINUX = 'Linux'
Device.OS.CHROME_OS = 'Chrome OS'

Device.Size = {}
Device.Size.DESKTOP = 'desktop'
Device.Size.SMARTPHONE = 'smartphone'
Device.Size.TABLET = 'tablet'
Device.Size.TV = 'tv'

export default Device
