/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'
import singleLine from '@waiterio/styles/singleLine.js'

export default css`
  label: fieldLabel;
  display: flex;
  ${singleLine};
  flex-shrink: 0;
  padding-left: 16px;
  padding-right: 8px;
  line-height: 48px;
  cursor: inherit;
`
