/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { PureComponent } from 'react'
import { jsx } from '@emotion/react'
import IconBack from '@stiloso/icons/IconBack.js'
import IconClose from '@stiloso/icons/IconClose.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import IconDiscount from '@stiloso/icons/IconDiscount.js'
import IconSearch from '@stiloso/icons/IconSearch.js'
import singleLine from '@waiterio/styles/singleLine.js'
import toolbar from '../styles/toolbar.js'
import toolbarButton from '../styles/toolbarButton.js'
import toolbarIcon from '../styles/toolbarIcon.js'
import getBrowserHistory from '../getBrowserHistory.js'

export default class ActionBar extends PureComponent {
  onClickTitle = event => {
    event.preventDefault()
    event.stopPropagation()

    if (this.props.onClickTitle) {
      this.props.onClickTitle()
    } else if (!this.props.href) {
      getBrowserHistory().goBack()
    } else {
      getBrowserHistory().push(this.props.href)
    }
  }

  render() {
    let actions = []

    if (this.props.action) {
      actions = actions.concat(this.props.action)
    }

    if (this.props.actions) {
      actions = actions.concat(this.props.actions)
    }

    const iconTitle = this.props.iconTitle || 'back'

    return (
      <div css={toolbar}>
        <div css={toolbarButton} onClick={this.onClickTitle}>
          {iconTitle && iconTitle === 'back' && (
            <IconBack
              css={{ margin: 8, width: 24, height: 24, fill: 'white' }}
            />
          )}
          {iconTitle && iconTitle === 'close' && (
            <IconClose css={{ margin: 8, fill: 'white' }} />
          )}
          <span css={singleLine}>{this.props.title}</span>
        </div>
        <div css={{ flexGrow: 1 }} />
        {actions.map(icon => {
          if (icon === 'delete') {
            return (
              <div
                key="delete"
                css={toolbarIcon}
                onClick={this.props.onClickAction}
              >
                <IconDelete css={{ fill: 'white' }} />
              </div>
            )
          } else if (icon === 'discount') {
            return (
              <div
                key="discount"
                css={toolbarIcon}
                onClick={this.props.onClickAction}
              >
                <IconDiscount css={{ fill: 'white' }} />
              </div>
            )
          } else if (icon === 'search') {
            return (
              <div
                key="search"
                css={toolbarIcon}
                onClick={this.props.onClickAction}
              >
                <IconSearch css={{ fill: 'white' }} />
              </div>
            )
          } else {
            return false
          }
        })}
      </div>
    )
  }
}
