/* Copyright 2013 - 2024 Waiterio LLC */
import ENVIRONMENT from '@monorepo/env/ENVIRONMENT.js'
import DEVELOPMENT from '@monorepo/env/DEVELOPMENT.js'
import STAGING from '@monorepo/env/STAGING.js'
import PRODUCTION from '@monorepo/env/PRODUCTION.js'

export default function getGoogleClientId() {
  let googleClientId = null

  switch (ENVIRONMENT) {
    case DEVELOPMENT:
      googleClientId =
        '1060829256082-pu18vu04tjfls8s2u2m601bs8a2khvhi.apps.googleusercontent.com'
      break

    case STAGING:
      googleClientId =
        '1060829256082-bk5g5lg5rt7eneaqiu92uj5h46ql1osq.apps.googleusercontent.com'
      break

    case PRODUCTION:
      googleClientId =
        '1060829256082-2bbd70msnqis0hokttp6qru8mp0n4uhe.apps.googleusercontent.com'
      break
  }

  return googleClientId
}
