/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconAndroid = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-android'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M6 18c0 0.552 0.456 1.008 1.008 1.008h0.984v3.504c0 0.816 0.672 1.488 1.512 1.488s1.488-0.672 1.488-1.488v-3.504h2.016v3.504c0 0.816 0.672 1.488 1.488 1.488s1.512-0.672 1.512-1.488v-3.504h0.984c0.552 0 1.008-0.456 1.008-1.008v-10.008h-12v10.008zM3.504 7.992c-0.84 0-1.512 0.672-1.512 1.512v6.984c0 0.84 0.672 1.512 1.512 1.512s1.488-0.672 1.488-1.512v-6.984c0-0.84-0.672-1.512-1.488-1.512zM20.496 7.992c-0.816 0-1.488 0.672-1.488 1.512v6.984c0 0.84 0.672 1.512 1.488 1.512s1.512-0.672 1.512-1.512v-6.984c0-0.84-0.672-1.512-1.512-1.512zM15.528 2.16l1.32-1.296c0.192-0.216 0.192-0.528 0-0.72-0.216-0.192-0.528-0.192-0.72 0l-1.488 1.488c-0.792-0.408-1.68-0.624-2.64-0.624s-1.848 0.216-2.664 0.624l-1.488-1.488c-0.192-0.192-0.504-0.192-0.696 0s-0.192 0.504 0 0.696l1.296 1.32c-1.488 1.104-2.448 2.856-2.448 4.848h12c0-1.992-0.984-3.768-2.472-4.848zM10.008 4.992h-1.008v-0.984h1.008v0.984zM15 4.992h-1.008v-0.984h1.008v0.984z" />
    </svg>
  )
}

export default IconAndroid
