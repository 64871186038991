/* Copyright 2013 - 2024 Waiterio LLC */
export default function getDisplay() {
  let display = {
    lg: false,
    md: false,
    sm: false,
    xs: false,

    isDesktopSize: false,
    isSmartphoneSize: false,
  }

  if (typeof window !== 'undefined') {
    display.landscape = window.innerWidth >= window.innerHeight
    display.portrait = window.innerHeight > window.innerWidth

    if (window.matchMedia) {
      if (window.matchMedia('(min-width: 1201px)').matches) {
        display.lg = true
        display.isDesktopSize = true
      } else if (
        window.matchMedia('(min-width: 993px) and (max-width: 1200px)').matches
      ) {
        display.md = true
      } else if (
        window.matchMedia('(min-width: 769px) and (max-width: 992px)').matches
      ) {
        display.sm = true
      } else if (window.matchMedia('(max-width: 768px)').matches) {
        display.xs = true
        display.isSmartphoneSize = true
      }

      display.retina =
        window?.displayPixelRatio > 1 ||
        (window.matchMedia &&
          window.matchMedia(
            '(-webkit-min-display-pixel-ratio: 1.5),(-moz-min-display-pixel-ratio: 1.5),(min-display-pixel-ratio: 1.5),(min-resolution: 192dpi),(min-resolution: 2dppx)',
          ).matches)
    }
  }

  return display
}
