/* Copyright 2013 - 2024 Waiterio LLC */
import post from '@waiterio/api-client/post.js'

export default function addItem(restaurantId, menuId, categoryId, item) {
  return post({
    url: 'menus/' + menuId + '/categories/' + categoryId + '/items',
    body: item,
  }).catch(error => {
    throw new Error(`Couldn't add item\n${error}`)
  })
}
