/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import Printer from '@waiterio/model/Printer.js'

const prefix = 'waiterio.printers.'

export function plantPrinter(printer) {
  return localForage.setItem(prefix + printer._id, printer).then(printer => {
    printer = new Printer(printer)

    produce(draft => {
      draft.data.printers[printer._id] = printer
    })

    return printer
  })
}

export function burnPrinter(printerId) {
  return localForage.removeItem(prefix + printerId).then(() => {
    produce(draft => {
      delete draft.data.printers[printerId]
    })

    return printerId
  })
}

export function pickPrinter(printerId) {
  return localForage
    .getItem(prefix + printerId)
    .then(printer => (printer ? new Printer(printer) : null))
}

export function pickPrinters() {
  return localForage
    .keys()
    .then(keyss =>
      Promise.all(
        keyss
          .filter(key => key.indexOf(prefix) > -1)
          .map(key =>
            localForage.getItem(key).then(printer => new Printer(printer)),
          ),
      ),
    )
}
