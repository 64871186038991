/* Copyright 2013 - 2024 Waiterio LLC */
import { getContext, produce } from '@monorepo/context/index.js'
import { setTokenForApiClient } from '@waiterio/api-client/token.js'
import { pickInvites } from '../forage/InvitesForage.js'
import { pickMaps } from '../forage/MapsForage.js'
import { pickMeals } from '../forage/MealsForage.js'
import { pickMenus } from '../forage/MenusForage.js'
import { pickPayments } from '../forage/PaymentsForage.js'
import { pickPlans } from '../forage/PlansForage.js'
import { pickPrinters } from '../forage/PrintersForage.js'
import { pickRestaurants } from '../forage/RestaurantsForage.js'
import { pickRoles } from '../forage/RolesForage.js'
import { pickSubscriptions } from '../forage/SubscriptionsForage.js'
import { pickUsers } from '../forage/UsersForage.js'
import { pickTerminals } from '../forage/TerminalsForage.js'
import getDefaultMenuId from './getDefaultMenuId.js'
import getCurrentRestaurantId from './getCurrentRestaurantId.js'
import getCurrentUserId from './getCurrentUserId.js'
import getWaiterioToken from './getWaiterioToken.js'

function arrayToMapOfIds(array) {
  return array.reduce((mapp, current) => {
    mapp[current._id] = current
    return mapp
  }, {})
}

export default async function rehydrate() {
  try {
    const [
      invites,
      maps,
      meals,
      menus,
      payments,
      plans,
      printers,
      restaurants,
      roles,
      subscriptions,
      terminals,
      users,
    ] = await Promise.all([
      pickInvites(),
      pickMaps(),
      pickMeals(),
      pickMenus(),
      pickPayments(),
      pickPlans(),
      pickPrinters(),
      pickRestaurants(),
      pickRoles(),
      pickSubscriptions(),
      pickTerminals(),
      pickUsers(),
    ])

    let waiterioToken = getWaiterioToken()

    let { data } = getContext()

    produce(draft => {
      draft.data.invites = { ...data.invites, ...arrayToMapOfIds(invites) }
      draft.data.maps = { ...data.maps, ...arrayToMapOfIds(maps) }
      draft.data.meals = { ...data.meals, ...arrayToMapOfIds(meals) }
      draft.data.menus = { ...data.menus, ...arrayToMapOfIds(menus) }
      draft.data.plans = { ...data.plans, ...arrayToMapOfIds(plans) }
      draft.data.payments = { ...data.payments, ...arrayToMapOfIds(payments) }
      draft.data.printers = { ...data.printers, ...arrayToMapOfIds(printers) }
      draft.data.restaurants = {
        ...data.restaurants,
        ...arrayToMapOfIds(restaurants),
      }
      draft.data.roles = { ...data.roles, ...arrayToMapOfIds(roles) }
      draft.data.subscriptions = {
        ...data.subscriptions,
        ...arrayToMapOfIds(subscriptions),
      }
      draft.data.users = { ...data.users, ...arrayToMapOfIds(users) }
      draft.data.terminals = {
        ...data.terminals,
        ...arrayToMapOfIds(terminals),
      }

      draft.session.defaultMenuId = getDefaultMenuId()
      draft.session.currentRestaurantId = getCurrentRestaurantId()
      draft.session.currentUserId = getCurrentUserId()
      draft.session.waiterioToken = waiterioToken
      draft.session.rehydrated = true
    })

    setTokenForApiClient(waiterioToken)
  } catch (error) {
    console.error(error)
  }
}
