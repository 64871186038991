/* Copyright 2013 - 2024 Waiterio LLC */
import post from '@waiterio/api-client/post.js'

export default function updateMenuWithFile(menuId, file) {
  return post({ url: 'menus/' + menuId + '/imports', body: [file] }).catch(
    error => {
      throw new Error(`Couldn't update menu with file\n${error}`)
    },
  )
}
