/* Copyright 2013 - 2024 Waiterio LLC */
import delete_ from '@waiterio/api-client/delete_.js'

export default function deleteItem(restaurantId, menuId, categoryId, itemId) {
  return delete_({
    url: 'menus/' + menuId + '/categories/' + categoryId + '/items/' + itemId,
  }).catch(error => {
    throw new Error(`Couldn't delete item\n${error}`)
  })
}
