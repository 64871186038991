/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import Map_ from '@waiterio/model/Map_.js'

const prefix = 'waiterio.maps.'

export function plantMap(map_) {
  return localForage.setItem(prefix + map_._id, map_).then(map_ => {
    map_ = new Map_(map_)

    produce(draft => {
      draft.data.maps[map_._id] = map_
    })

    return map_
  })
}

export function burnMap(mapId) {
  return localForage.removeItem(prefix + mapId).then(() => {
    produce(draft => {
      delete draft.data.maps[mapId]
    })

    return mapId
  })
}

export function pickMap(mapId) {
  if (mapId) {
    return localForage
      .getItem(prefix + mapId)
      .then(map_ => (map_ ? new Map_(map_) : null))
  } else {
    return pickMaps().then(maps => (maps && maps.length > 0 ? maps[0] : null))
  }
}

export function pickMaps() {
  return localForage
    .keys()
    .then(keyss =>
      Promise.all(
        keyss
          .filter(key => key.indexOf(prefix) > -1)
          .map(key => localForage.getItem(key).then(map_ => new Map_(map_))),
      ),
    )
}
