/* Copyright 2013 - 2024 Waiterio LLC */
import failureLoginWithPIN from '@waiterio/api-client/failureLoginWithPIN.js'
import post from '@waiterio/api-client/post.js'

export default function loginWithPIN(loginRequest) {
  return post(
    { url: 'authentication', body: loginRequest },
    failureLoginWithPIN,
  )
}
