/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { Provider } from '@monorepo/context/index.js'
import StageBadge from '@waiterio/components/StageBadge.js'
import GlobalStyles from '@waiterio/components/GlobalStyles.js'
import ReferrerCookie from '@waiterio/components/ReferrerCookie.js'
import ThemeInjector from './components/ThemeInjector.js'
import { identify } from './services/Analytics.js'
import listenForUpdates from './services/listenForUpdates.js'
import listenForConnectionChanges from './services/listenForConnectionChanges.js'
import listenForWindowResize from './services/listenForWindowResize.js'
import isLoggedInSession from './session/isLoggedInSession.js'
import rehydrateTranslations from './translate/rehydrate.js'
import rehydrateSession from './session/rehydrateSession.js'
import Routes from './Routes.js'
import getColorPrimaryFromFlavor from './styles/getColorPrimaryFromFlavor.js'
import flavor from './device/flavor.js'
import nativeVersion from './device/nativeVersion.js'

function onContextProvided() {
  listenForUpdates()
  listenForConnectionChanges()
  listenForWindowResize()

  if (typeof window !== 'undefined' && nativeVersion) {
    window.$globo ||= []
    window.$globo.push(['setVersionNative', nativeVersion])
  }

  rehydrateTranslations()

  if (isLoggedInSession()) {
    identify()
    rehydrateSession()
  }
}

const colorPrimary = getColorPrimaryFromFlavor(flavor)

const App = ({ initialContext }) => (
  <>
    <GlobalStyles colorPrimary={colorPrimary} />
    <Provider initialContext={initialContext} callback={onContextProvided}>
      <ThemeInjector />
      <Routes />
    </Provider>
    <ReferrerCookie />
    <StageBadge />
  </>
)

export default App
