/* Copyright 2013 - 2024 Waiterio LLC */
import isAndroidNative from './isAndroidNative.js'
import isElectronNative from './isElectronNative.js'
import isWebkitNative from './isWebkitNative.js'
import sendMessage from './sendMessage.js'

export default metadata => {
  if (isAndroidNative()) {
    window.android?.identify?.(JSON.stringify(metadata))
  } else if (isElectronNative()) {
    window.electron?.identify?.(metadata)
  } else if (isWebkitNative()) {
    sendMessage({
      type: 'identify',
      ...metadata,
    })
  }
}
