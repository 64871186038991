/* Copyright 2013 - 2024 Waiterio LLC */
import onReceiveMessage from './onReceiveMessage.js'

export default function defineGlobalNative() {
  if (typeof global !== 'undefined') {
    global.NativeMessageHandler?.queue?.forEach?.(message =>
      onReceiveMessage(message),
    )
    global.NativeMessageHandler = {
      onReceiveMessage,
    }
  } else if (typeof window !== 'undefined') {
    window.NativeMessageHandler?.queue?.forEach?.(message =>
      onReceiveMessage(message),
    )
    window.NativeMessageHandler = {
      onReceiveMessage,
    }
  }
}
