/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import Table from './Table.js'
import UUID from './UUID.js'

function Room(source) {
  if (source instanceof Room) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this.id) {
      this.id = UUID.generate()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().getTime()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (!this.tables) {
      this.tables = []
    }

    if (this.tables) {
      for (let i = 0; i < this.tables.length; i += 1) {
        this.tables[i] = new Table(this.tables[i])
      }
    }

    return Immutable(this, { prototype: Room.prototype })
  }
}

Room.prototype.getTableIndex = function getTableIndex(tableId) {
  let index = null
  let found = false
  let currentIndex = 0

  if (this.tables) {
    while (currentIndex < this.tables.length && !found) {
      if (this.tables[currentIndex].id === tableId) {
        found = true
        index = currentIndex
      }

      currentIndex += 1
    }
  }

  return index
}

Room.prototype.getTable = function getTable(tableId) {
  let table = null

  const indexOldTable = this.getTableIndex(tableId)

  if (indexOldTable !== null) {
    table = this.tables[indexOldTable]
  }

  return table
}

Room.prototype.addOrUpdateTable = function addOrUpdateTable(table) {
  let room = this

  if (!table.id) {
    room = room.setIn(
      ['tables', room.tables.length],
      new Table({
        ...table,
        roomId: room.id,
        mapId: room.mapId,
        restaurantId: room.restaurantId,
      }),
    )
  } else {
    const tableIndex = room.getTableIndex(table.id)

    if (tableIndex || tableIndex === 0) {
      room = room.setIn(['tables', tableIndex], table)
    } else {
      room = room.setIn(['tables', room.tables.length], table)
    }
  }

  return room
}

Room.prototype.removeTable = function removeTable(tableId) {
  return this.set(
    'tables',
    this.tables.filter(table => table.id !== tableId),
  )
}

Room.prototype.removeTableByIndex = function removeTableByIndex(tableIndex) {
  return this.set(
    'tables',
    this.tables.filter((table, index) => index !== tableIndex),
  )
}

export default Room
