/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import getBrowserHistory from '../getBrowserHistory.js'
import { toast } from '../services/Toaster.js'

const ToastPage = () => {
  const message = new URLSearchParams(window.location.search).get('message')

  if (message) {
    toast(message)
  }

  getBrowserHistory().push('/orders')

  return null
}

export default ToastPage
