/* Copyright 2013 - 2024 Waiterio LLC */
import post from '@waiterio/api-client/post.js'

export default function addCategory(restaurantId, menuId, category) {
  return post({ url: 'menus/' + menuId + '/categories', body: category }).catch(
    error => {
      throw new Error(`Couldn't add category\n${error}`)
    },
  )
}
