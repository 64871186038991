/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { PureComponent } from 'react'
import { jsx } from '@emotion/react'
import { connect } from '@monorepo/context/index.js'
import changePassword from '@waiterio/api-client/changePassword.js'
import forgotPassword from '@waiterio/api-client/forgotPassword.js'
import normalizeEmail from '@monorepo/shared/normalizeEmail.js'
import card from '@waiterio/styles/card.js'
import field from '@waiterio/styles/field.js'
import fieldLabel from '@waiterio/styles/fieldLabel.js'
import fieldValue from '@waiterio/styles/fieldValue.js'
import greenButton from '@waiterio/styles/greenButton.js'
import inputable from '@waiterio/styles/inputable.js'
import getBrowserHistory from '../getBrowserHistory.js'

import Header from '../components/Header.js'
import LoadingDialog from '../components/LoadingDialog.js'
import ErrorDialog from '../components/ErrorDialog.js'

import translate from '../translate/index.js'

import semiDivider from '../styles/semiDivider.js'

class ForgotpasswordPage extends PureComponent {
  componentWillUnmount() {
    this.reset()
  }

  changeEmail = email => {
    this.props.produce(draft => {
      draft.email = email
    })
  }

  changeNewPassword = newPassword => {
    this.props.produce(draft => {
      draft.newPassword = newPassword
    })
  }

  changeConfirmPassword = confirmPassword => {
    this.props.produce(draft => {
      draft.confirmPassword = confirmPassword
    })
  }

  changePassword = event => {
    event.preventDefault()
    let { newPassword, token } = this.props

    const changePasswordRequest = {
      newPassword: btoa(newPassword),
      forgotPasswordToken: btoa(token),
    }

    this.showChangingPasswordDialog()

    changePassword(changePasswordRequest)
      .then(() => {
        this.hideChangingPasswordDialog()
        this.navigateBack(true)
      })
      .catch(error => {
        this.hideChangingPasswordDialog()
        this.showErrorDialog(error)
      })
  }

  emailResetLink = () => {
    this.props.produce(draft => {
      draft.emailedResetLink = true
    })
  }

  hideChangingPasswordDialog = () => {
    this.props.produce(draft => {
      delete draft.changingPasswordDialog
    })
  }

  hideErrorDialog = () => {
    this.props.produce(draft => {
      delete draft.errorDialog
    })
  }

  hideResettingPasswordDialog = () => {
    this.props.produce(draft => {
      delete draft.resettingPasswordDialog
    })
  }

  navigateBack = passwordChanged => {
    getBrowserHistory().push('/login?passwordChanged=' + passwordChanged)
  }

  reset = () => {
    this.props.produce()
  }

  resetPassword = event => {
    event.preventDefault()
    let { email } = this.props

    email = normalizeEmail(email?.trim())

    this.showResettingPasswordDialog()

    forgotPassword(email)
      .then(() => {
        this.hideResettingPasswordDialog()
        this.emailResetLink()
      })
      .catch(error => {
        this.hideResettingPasswordDialog()
        this.showErrorDialog(error)
      })
  }

  showChangingPasswordDialog = () => {
    this.props.produce(draft => {
      draft.changingPasswordDialog = true
    })
  }

  showErrorDialog = error => {
    this.props.produce(draft => {
      delete draft.sendingDialog
      draft.errorDialog = error ? error.toString() : null
    })
  }

  showResettingPasswordDialog = () => {
    this.props.produce(draft => {
      draft.resettingPasswordDialog = true
    })
  }

  render() {
    const {
      email,
      confirmPassword,
      newPassword,
      emailedResetLink,
      token,
      changingPasswordDialog,
      errorDialog,
      resettingPasswordDialog,
    } = this.props

    return (
      <>
        <Header title={translate('Forgot password')} href="login" />
        <div css={{ padding: 16 }}>
          {!token && !emailedResetLink && (
            <div css={{ maxWidth: 380, margin: '0 auto' }}>
              <form onSubmit={this.resetPassword} autoComplete="on" css={card}>
                <div css={[inputable, field]}>
                  <label htmlFor="emailInput" css={fieldLabel}>
                    {translate('email')}
                  </label>
                  <input
                    id="emailInput"
                    type="email"
                    name="email"
                    autoComplete="email"
                    autoCapitalize="none"
                    autoCorrect="off"
                    required
                    css={fieldValue}
                    value={email || ''}
                    onChange={event => {
                      this.changeEmail(event.target.value)
                    }}
                  />
                </div>
                <button
                  type="submit"
                  css={greenButton}
                  data-testid="reset-password"
                >
                  {translate('Reset password')}
                </button>
              </form>
            </div>
          )}

          {token && (
            <div css={{ maxWidth: 380, margin: '0 auto' }}>
              <form onSubmit={this.changePassword} css={card}>
                <div css={[inputable, field]}>
                  <label htmlFor="passwordInput" css={fieldLabel}>
                    {translate('new password')}
                  </label>
                  <input
                    id="passwordInput"
                    css={fieldValue}
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    autoCapitalize="none"
                    autoCorrect="off"
                    required
                    minLength="6"
                    value={newPassword || ''}
                    onChange={event => {
                      this.changeNewPassword(event.target.value)
                    }}
                  />
                </div>
                <div css={semiDivider} />
                <div css={[inputable, field]}>
                  <label htmlFor="passwordInput" css={fieldLabel}>
                    {translate('confirm password')}
                  </label>
                  <input
                    id="confirmPasswordInput"
                    css={fieldValue}
                    type="password"
                    autoCapitalize="none"
                    autoCorrect="off"
                    required
                    minLength="6"
                    value={confirmPassword || ''}
                    onChange={event => {
                      this.changeConfirmPassword(event.target.value)
                    }}
                  />
                </div>
                <button type="submit" css={greenButton}>
                  {translate('Save')}
                </button>
              </form>
            </div>
          )}

          {!token && emailedResetLink && (
            <div css={{ maxWidth: 380, margin: '0 auto' }}>
              <div
                css={{
                  textAlign: 'center',
                  background: 'white',
                  padding: 16,
                  marginBottom: 16,
                }}
                data-testid="emailed-password-reset-link"
              >
                {translate('We emailed you a link to reset your password')}
              </div>
            </div>
          )}
        </div>

        {changingPasswordDialog && (
          <LoadingDialog
            title={translate('Change password')}
            message={translate('in progress')}
          />
        )}
        {resettingPasswordDialog && (
          <LoadingDialog
            title={translate('Reset password')}
            message={translate('in progress')}
          />
        )}
        {errorDialog && (
          <ErrorDialog message={errorDialog} close={this.hideErrorDialog} />
        )}
      </>
    )
  }
}

export default connect((context, ownProps, produce) => {
  const params = new URLSearchParams(ownProps.location.search)
  const token = params.get('token')

  return {
    ...context.pages.forgotpassword,
    token,
    produce: produce(['pages', 'forgotpassword']),
  }
})(ForgotpasswordPage)
