/* Copyright 2013 - 2024 Waiterio LLC */
import put from '@waiterio/api-client/put.js'

export default function updateCoverOfRestaurant(restaurantId, file) {
  return put({
    url: 'restaurants/' + restaurantId + '/cover',
    body: [file],
  }).catch(error => {
    throw new Error(`Couldn't update cover of restaurant\n${error}`)
  })
}
