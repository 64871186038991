/* Copyright 2013 - 2024 Waiterio LLC */
import failure from '@waiterio/api-client/failure.js'

export default function failureLoginWithPIN(response) {
  if (response && response.status === 401) {
    response.message = 'Invalid PIN'
  }

  return failure(response)
}
