/* Copyright 2013 - 2024 Waiterio LLC */
import post from '@waiterio/api-client/post.js'

export default function addMeal(restaurantId, meal) {
  return post({ url: 'meals', body: meal }).catch(error => {
    let newError = new Error(`Couldn't add meal\n${error}`)
    newError.status = error.status
    throw newError
  })
}
