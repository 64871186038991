/* Copyright 2013 - 2024 Waiterio LLC */
import IS_DEVELOPMENT from './IS_DEVELOPMENT.js'
import IS_STAGING from './IS_STAGING.js'
import IS_TESTING from './IS_TESTING.js'
import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

export default function getWaiterioApiUrl() {
  let url = 'https://api.waiterio.com/api/v3'

  if (IS_BROWSER && window.WAITERIO_API_URL === '{{{WAITERIO_API_URL}}}') {
    delete window.WAITERIO_API_URL
  }

  if (IS_NODE && process.env.WAITERIO_API_URL === '{{{WAITERIO_API_URL}}}') {
    delete process.env.WAITERIO_API_URL
  }

  if (IS_BROWSER && window.WAITERIO_API_URL) {
    url = `${window.WAITERIO_API_URL}/api/v3`
  } else if (IS_NODE && process.env.WAITERIO_API_URL) {
    url = `${process.env.WAITERIO_API_URL}/api/v3`
  } else if (IS_STAGING) {
    url = 'https://api.waiterio-staging.com/api/v3'
  } else if (IS_TESTING && process.env.WAITERIO_API_URL) {
    url = `${process.env.WAITERIO_API_URL}/api/v3`
  } else if (IS_DEVELOPMENT || IS_TESTING) {
    // url = 'https://api.waiterio-staging.com/api/v3';
    url = 'http://192.168.1.3:3000/api/v3'
    // url = 'http://localhost:3000/api/v3'
  }

  // if (IS_STAGING || IS_DEVELOPMENT) {
  //   console.debug('Api.url = ' + url)
  // }

  return url
}
