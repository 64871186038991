/* Copyright 2013 - 2024 Waiterio LLC */
import { produce } from '@monorepo/context/index.js'
import { pickTranslations } from '../forage/TranslationsForage.js'

function arrayToMapOfLocales(array) {
  return array.reduce((mapp, current) => {
    if (current && current.locale) {
      mapp[current.locale] = current
    }

    return mapp
  }, {})
}

export default async function rehydrate() {
  try {
    const translations = await pickTranslations()

    produce(draft => {
      draft.translations = arrayToMapOfLocales(translations)
    })
  } catch (error) {
    console.error(error)
  }
}
