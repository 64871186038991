/* Copyright 2013 - 2024 Waiterio LLC */
import put from '@waiterio/api-client/put.js'

export default function cancelSubscription(subscription) {
  return put({
    url: `subscriptions/${subscription._id}/cancel`,
    body: subscription,
  }).catch(error => {
    throw new Error(`Couldn't cancel subscription\n${error}`)
  })
}
