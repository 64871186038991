/* Copyright 2013 - 2024 Waiterio LLC */
import userAgent from './userAgent.js'

let flavor = 'pos'

if (/flavor/i.test(userAgent)) {
  let splits = userAgent.split(' ')
  let group = splits.filter(s => s.includes('flavor'))[0]

  if (group) {
    flavor = group.split('/')?.[1]
  }
}

export default flavor
