/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import Subscription from '@waiterio/model/Subscription.js'
import UUID from '@waiterio/model/UUID.js'

const prefix = 'waiterio.subscriptions.'

export function plantSubscription(subscription) {
  return new Promise((resolve, reject) => {
    if (!subscription._id) {
      subscription._id = UUID.generate()
      subscription.lastEditTime = new Date().getTime()
    }

    if (subscription.creationTime === 0) {
      subscription.creationTime = new Date().getTime()
      subscription.lastEditTime = subscription.creationTime
    }

    resolve(localForage.setItem(prefix + subscription._id, subscription))
  }).then(subscription => {
    subscription = new Subscription(subscription)

    produce(draft => {
      draft.data.subscriptions[subscription._id] = subscription
    })

    return subscription
  })
}

export function burnSubscription(subscriptionId) {
  return localForage.removeItem(prefix + subscriptionId).then(() => {
    produce(draft => {
      delete draft.data.subscriptions[subscriptionId]
    })

    return subscriptionId
  })
}

export function pickSubscription(subscriptionId) {
  return localForage
    .getItem(prefix + subscriptionId)
    .then(subscription =>
      subscription ? new Subscription(subscription) : null,
    )
}

export function pickSubscriptions() {
  return localForage
    .keys()
    .then(keyss =>
      Promise.all(
        keyss
          .filter(key => key.indexOf(prefix) > -1)
          .map(key =>
            localForage
              .getItem(key)
              .then(subscription => new Subscription(subscription)),
          ),
      ),
    )
}

export function pickCurrentSubscription() {
  return pickSubscriptions().then(subscriptions => {
    subscriptions = subscriptions.filter(
      subscription => Subscription.Status.CANCELED !== subscription.status,
    )

    return subscriptions.length > 0 ? subscriptions[0] : null
  })
}
