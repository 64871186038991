/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import Invite from '@waiterio/model/Invite.js'

const prefix = 'waiterio.invites.'

export async function plantInvite(invite) {
  invite = new Invite(invite)
  await localForage.setItem(prefix + invite._id, new Invite(invite))
  produce(draft => {
    draft.data.invites[invite._id] = invite
  })
  return invite
}

export async function burnInvite(inviteId) {
  await localForage.removeItem(prefix + inviteId)
  produce(draft => {
    delete draft.data.invites[inviteId]
  })
  return inviteId
}

export async function pickInvite(inviteId) {
  const invite = await localForage.getItem(prefix + inviteId)
  return invite ? new Invite(invite) : null
}

export function pickInvites() {
  return localForage
    .keys()
    .then(keyss =>
      Promise.all(
        keyss
          .filter(key => key.indexOf(prefix) > -1)
          .map(key =>
            localForage.getItem(key).then(invite => new Invite(invite)),
          ),
      ),
    )
}
