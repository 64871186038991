/* Copyright 2013 - 2024 Waiterio LLC */
import UUID from '@waiterio/model/UUID.js'
import addAnalyticsEvents from '@waiterio/api-client/addAnalyticsEvents.js'
import getCurrentUserId from '../session/getCurrentUserId.js'
import getCurrentRestaurantId from '../session/getCurrentRestaurantId.js'

export default {
  track(event, properties) {
    if (typeof event === 'string') {
      event = {
        name: event,
      }
    }

    if (properties) {
      Object.keys(properties).forEach(key => {
        event[key] = properties[key]
      })
    }

    event._id = UUID.generate()
    event.creationTime = new Date().getTime()
    event.userId = getCurrentUserId()
    event.restaurantId = getCurrentRestaurantId()

    addAnalyticsEvents([event]).catch(error => {
      console.log('error', error)
    })
  },
}
