/* Copyright 2013 - 2024 Waiterio LLC */

import React from 'react'

const IconDiscount = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-discount'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M15.096 11.856c-0.6 0-1.080 0.48-1.080 1.056s0.48 1.056 1.080 1.056c0.576 0 1.056-0.48 1.056-1.056s-0.48-1.056-1.056-1.056zM8.904 7.656c-0.576 0-1.056 0.48-1.056 1.056 0 0.6 0.48 1.056 1.056 1.056 0.6 0 1.080-0.456 1.080-1.056 0-0.576-0.48-1.056-1.080-1.056zM22.416 14.544c-0.144-0.384-0.504-0.648-0.912-0.648h-1.896v-12c0-1.056-0.84-1.896-1.896-1.896h-11.424c-1.056 0-1.896 0.84-1.896 1.896v12h-1.896c-0.408 0-0.768 0.264-0.912 0.648-0.144 0.36-0.024 0.792 0.264 1.080l8.904 7.896c0.696 0.648 1.8 0.648 2.52 0l8.88-7.896c0.312-0.288 0.408-0.72 0.264-1.080zM6.528 8.712c0-1.32 1.056-2.4 2.376-2.4 1.344 0 2.4 1.080 2.4 2.4s-1.056 2.4-2.4 2.4c-1.32 0-2.376-1.080-2.376-2.4zM10.272 15.648c-0.12 0.24-0.336 0.36-0.576 0.36-0.12 0-0.216-0.024-0.312-0.072-0.336-0.168-0.456-0.576-0.288-0.888l4.608-9.072c0.168-0.312 0.552-0.456 0.888-0.288s0.456 0.576 0.288 0.912l-4.608 9.048zM15.096 15.312c-1.32 0-2.4-1.080-2.4-2.4s1.080-2.4 2.4-2.4c1.32 0 2.4 1.080 2.4 2.4s-1.080 2.4-2.4 2.4z" />
    </svg>
  )
}

export default IconDiscount
