/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'
import clickable from '@waiterio/styles/clickable.js'

export default css`
  ${clickable};
  display: flex;
  width: 56px;
  min-width: 56px;
  fill: white;
  padding: 16;

  & i {
    align-self: center;
    width: 56px;
    min-width: 56px;
    text-align: center;
  }
`
