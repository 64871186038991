/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import fixedHeader from '../styles/fixedHeader.js'
import ActionBar from './ActionBar.js'
import NoInternetConnection from './NoInternetConnection.js'
import NoInternetConnectionFixer from './NoInternetConnectionFixer.js'

const Header = props => (
  <>
    <div css={fixedHeader}>
      <ActionBar
        title={props.title}
        iconTitle={props.iconTitle}
        onClickTitle={props.onClickTitle}
        href={props.href}
        action={props.action}
        onClickAction={props.onClickAction}
      />
      <NoInternetConnection />
    </div>

    <div css={{ height: 56, minHeight: 56 }} />
    <NoInternetConnectionFixer />
  </>
)

export default Header
