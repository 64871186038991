/* Copyright 2013 - 2024 Waiterio LLC */
import isWebkitNative from './isWebkitNative.js'

export default message => {
  // console.info('native/sendMessage')
  // console.info(message)

  if (isWebkitNative()) {
    window.webkit.messageHandlers?.waiterio?.postMessage(message)
  }
}
