/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import ErrorBoundary from '@waiterio/components/ErrorBoundary.js'
import getBrowserHistory from '../getBrowserHistory.js'
import { toastError } from '../services/Toaster.js'

const FallbackComponent = ({ error }) => {
  toastError(error)
  getBrowserHistory().goBack()
}

const ErrorBoundaryGoBack = ({ children }) => (
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    {children}
  </ErrorBoundary>
)

export default ErrorBoundaryGoBack
