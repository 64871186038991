/* Copyright 2013 - 2024 Waiterio LLC */
import { getContext } from '@monorepo/context/index.js'
import Printer from '@waiterio/model/Printer.js'
import { addPrinter } from '../services/Updater.js'

export default async function onFindPrinters({ printers } = {}) {
  try {
    if (printers && printers.length > 0) {
      const context = getContext()

      if (
        context &&
        context.data &&
        context.data.printers &&
        Object.keys(context.data.printers).length === 0
      ) {
        await Promise.all(
          printers
            .map(({ name, connection, ip, protocol, type }) => {
              /* istanbul ignore next */
              if (!type) {
                switch (protocol) {
                  case Printer.Protocol.COMPUTER_DRIVER:
                    type = Printer.Type.COMPUTER_DRIVER_PRINTER

                    break

                  case Printer.Protocol.EPSON:
                    switch (connection) {
                      case Printer.Connection.BLUETOOTH:
                        type = Printer.Type.EPSON_BLUETOOTH_PRINTER

                        break

                      case Printer.Connection.ETHERNET_WIFI:
                        type = Printer.Type.EPSON_TCP_PRINTER

                        break

                      case Printer.Connection.USB:
                        type = Printer.Type.EPSON_USB_PRINTER

                        break
                    }

                    break

                  case Printer.Protocol.ESC_POS:
                    switch (connection) {
                      case Printer.Connection.BLUETOOTH:
                        type = Printer.Type.ESCPOS_BLUETOOTH_PRINTER

                        break

                      case Printer.Connection.ETHERNET_WIFI:
                        type = Printer.Type.ESCPOS_TCP_PRINTER

                        break

                      case Printer.Connection.USB:
                        type = Printer.Type.ESCPOS_USB_PRINTER

                        break
                    }

                    break

                  case Printer.Protocol.STAR_MICRONICS:
                    switch (connection) {
                      case Printer.Connection.BLUETOOTH:
                        type = Printer.Type.STAR_BLUETOOTH_PRINTER

                        break

                      case Printer.Connection.ETHERNET_WIFI:
                        type = Printer.Type.STAR_TCP_PRINTER

                        break

                      case Printer.Connection.USB:
                        type = Printer.Type.STAR_USB_PRINTER

                        break
                    }

                    break
                }
              }

              if (!name) {
                if (connection) {
                  name =
                    connection.toLowerCase().replace('_', ' ') +
                    ' thermal printer'
                } else {
                  name = 'thermal printer'
                }
              }

              name = name || connection

              return new Printer({
                name,
                connection,
                protocol: protocol || Printer.Protocol.ESC_POS,
                type,
                restaurantId: context.session.currentRestaurantId,
                ip,
              })
            })
            .map(addPrinter),
        )
      }
    }
  } catch (error) {
    console.error(error)
  }
}
