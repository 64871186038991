/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'
import singleLine from '@waiterio/styles/singleLine.js'
import card from '@waiterio/styles/card.js'
import clickable from '@waiterio/styles/clickable.js'

export default css`
  label: button;
  ${clickable};
  ${singleLine};
  ${card};
  display: block;
  border: 0;
  height: 48px;
  width: 100%;
  line-height: 48px;
  padding: 0 16px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  box-sizing: border-box;
  color: white;
  background-color: var(--color-primary);
`
