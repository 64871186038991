/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconChat = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-chat'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M4.584 0c-2.544 0-4.584 2.040-4.584 4.584v10.68c0 2.544 2.040 4.584 4.584 4.584h0.648c0.672 0 0.888 0.216 0.888 0.672v3.264c0 0.216 0.216 0.432 0.864-0.216l3.264-3.288c0.216-0.216 0.672-0.432 0.888-0.432h8.28c2.544 0 4.584-2.040 4.584-4.584v-10.68c0-2.544-2.040-4.584-4.584-4.584h-14.832zM11.976 4.728c1.752-0.024 3.312 1.224 3.384 3 0.048 1.44-0.936 2.76-2.424 3.24v1.032c0 0.528-0.408 0.936-0.936 0.936s-0.936-0.408-0.936-0.936v-1.752c0-0.48 0.384-0.888 0.864-0.936 1.2-0.072 1.584-0.696 1.56-1.512-0.048-0.768-0.552-1.2-1.464-1.2-0.936 0.024-1.392 0.6-1.512 1.056-0.12 0.504-0.624 0.792-1.128 0.672s-0.816-0.624-0.696-1.128c0 0 0 0 0 0 0.336-1.296 1.584-2.448 3.288-2.472zM12 14.136c0.6 0 1.104 0.504 1.104 1.104s-0.504 1.080-1.104 1.080-1.080-0.48-1.080-1.080c0-0.6 0.48-1.104 1.080-1.104z" />
    </svg>
  )
}

export default IconChat
