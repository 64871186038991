/* Copyright 2013 - 2024 Waiterio LLC */
import blue from '@waiterio/styles/blue.js'
import violet from '@waiterio/styles/violet.js'

const flavor2primary = {
  pos: blue,
  builder: violet,
}

const getColorPrimaryFromFlavor = flavor => {
  let colorPrimary = flavor2primary[flavor] || blue

  return colorPrimary
}

export default getColorPrimaryFromFlavor
