/* Copyright 2013 - 2024 Waiterio LLC */
import { produce } from '@monorepo/context/index.js'
import { setTokenForApiClient } from '@waiterio/api-client/token.js'
import { plantMap } from '../forage/MapsForage.js'
import { plantMenu } from '../forage/MenusForage.js'
import { plantRestaurant } from '../forage/RestaurantsForage.js'
import { plantRole } from '../forage/RolesForage.js'
import { plantSubscription } from '../forage/SubscriptionsForage.js'
import { plantUser } from '../forage/UsersForage.js'

import setWaiterioToken from './setWaiterioToken.js'
import setCurrentUserId from './setCurrentUserId.js'
import setCurrentRestaurantId from './setCurrentRestaurantId.js'
import setDefaultMenuId from './setDefaultMenuId.js'
// import setCurrentDeviceId from './setCurrentDeviceId'.js'
// import setGoogleToken from './setGoogleToken'.js'

export default async function storeNewSession(loginHolder) {
  try {
    const promises = []

    // promises.push(DevicesForage.plantDevice(loginHolder.device));
    promises.push(plantMap(loginHolder.map))
    promises.push(plantMenu(loginHolder.menu))
    promises.push(plantRestaurant(loginHolder.restaurant))
    promises.push(plantUser(loginHolder.user))
    for (let i = 0; i < loginHolder.roles.length; i += 1) {
      promises.push(plantRole(loginHolder.roles[i]))
    }

    if (loginHolder.subscriptions) {
      for (let s = 0; s < loginHolder.subscriptions.length; s += 1) {
        promises.push(plantSubscription(loginHolder.subscriptions[s]))
      }
    }

    await Promise.all(promises)

    setWaiterioToken(loginHolder.waiterioToken)
    setCurrentUserId(loginHolder.user._id)
    setCurrentRestaurantId(loginHolder.restaurant._id)
    setDefaultMenuId(loginHolder.menu._id)
    // setCurrentDeviceId(loginHolder.device._id);
    // setGoogleToken(loginHolder.googleOauth2Token);
    // invitationManager.setInviteToken(loginHolder.getInviteToken());
    produce(draft => {
      draft.session.defaultMenuId = loginHolder.menu._id
      draft.session.currentRestaurantId = loginHolder.restaurant._id
      draft.session.currentUserId = loginHolder.user._id
      draft.session.rehydrated = true
      draft.session.justLoggedIn = true
    })

    setTokenForApiClient(loginHolder.waiterioToken)

    return true
  } catch (error) {
    console.log('error', error)
    throw error
  }
}
