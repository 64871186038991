/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { PureComponent } from 'react'
import { jsx } from '@emotion/react'
import { connect } from '@monorepo/context/index.js'

class NoInternetConnectionFixer extends PureComponent {
  render() {
    return this.props.online ? null : (
      <div css={{ height: 35, minHeight: 35 }} />
    )
  }
}

export default connect((context, ownProps) => ({
  online: context.connection.online,
}))(NoInternetConnectionFixer)
