/* Copyright 2013 - 2024 Waiterio LLC */
import iosPublishedVersion from '../iosPublishedVersion.js'
import compareSemanticVersions from './compareSemanticVersions.js'
import iosNative from './iosNative.js'
import nativeVersion from './nativeVersion.js'

let underReview = false

if (
  nativeVersion &&
  iosNative &&
  compareSemanticVersions(nativeVersion, iosPublishedVersion) > 0
) {
  underReview = true
}

export default underReview
