/* Copyright 2013 - 2024 Waiterio LLC */
import onError from './onError.js'
import onFindPrinters from './onFindPrinters.js'
import onSetReferrer from './onSetReferrer.js'
import onSetTapToPayCapability from './onSetTapToPayCapability.js'

export default function onReceiveMessage(message = {}) {
  // console.log('native/onReceiveMessage', JSON.stringify(message, null, 2))

  if (message) {
    if (message.type === 'error') {
      onError(message)
    } else if (message.type === 'findPrinters') {
      onFindPrinters(message)
    } else if (message.type === 'setReferrer') {
      onSetReferrer(message)
    } else if (message.type === 'setTapToPayCapability') {
      onSetTapToPayCapability(message)
    }
  }
}
