/* Copyright 2013 - 2024 Waiterio LLC */
import { plantTranslation } from '../forage/TranslationsForage.js'

export default function fetchTranslation(languageCode) {
  import(
    /* webpackChunkName: "language-[request]" */ `@waiterio/app/languages/${languageCode}.js`
  )
    .then(module => {
      const translation = module.default ? module.default : module

      plantTranslation(languageCode, translation)
    })
    .catch(console.error)
}
