/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import UUID from './UUID.js'
import Settings from './Settings.js'

function User(source) {
  if (source instanceof User) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = UUID.generate()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().getTime()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (!this.firstName) {
      this.firstName = ''
    }

    if (!this.lastName) {
      this.lastName = ''
    }

    if (!this.settings) {
      this.settings = new Settings()
    }

    if (this.settings) {
      this.settings = new Settings(this.settings)
    }

    return Immutable(this, { prototype: User.prototype })
  }
}

User.prototype.constructor = User

User.ANONYMOUS_CUSTOMER = 'anonymousCustomer'
User.DEFAULT_GOOGLE_AVATAR_IMAGE_URL =
  'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg'

User.Gender = {
  MALE: 'male',
  FEMALE: 'female',
}

User.prototype.getFullname = function getFullname() {
  let fullname = [this.firstName, this.lastName].join(' ')

  if (fullname) {
    fullname = fullname.trim()
  }

  if (!fullname || fullname.length === 0) {
    fullname = this.email
  }

  return fullname
}

User.prototype.getInitial = function getInitial() {
  let initial

  if (this.firstName?.trim().length > 0) {
    initial = this.firstName.charAt(0)
  } else if (this.lastName?.trim().length > 0) {
    initial = this.lastName.charAt(0)
  } else if (this.email?.trim().length > 0) {
    initial = this.email.charAt(0)
  } else {
    initial = '?'
  }

  return initial
}

export default User
