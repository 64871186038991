/* Copyright 2013 - 2024 Waiterio LLC */
import batchCalls from '@waiterio/api-client/batchCalls.js'
import Map_ from '@waiterio/model/Map_.js'
import Menu from '@waiterio/model/Menu.js'
import Restaurant from '@waiterio/model/Restaurant.js'
import Subscription from '@waiterio/model/Subscription.js'

export default function getAllLoginData(restaurantId) {
  return batchCalls([
    {
      alias: 'restaurant',
      url: `restaurants/${restaurantId}`,
    },
    {
      alias: 'maps',
      url: `maps?restaurantId=${restaurantId}`,
    },
    {
      alias: 'menus',
      url: `menus?restaurantId=${restaurantId}`,
    },
    {
      alias: 'subscriptions',
      url: `subscriptions?restaurantId=${restaurantId}`,
    },
  ]).then(({ restaurant, maps, menus, subscriptions }) => ({
    restaurant: new Restaurant(restaurant),
    maps: maps.map(map => new Map_(map)),
    menus: menus.map(menu => new Menu(menu)),
    subscriptions: subscriptions.map(
      subscription => new Subscription(subscription),
    ),
  }))
}
