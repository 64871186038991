/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css({
  top: '50%',
  margin: '0 auto',

  borderRadius: '50%',
  width: '7em',
  height: '7em',

  fontSize: '5px',
  position: 'relative',
  textIndent: '-9999em',
  borderTop: '1.1em solid rgba(0, 0, 0, 0.2)',
  borderRight: '1.1em solid rgba(0, 0, 0, 0.2)',
  borderBottom: '1.1em solid rgba(0, 0, 0, 0.2)',
  borderLeft: '1.1em solid var(--color-primary)',
  transform: 'translateZ(0)',
  animation: 'rotation 1.1s infinite linear',
})
