/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import User from '@waiterio/model/User.js'
import getCurrentUserId from '../session/getCurrentUserId.js'

const prefix = 'waiterio.users.'

export async function plantUser(user) {
  user = await localForage.setItem(prefix + user._id, user)
  user = new User(user)
  produce(draft => {
    draft.data.users[user._id] = user
  })
  return user
}

export async function burnUser(userId) {
  await localForage.removeItem(prefix + userId)
  produce(draft => {
    delete draft.data.users[userId]
  })
  return userId
}

export async function pickUser(userId) {
  if (userId) {
    let user = await localForage.getItem(prefix + userId)
    return user ? new User(user) : null
  } else if (getCurrentUserId()) {
    let user = await localForage.getItem(prefix + getCurrentUserId())
    return user ? new User(user) : null
  } else {
    let users = await pickUsers()
    return users && users.length > 0 ? users[0] : null
  }
}

export async function pickUsers() {
  let keys_ = await localForage.keys()
  keys_ = keys_.filter(key => key.indexOf(prefix) > -1)
  const promises = keys_.map(key =>
    localForage.getItem(key).then(user => new User(user)),
  )
  return Promise.all(promises)
}
