/* Copyright 2013 - 2024 Waiterio LLC */
import post from '@waiterio/api-client/post.js'

export default function addAnalyticsEvents(analyticsEvents) {
  return post({ url: 'analytics/events', body: analyticsEvents }).catch(
    error => {
      throw new Error(`Couldn't add analytics event\n${error}`)
    },
  )
}
