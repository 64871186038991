/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable camelcase */
import { createSelector } from 'reselect'

import ar from 'date-fns/locale/ar/index.js'
import bg from 'date-fns/locale/bg/index.js'
import ca from 'date-fns/locale/ca/index.js'
import cs from 'date-fns/locale/cs/index.js'
import da from 'date-fns/locale/da/index.js'
import de from 'date-fns/locale/de/index.js'
import el from 'date-fns/locale/el/index.js'
import en from 'date-fns/locale/en/index.js'
import es from 'date-fns/locale/es/index.js'
import fi from 'date-fns/locale/fi/index.js'
import fr from 'date-fns/locale/fr/index.js'
import hr from 'date-fns/locale/hr/index.js'
import id from 'date-fns/locale/id/index.js'
import is from 'date-fns/locale/is/index.js'
import it from 'date-fns/locale/it/index.js'
import ja from 'date-fns/locale/ja/index.js'
import ko from 'date-fns/locale/ko/index.js'
import mk from 'date-fns/locale/mk/index.js'
import nb from 'date-fns/locale/nb/index.js'
import nl from 'date-fns/locale/nl/index.js'
import pl from 'date-fns/locale/pl/index.js'
import pt from 'date-fns/locale/pt/index.js'
import ro from 'date-fns/locale/ro/index.js'
import ru from 'date-fns/locale/ru/index.js'
import sk from 'date-fns/locale/sk/index.js'
import sv from 'date-fns/locale/sv/index.js'
import th from 'date-fns/locale/th/index.js'
import tr from 'date-fns/locale/tr/index.js'
import zh_cn from 'date-fns/locale/zh_cn/index.js'

import fetchTranslation from '../fetch/fetchTranslation.js'
import { languageSelector } from './AppSelectors.js'

export const dateFnsLocaleSelector = createSelector(
  [languageSelector],
  language => {
    language = language !== 'in' ? language : 'id'

    let buildDistanceInWordsLocale

    switch (language) {
      case 'ar':
        buildDistanceInWordsLocale = ar
        break
      case 'bg':
        buildDistanceInWordsLocale = bg
        break
      case 'ca':
        buildDistanceInWordsLocale = ca
        break
      case 'cs':
        buildDistanceInWordsLocale = cs
        break
      case 'da':
        buildDistanceInWordsLocale = da
        break
      case 'de':
        buildDistanceInWordsLocale = de
        break
      case 'el':
        buildDistanceInWordsLocale = el
        break
      case 'es':
        buildDistanceInWordsLocale = es
        break
      case 'fi':
        buildDistanceInWordsLocale = fi
        break
      case 'fr':
        buildDistanceInWordsLocale = fr
        break
      case 'hr':
        buildDistanceInWordsLocale = hr
        break
      case 'id':
        buildDistanceInWordsLocale = id
        break
      case 'is':
        buildDistanceInWordsLocale = is
        break
      case 'it':
        buildDistanceInWordsLocale = it
        break
      case 'ja':
        buildDistanceInWordsLocale = ja
        break
      case 'ko':
        buildDistanceInWordsLocale = ko
        break
      case 'mk':
        buildDistanceInWordsLocale = mk
        break
      case 'nb':
        buildDistanceInWordsLocale = nb
        break
      case 'nl':
        buildDistanceInWordsLocale = nl
        break
      case 'pl':
        buildDistanceInWordsLocale = pl
        break
      case 'pt':
        buildDistanceInWordsLocale = pt
        break
      case 'ro':
        buildDistanceInWordsLocale = ro
        break
      case 'ru':
        buildDistanceInWordsLocale = ru
        break
      case 'sk':
        buildDistanceInWordsLocale = sk
        break
      case 'sv':
        buildDistanceInWordsLocale = sv
        break
      case 'th':
        buildDistanceInWordsLocale = th
        break
      case 'tr':
        buildDistanceInWordsLocale = tr
        break
      case 'zh':
        buildDistanceInWordsLocale = zh_cn
        break
      default:
        buildDistanceInWordsLocale = en
        break
    }

    return buildDistanceInWordsLocale
  },
)

const translations = context => context.translations

export const translateSelector = createSelector(
  [languageSelector, translations],
  (languageCode, translations) => {
    languageCode = languageCode !== 'in' ? languageCode : 'id'

    if (languageCode && !translations[languageCode]) {
      fetchTranslation(languageCode)
    }

    return function translate(text, fallback) {
      let translation

      if (translations?.[languageCode]?.[text]) {
        translation = translations[languageCode][text]
      } else if (fallback) {
        translation = fallback
      } else {
        translation = text
      }

      return translation
    }
  },
)
