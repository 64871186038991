/* Copyright 2013 - 2024 Waiterio LLC */
import batchCalls from '@waiterio/api-client/batchCalls.js'
import Account from '@waiterio/model/Account.js'
import Invite from '@waiterio/model/Invite.js'
import Meal from '@waiterio/model/Meal.js'
import Payment from '@waiterio/model/Payment.js'
import Plan from '@waiterio/model/Plan.js'
import Printer from '@waiterio/model/Printer.js'
import Role from '@waiterio/model/Role.js'
import Terminal from '@waiterio/model/Terminal.js'
import User from '@waiterio/model/User.js'

export default function getAllButLoginData(restaurantId) {
  const twentyfourHours = 86400000 // TWENTYFOUR_HOURS
  const startTime = new Date().getTime() - twentyfourHours

  return batchCalls([
    {
      alias: 'accounts',
      url: `accounts?restaurantId=${restaurantId}`,
    },
    {
      alias: 'invites',
      url: `invites?restaurantId=${restaurantId}`,
    },
    {
      alias: 'meals',
      url: `meals?restaurantId=${restaurantId}&startTime=${startTime}`,
    },
    {
      alias: 'users',
      url: `users?restaurantId=${restaurantId}`,
    },
    {
      alias: 'payments',
      url: `payments?restaurantId=${restaurantId}&startTime=${startTime}`,
    },
    {
      alias: 'plans',
      url: `plans?restaurantId=${restaurantId}`,
    },
    {
      alias: 'printers',
      url: `printers?restaurantId=${restaurantId}`,
    },
    {
      alias: 'roles',
      url: `roles?restaurantId=${restaurantId}`,
    },
    {
      alias: 'terminals',
      url: `terminals?restaurantId=${restaurantId}`,
    },
  ]).then(
    ({
      accounts,
      invites,
      meals,
      payments,
      plans,
      printers,
      roles,
      terminals,
      users,
    }) => ({
      accounts: accounts.map(account => new Account(account)),
      invites: invites.map(invite => new Invite(invite)),
      meals: meals.map(classifyMeal),
      payments: payments.map(payment => new Payment(payment)),
      plans: plans.map(plan => new Plan(plan)),
      printers: printers.map(printer => new Printer(printer)),
      roles: roles.map(role => new Role(role)),
      terminals: terminals.map(terminal => new Terminal(terminal)),
      users: users.map(user => new User(user)),
    }),
  )
}

function classifyMeal(meal) {
  if (meal && meal.itemstamps && meal.itemstamps instanceof Array) {
    meal.itemstamps = meal.itemstamps.reduce((itemstamps, itemstamp) => {
      itemstamps[itemstamp.id] = itemstamp

      return itemstamps
    }, {})
  }

  return new Meal(meal)
}
