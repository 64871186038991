/* Copyright 2013 - 2024 Waiterio LLC */
import post from '@waiterio/api-client/post.js'

export default function addSubscription(restaurantId, cardToken, subscription) {
  return post({
    url: `subscriptions?cardToken=${cardToken}`,
    body: subscription,
  }).catch(error => {
    throw new Error(error?.message || "Couldn't add subscription")
  })
}
