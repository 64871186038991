/* Copyright 2013 - 2024 Waiterio LLC */
import { produce } from '@monorepo/context/index.js'
import getDisplay from './getDisplay.js'

const updateDisplayInStore = () => {
  produce(draft => {
    draft.display = getDisplay()
  })
}

export default function listenForWindowResize() {
  updateDisplayInStore()

  window.addEventListener('resize', updateDisplayInStore)
}
