/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css`
  label: fixed-header;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
`
