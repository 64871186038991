/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import UUID from './UUID.js'

function Table(source) {
  if (source instanceof Table) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this.id) {
      this.id = UUID.generate()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().getTime()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (this.x === undefined) {
      this.x = 1
    }

    if (this.y === undefined) {
      this.y = 1
    }

    if (!this.shape) {
      this.shape = Table.Shape.SQUARE
    }

    if (!this.size) {
      this.size = Table.Size.MEDIUM
    }

    if (!this.rotation) {
      this.rotation = Table.Rotation.DEGREES_0
    }

    if (!this.label) {
      this.label = Table.Label.BOTTOM
    }

    return Immutable(this, { prototype: Table.prototype })
  }
}

Table.prototype.constructor = Table

Table.Shape = {
  CIRCLE: 'circle',
  SQUARE: 'square',
  RECTANGLE: 'rectangle',
}

Table.Size = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
}

Table.Rotation = {
  DEGREES_0: 'DEGREES_0',
  DEGREES_45: 'DEGREES_45',
  DEGREES_90: 'DEGREES_90',
  DEGREES_135: 'DEGREES_135',
  DEGREES_180: 'DEGREES_180',
  DEGREES_225: 'DEGREES_225',
  DEGREES_270: 'DEGREES_270',
  DEGREES_315: 'DEGREES_315',
}

Table.Label = {
  HIDDEN: 'hidden',
  BOTTOM: 'bottom',
  LEFT: 'left',
  TOP: 'top',
  RIGHT: 'right',
}

export default Table
