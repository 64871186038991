/* Copyright 2013 - 2024 Waiterio LLC */
import standalone from './standalone.js'
import chromium from './chromium.js'
import chrome from './chrome.js'
import chromeos from './chromeos.js'
import safari from './safari.js'
import opera from './opera.js'
import firefox from './firefox.js'
import electron from './electron.js'
import android from './android.js'
import ios from './ios.js'
import iosNative from './iosNative.js'
import iphone from './iphone.js'
import ipad from './ipad.js'
import macos from './macos.js'
import windows from './windows.js'
import linux from './linux.js'
import androidNative from './androidNative.js'
import macosNative from './macosNative.js'
import windowsNative from './windowsNative.js'
import linuxNative from './linuxNative.js'
import mobile from './mobile.js'
import native from './native.js'
import browser from './browser.js'
import automation from './automation.js'
import nativeVersion from './nativeVersion.js'
import flavor from './flavor.js'

import underReview from './underReview.js'
import wkwebview from './wkwebview.js'

const device = {
  standalone,
  wkwebview,
  chromium,
  chrome,
  chromeos,
  safari,
  opera,
  firefox,
  electron,
  android,
  ios,
  iphone,
  ipad,
  macos,
  windows,
  linux,
  androidNative,
  iosNative,
  macosNative,
  windowsNative,
  linuxNative,
  mobile,
  native,
  browser,
  automation,
  nativeVersion,
  underReview,
  flavor,
}

export default device
