/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect } from 'react'
import { jsx } from '@emotion/react'
import { useLocation } from 'react-router-dom'
import { connect } from '@monorepo/context/index.js'
import getBrowserHistory from '../getBrowserHistory.js'
import clearSession from '../session/clearSession.js'

const LogoutPage = () => {
  let location = useLocation()
  let urlSearchParams = new URLSearchParams(location.search)
  let querystring = '?' + urlSearchParams.toString()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)

      clearSession()

      getBrowserHistory().push(`/login${querystring}`)
    }
  })

  return <h1>Logout</h1>
}

export default connect((context, props, produce) => ({}))(LogoutPage)
