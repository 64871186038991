/* Copyright 2013 - 2024 Waiterio LLC */
import put from '@waiterio/api-client/put.js'

export default function updateSubscription(
  restaurantId,
  subscriptionId,
  newPlanId,
  subscription,
) {
  return put({
    url: `subscriptions/${subscription._id}?newPlanId=${newPlanId}`,
    body: subscription,
  }).catch(error => {
    throw new Error(`Couldn't update subscription\n${error}`)
  })
}
