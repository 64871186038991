/* Copyright 2013 - 2024 Waiterio LLC */
import getAllButLoginData from '../services/getAllButLoginData.js'
import { plantAccount } from '../forage/AccountsForage.js'
import { pickInvite, plantInvite } from '../forage/InvitesForage.js'
import { pickMeal, plantMeal } from '../forage/MealsForage.js'
import { pickPayment, plantPayment } from '../forage/PaymentsForage.js'
import { pickPlan, plantPlan } from '../forage/PlansForage.js'
import { pickPrinter, plantPrinter } from '../forage/PrintersForage.js'
import { pickRole, plantRole } from '../forage/RolesForage.js'
import { pickTerminal, plantTerminal } from '../forage/TerminalsForage.js'
import { pickUser, plantUser } from '../forage/UsersForage.js'

export default function fetchAllButLoginData(restaurantId) {
  getAllButLoginData(restaurantId).then(
    ({
      accounts,
      invites,
      meals,
      payments,
      plans,
      printers,
      roles,
      terminals,
      users,
    }) => {
      if (accounts.length > 0) {
        const account = accounts[0]
        plantAccount(account)
      }

      invites.forEach(invite => {
        pickInvite(invite._id).then(oldInvite => {
          if (invite.lastEditTime > (oldInvite ? oldInvite.lastEditTime : 0)) {
            plantInvite(invite)
          }
        })
      })

      Promise.all(
        meals.map(newMeal =>
          Promise.all([Promise.resolve(newMeal), pickMeal(newMeal._id)]).then(
            ([newMeal, oldMeal]) => {
              let atLeastOneMealUpdated = false

              if (newMeal.lastEditTime > (oldMeal ? oldMeal.lastEditTime : 0)) {
                plantMeal(newMeal)
                atLeastOneMealUpdated = true
              }

              return atLeastOneMealUpdated
            },
          ),
        ),
      )

      Promise.all(
        payments.map(newPayment =>
          Promise.all([
            Promise.resolve(newPayment),
            pickPayment(newPayment._id),
          ]).then(([newPayment, oldPayment]) => {
            let atLeastOnePaymentUpdated = false

            if (
              newPayment.lastEditTime >
              (oldPayment ? oldPayment.lastEditTime : 0)
            ) {
              plantPayment(newPayment)
              atLeastOnePaymentUpdated = true
            }

            return atLeastOnePaymentUpdated
          }),
        ),
      )

      users.forEach(user => {
        pickUser(user._id).then(oldUser => {
          if (user.lastEditTime > (oldUser ? oldUser.lastEditTime : 0)) {
            plantUser(user)
          }
        })
      })

      plans.forEach(plan => {
        pickPlan(plan._id).then(oldPlan => {
          if (plan.lastEditTime > (oldPlan ? oldPlan.lastEditTime : 0)) {
            plantPlan(plan)
          }
        })
      })

      printers.forEach(printer => {
        pickPrinter(printer._id).then(oldPrinter => {
          if (
            printer.lastEditTime > (oldPrinter ? oldPrinter.lastEditTime : 0)
          ) {
            plantPrinter(printer)
          }
        })
      })

      roles.forEach(role => {
        pickRole(role._id).then(oldRole => {
          if (role.lastEditTime > (oldRole ? oldRole.lastEditTime : 0)) {
            plantRole(role)
          }
        })
      })

      terminals.forEach(terminal => {
        pickTerminal(terminal._id).then(oldTerminal => {
          if (
            terminal.lastEditTime > (oldTerminal ? oldTerminal.lastEditTime : 0)
          ) {
            plantTerminal(terminal)
          }
        })
      })
    },
  )
}
