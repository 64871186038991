/* Copyright 2013 - 2024 Waiterio LLC */
import addIpLog from '@waiterio/api-client/addIpLog.js'
import UUID from '@waiterio/model/UUID.js'
import getCurrentRestaurantId from '../session/getCurrentRestaurantId.js'
import getCurrentUserId from '../session/getCurrentUserId.js'

export default function logIP(log) {
  const restaurantId = getCurrentRestaurantId()
  const userId = getCurrentUserId()

  return addIpLog({
    _id: UUID.generate(),
    creationTime: new Date().getTime(),
    restaurantId,
    userId,
    ...log,
  }).catch(console.error)
}
