/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'
import singleLine from '@waiterio/styles/singleLine.js'

export default css`
  label: fieldValue;
  display: flex;
  flex-grow: 1;
  ${singleLine};
  text-align: right;
  justify-content: flex-end;
  line-height: 48px;
  padding-right: 16px;
`
