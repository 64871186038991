/* Copyright 2013 - 2024 Waiterio LLC */
import { createBrowserHistory } from 'history'

let history

export default () => {
  if (!history) {
    history = createBrowserHistory()
  }

  return history
}
