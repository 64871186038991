/* Copyright 2013 - 2024 Waiterio LLC */
/* global Stripe */

import getStripePublishableKey from '@waiterio/stripe-publishable/getStripePublishableKey.js'

export default function getStripeTokenUS(card) {
  let stripePublishableKey = getStripePublishableKey()
  Stripe.setPublishableKey(stripePublishableKey)

  return new Promise((resolve, reject) => {
    Stripe.card.createToken(
      {
        name: card.fullname,
        number: card.number,
        cvc: card.securityCode,
        exp_month: card.expiry.split('/')[0].trim(),
        exp_year: card.expiry.split('/')[1].trim(),
      },
      (status, response) => {
        // console.log(response);

        if (response.error) {
          const error = new Error(response.error.message)
          error.code = response.error.code
          error.status = status
          reject(error)
        } else {
          const cardToken = response.id

          resolve(cardToken)
        }
      },
    )
  })
}
