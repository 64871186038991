/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import button from '@waiterio/styles/button.js'
import Modal from '@waiterio/components/Modal.js'
import ModalHeaderWhite from '@waiterio/components/ModalHeaderWhite.js'
import translate from '../translate/index.js'

const ErrorDialog = ({ close, title, message, confirm }) => {
  if (message && message.trim().length === 0) {
    message = null
  }

  if (message) {
    message = translate(message)
  }

  return (
    <Modal close={close}>
      <ModalHeaderWhite>{title || translate('Error')}</ModalHeaderWhite>
      <div css={{ display: 'flex', color: '#ababab', minHeight: 80 }}>
        <div
          css={{
            alignSelf: 'center',
            width: '100%',
            textAlign: 'center',
            whiteSpace: 'pre-line',
            padding: 16,
            overflow: 'hidden',
            wordWrap: 'break-word',
            userSelect: 'text',
            cursor: 'text',
          }}
        >
          {message || translate('An error occurred')}
        </div>
      </div>
      <div
        css={[button, { fontWeight: 'normal', textTransform: 'inherit' }]}
        onClick={event => {
          event.preventDefault()
          close()
        }}
      >
        {confirm || translate('Okay')}
      </div>
    </Modal>
  )
}

export default ErrorDialog
