/* Copyright 2013 - 2024 Waiterio LLC */
import AndroidToast from '@waiterio/shared/AndroidToast.js'
import translate from '../translate/index.js'

export const toast = message => {
  let content = translate(message)

  content = content && content.replace(new RegExp('\\n', 'g'), '<br>')

  return new AndroidToast({ content })
}

export const toastError = error => {
  let message = error && error.toString && error.toString()
  message = message && message.length ? message : 'An error occurred'

  return toast(message)
}
