/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'
import unselectable from '@waiterio/styles/unselectable.js'

export default css`
  label: clickable;
  ${unselectable};
  outline: none;

  @media (hover: hover) {
    &:not(:disabled):hover {
      background-image: linear-gradient(
        var(--color-hover, rgba(0, 0, 0, 0.1)),
        var(--color-hover, rgba(0, 0, 0, 0.1))
      );
      cursor: pointer;
    }
  }

  &:not(:disabled):active {
    background-image: linear-gradient(
      var(--color-active, rgba(0, 0, 0, 0.2)),
      var(--color-active, rgba(0, 0, 0, 0.2))
    );
    cursor: pointer;
  }
`
