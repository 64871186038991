/* Copyright 2013 - 2024 Waiterio LLC */
import put from '@waiterio/api-client/put.js'

export default function updateCategory(restaurantId, menuId, category) {
  return put({
    url: 'menus/' + menuId + '/categories/' + category.id,
    body: category,
  }).catch(error => {
    throw new Error(`Couldn't update category\n${error}`)
  })
}
