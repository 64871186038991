/* Copyright 2013 - 2024 Waiterio LLC */
function Subscription(source) {
  Object.keys(source || {}).forEach(key => {
    this[key] = source[key]
  })

  if (!this.creationTime) {
    this.creationTime = 0
  }

  if (!this.lastEditTime) {
    this.lastEditTime = this.creationTime || 0
  }

  if (!this.startTime) {
    this.startTime = 0
  }

  if (!this.currentPeriodStartTime) {
    this.currentPeriodStartTime = 0
  }

  if (!this.currentPeriodEndTime) {
    this.currentPeriodEndTime = 0
  }
}

Subscription.prototype.constructor = Subscription

Subscription.Type = {}
Subscription.Type.BRAINTREE_SUBSCRIPTION = 'BRAINTREE_SUBSCRIPTION'
Subscription.Type.MANUAL_SUBSCRIPTION = 'MANUAL_SUBSCRIPTION'
Subscription.Type.STRIPE_SUBSCRIPTION = 'STRIPE_SUBSCRIPTION'

Subscription.Status = {}
Subscription.Status.ACTIVE = 'ACTIVE'
Subscription.Status.PAST_DUE = 'PAST_DUE'
Subscription.Status.CANCELED = 'CANCELED'

export default Subscription
