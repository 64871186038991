/* Copyright 2013 - 2024 Waiterio LLC */
import { produce } from '@monorepo/context/index.js'

export default async function onSetTapToPayCapability(message) {
  try {
    message ||= {}
    let { isTapToPayCapable } = message

    produce(draft => {
      draft.isTapToPayCapable = isTapToPayCapable
    })
  } catch (error) {
    console.error(error)
  }
}
