/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { PureComponent } from 'react'
import { jsx } from '@emotion/react'
import Payment from 'payment'

import IS_DEVELOPMENT from '@monorepo/env/IS_DEVELOPMENT.js'

import cardCss from '@waiterio/styles/card.js'
import field from '@waiterio/styles/field.js'
import fieldLabel from '@waiterio/styles/fieldLabel.js'
import fieldValue from '@waiterio/styles/fieldValue.js'
import greenButton from '@waiterio/styles/greenButton.js'
import inputable from '@waiterio/styles/inputable.js'
import section from '@waiterio/styles/section.js'
import translate from '../translate/index.js'
import semiDivider from '../styles/semiDivider.js'

const getCardErrors = card => {
  const cardType = Payment.fns.cardType(card.number)
  const invalidCardFullname = !(card.fullname && card.fullname.length > 0)
  const invalidCardNumber = !Payment.fns.validateCardNumber(card.number)
  const invalidCardExpiry = !Payment.fns.validateCardExpiry(card.expiry)
  const invalidCardSecurityCode = !Payment.fns.validateCardCVC(
    card.securityCode,
    cardType,
  )

  return {
    FullnameInput: invalidCardFullname,
    NumberInput: invalidCardNumber,
    ExpiryInput: invalidCardExpiry,
    SecurityCodeInput: invalidCardSecurityCode,
  }
}

export default class CardForm extends PureComponent {
  constructor(props) {
    super(props)

    let card = {}
    let errors = {}
    let submitted = false

    if (IS_DEVELOPMENT) {
      // card.fullname = 'Valid Card Visa'
      // card.number = '4242424242424242'
      // card.expiry = '02 / 28'
      // card.securityCode = 123
      // card.fullname = 'Valid Card Mastercard'
      // card.number = '5555555555554444'
      // card.expiry = '02 / 28'
      // card.securityCode = 123
      // card.fullname = 'Invalid Number'
      // card.number = 4242424242424241
      // card.expiry = '02 / 28'
      // card.securityCode = 123
      // card.fullname = 'Expired card'
      // card.number = 4000000000000069
      // card.expiry = '02 / 28'
      // card.securityCode = 123
      // card.fullname = 'Insufficient Funds'
      // card.number = 4000000000009995
      // card.expiry = '02 / 28'
      // card.securityCode = 123
      // card.fullname = 'Incorrect CVC'
      // card.number = 4000000000000127
      // card.expiry = '02 / 28'
      // card.securityCode = 123
      // card.fullname = 'Lost Card'
      // card.number = 4000000000009987
      // card.expiry = '02 / 28'
      // card.securityCode = 123
      // card.fullname = 'Stolen Card'
      // card.number = 4000000000009979
      // card.expiry = '02 / 28'
      // card.securityCode = 123
      // card.fullname = 'Card Declined'
      // card.number = 4000000000000002
      // card.expiry = '02 / 28'
      // card.securityCode = 123
      // card.fullname = 'Processing Error'
      // card.number = 4000000000000119
      // card.expiry = '02 / 28'
      // card.securityCode = 123
      // errors = getCardErrors(card)
      // submitted = true
    }

    this.state = {
      card,
      errors,
      submitted,
    }
  }

  changeFullname = event => {
    const fullname = event.target.value
    this.changeCard({ ...this.state.card, fullname })
  }

  changeNumber = event => {
    let number = event.target.value
    number = number.replace(/[^0-9]/g, '')
    this.changeCard({ ...this.state.card, number })
  }

  changeExpiry = event => {
    let previousExpiry = this.state.card.expiry
    let expiry = event.target.value
    let change = (expiry?.length || 0) - (previousExpiry?.length || 0)

    expiry = expiry.replace(/[^0-9/]/g, '')
    expiry = expiry.split('//').join('/')

    if (/^[2-9]$/.test(expiry)) {
      expiry = `0${expiry}`
    }

    if (change > 0) {
      switch (expiry) {
        case '01':
        case '02':
        case '03':
        case '04':
        case '05':
        case '06':
        case '07':
        case '08':
        case '09':
        case '10':
        case '11':
        case '12':
          expiry += '/'
          break
        case '0/':
          expiry = '0'
          break
        case '1/':
          expiry = '1'
          break
      }
    } else if (change < 0) {
      switch (expiry) {
        case '01/':
        case '02/':
        case '03/':
        case '04/':
        case '05/':
        case '06/':
        case '07/':
        case '08/':
        case '09/':
        case '10/':
        case '11/':
        case '12/':
          expiry = expiry.substring(0, 1)
      }
    }

    expiry = expiry.replace('/', ' / ')

    this.changeCard({ ...this.state.card, expiry })
  }

  changeSecurityCode = event => {
    let securityCode = event.target.value
    securityCode = securityCode.replace(/[^0-9]/g, '')
    this.changeCard({ ...this.state.card, securityCode })
  }

  changeCard = card => {
    card = {
      fullname: card.fullname,
      number: Payment.fns.formatCardNumber(card.number),
      expiry: card.expiry,
      securityCode: card.securityCode,
    }

    const errors = getCardErrors(card)

    this.setState(previousState => ({
      card,
      errors: { ...previousState.errors, ...errors },
    }))
  }

  onSubmit = event => {
    event.preventDefault()
    let { card, errors } = this.state

    this.setState({
      submitted: true,
    })

    if (
      !errors.FullnameInput &&
      !errors.NumberInput &&
      !errors.ExpiryInput &&
      !errors.SecurityCodeInput
    ) {
      this.props.onSubmit(card)
    }
  }

  render() {
    const { card, errors, submitted } = this.state
    const { submitLabel = 'Submit' } = this.props

    return (
      <div css={[cardCss, section]}>
        <form onSubmit={this.onSubmit} autoComplete="on">
          <div
            css={{
              width: '100%',
              textAlign: 'center',
              padding: 12,
              fontWeight: 'bold',
              cursor: 'default',
              boxSizing: 'border-box',
            }}
          >
            {translate('Payment card')}
          </div>

          <div css={semiDivider} />

          <div css={[inputable, field]}>
            <label
              htmlFor="cc-name"
              css={[
                fieldLabel,
                { color: submitted && errors.FullnameInput && 'red' },
              ]}
            >
              {translate('fullname')}
            </label>
            <input
              id="cc-name"
              name="ccname"
              autoComplete="cc-name"
              placeholder={translate('fullname')}
              type="text"
              required
              css={fieldValue}
              value={card.fullname || ''}
              onChange={this.changeFullname}
            />
          </div>

          <div css={semiDivider} />

          <div css={[inputable, field]}>
            <label
              htmlFor="cc-number"
              css={[
                fieldLabel,
                { color: submitted && errors.NumberInput && 'red' },
              ]}
            >
              {translate('number')}
            </label>
            <input
              id="cc-number"
              name="cardnumber"
              autoComplete="cc-number"
              placeholder="**** **** **** ****"
              type="tel"
              required
              css={fieldValue}
              value={card.number || ''}
              onChange={this.changeNumber}
            />
          </div>

          <div css={semiDivider} />

          <div css={[inputable, field]}>
            <label
              htmlFor="cc-exp"
              css={[
                fieldLabel,
                { color: submitted && errors.ExpiryInput && 'red' },
              ]}
            >
              {translate('expiry')}
            </label>
            <input
              id="cc-exp"
              name="exp-date"
              autoComplete="cc-exp"
              placeholder={translate('MM / YY')}
              type="tel"
              minLength="5"
              maxLength="7"
              required
              css={fieldValue}
              value={card.expiry || ''}
              onChange={this.changeExpiry}
            />
          </div>

          <div css={semiDivider} />

          <div css={[inputable, field]}>
            <label
              htmlFor="cc-csc"
              css={[
                fieldLabel,
                { color: submitted && errors.SecurityCodeInput && 'red' },
              ]}
            >
              {translate('security code')}
            </label>
            <input
              id="cc-csc"
              name="cvc"
              autoComplete="cc-csc"
              placeholder="123"
              type="tel"
              minLength="3"
              maxLength="4"
              required
              css={fieldValue}
              value={card.securityCode || ''}
              onChange={this.changeSecurityCode}
            />
          </div>

          <button
            css={[
              greenButton,
              {
                height: 48,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            ]}
            type="submit"
            data-testid="subscribe"
          >
            {translate(submitLabel)}
          </button>
        </form>
      </div>
    )
  }
}
