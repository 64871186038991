/* Copyright 2013 - 2024 Waiterio LLC */
import failureSignup from '@waiterio/api-client/failureSignup.js'
import post from '@waiterio/api-client/post.js'

export default function signup(loginRequest) {
  return post(
    { url: 'authentication/signup', body: loginRequest },
    failureSignup,
  )
}
