/* Copyright 2013 - 2024 Waiterio LLC */
export default () => {
  let result = false

  if (typeof window !== 'undefined') {
    if (window.electron) {
      result = true
    } else if (window.require) {
      window.electron = window.require('electron').remote.getGlobal('electron')
      result = true
    }
  }

  return result
}
