/* Copyright 2013 - 2024 Waiterio LLC */
import { darken, lighten, transparentize } from 'polished'
import green from '@waiterio/styles/green.js'
import red from '@waiterio/styles/red.js'

function generateTheme({
  colorPrimary = '#021a25',
  colorSecondary = '#ffffff',
}) {
  let colorPrimaryLight = lighten(0.08, colorPrimary)
  let colorSecondaryLight = lighten(0.08, colorSecondary)

  return {
    '--color-primary': colorPrimary,
    '--color-secondary': colorSecondary,
    '--color-hover': transparentize(0.7, colorPrimaryLight),
    '--color-active': transparentize(0.7, colorPrimary),
    '--color-primary-dark': darken(0.08, colorPrimary),
    '--color-primary-darker': darken(0.16, colorPrimary),
    '--color-primary-darkest': darken(0.24, colorPrimary),
    '--color-primary-light': colorPrimaryLight,
    '--color-primary-lighter': lighten(0.16, colorPrimary),
    '--color-primary-lightest': lighten(0.24, colorPrimary),
    '--color-secondary-dark': darken(0.08, colorSecondary),
    '--color-secondary-darker': darken(0.16, colorSecondary),
    '--color-secondary-darkest': darken(0.24, colorSecondary),
    '--color-secondary-light': colorSecondaryLight,
    '--color-secondary-lighter': lighten(0.16, colorSecondaryLight),
    '--color-secondary-lightest': lighten(0.24, colorSecondaryLight),
    '--color-green': green,
    '--color-red': red,
    '--color-red-light': lighten(0.08, red),
    '--color-red-lightest': lighten(0.24, red),
    '--color-red-dark': darken(0.08, red),
  }
}

export default generateTheme
