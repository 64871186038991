/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'
import singleLine from '@waiterio/styles/singleLine.js'
import clickable from '@waiterio/styles/clickable.js'

export default css(singleLine, clickable, {
  display: 'flex',
  padding: '0 8px',
  minWidth: '3.5em',
  lineHeight: '56px',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
})
