/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable camelcase */
/** @jsx jsx */
import React, { PureComponent } from 'react'
import { css, jsx } from '@emotion/react'
import { GoogleLogin } from 'react-google-login'
import { connect } from '@monorepo/context/index.js'
import convertStringToColor from '@monorepo/shared/convertStringToColor.js'
import normalizeEmail from '@monorepo/shared/normalizeEmail.js'
import singleLine from '@waiterio/styles/singleLine.js'
import card from '@waiterio/styles/card.js'
import clickable from '@waiterio/styles/clickable.js'
import field from '@waiterio/styles/field.js'
import fieldLabel from '@waiterio/styles/fieldLabel.js'
import fieldValue from '@waiterio/styles/fieldValue.js'
import greenButton from '@waiterio/styles/greenButton.js'
import inputable from '@waiterio/styles/inputable.js'
import redButton from '@waiterio/styles/redButton.js'
import unselectable from '@waiterio/styles/unselectable.js'
import getBrowserHistory from '../getBrowserHistory.js'
import getGoogleClientId from '../services/getGoogleClientId.js'

import Header from '../components/Header.js'

import ErrorDialog from '../components/ErrorDialog.js'
import LoadingDialog from '../components/LoadingDialog.js'
import SelectRestaurantDialog from '../dialogs/SelectRestaurantDialog.js'

import * as loginManager from '../services/LoginManager.js'
import translate from '../translate/index.js'

import semiDivider from '../styles/semiDivider.js'

class LoginPage extends PureComponent {
  componentWillUnmount() {
    this.reset()
  }

  changeEmail = ({ target: { value: email } }) => {
    this.props.produce(draft => {
      draft.email = email
    })
  }

  changePassword = ({ target: { value: password } }) => {
    this.props.produce(draft => {
      draft.password = password
    })
  }

  loginWithEmail = () => {
    this.props.produce(draft => {
      draft.logging = true
    })

    let { email, password } = this.props

    email = normalizeEmail(email)

    loginManager
      .loginWithEmail(email, password)
      .then(this.onLoginSucceed)
      .catch(this.showError)
  }

  loginWithGoogle = access_token => {
    this.props.produce(draft => {
      draft.logging = true
    })

    loginManager
      .loginWithGoogle(access_token)
      .then(this.onLoginSucceed)
      .catch(this.showError)
  }

  onLoginSucceed = loginResponse => {
    const restaurantsIds = {}
    const restaurants = []

    for (let i = 0; i < loginResponse.roles.length; i += 1) {
      const role = loginResponse.roles[i]
      const { restaurantId } = role
      if (restaurantId && restaurantId.trim().length > 0) {
        if (!restaurantsIds[restaurantId]) {
          const name =
            role.restaurantName && role.restaurantName.length > 0
              ? role.restaurantName
              : role.restaurantId

          const restaurant = {
            _id: role.restaurantId,
            name,
            initial: name.charAt(0),
            color: convertStringToColor(role.restaurantId),
          }

          restaurants.push(restaurant)

          restaurantsIds[restaurantId] = true
        }
      }
    }

    const restaurantsIdsKeys = Object.keys(restaurantsIds)

    if (restaurantsIdsKeys.length > 1) {
      this.props.produce(draft => {
        draft.logging = false
        draft.restaurants = restaurants
      })
    } else if (restaurantsIdsKeys.length === 1) {
      this.fetchDataByRestaurantId(restaurantsIdsKeys[0])
    } else {
      this.showError(
        new Error(
          'the user fetched from server has no roles with a non-empty restaurantId',
        ),
      )
    }
  }

  fetchDataByRestaurantId = restaurantId => {
    loginManager
      .fetchData(restaurantId)
      .then(() => {
        let url = this.props.redirect || '/orders'
        url += window.location.search
        getBrowserHistory().push(url)
      })
      .catch(this.showError)
  }

  onSelectRestaurant = restaurant => {
    this.props.produce(draft => {
      draft.logging = true
    })

    loginManager
      .fetchData(restaurant._id)
      .then(() => {
        let url = this.props.redirect || '/orders'
        url += window.location.search
        getBrowserHistory().push(url)
      })
      .catch(this.showError)
  }

  onResponseFromGoogle = response => {
    if (response && response.accessToken) {
      this.loginWithGoogle(response.accessToken)
    }
  }

  hideError = () => {
    this.props.produce(draft => {
      draft.error = null
    })
  }

  hideRestaurants = () => {
    this.props.produce(draft => {
      draft.restaurants = null
    })
  }

  reset = () => {
    this.props.produce()
  }

  showError = error => {
    console.error(error)

    this.props.produce(draft => {
      draft.logging = false
      draft.restaurants = null
      draft.error = error
    })
  }

  render() {
    const {
      device,
      email,
      password,
      passwordChanged,
      logging,
      error,
      restaurants,
    } = this.props

    return (
      <>
        <Header title={translate('Login')} href="landing" />

        <div
          css={[unselectable, { maxWidth: 480, margin: '0 auto', padding: 16 }]}
        >
          {passwordChanged && (
            <div
              css={{
                textAlign: 'center',
                background: 'white',
                padding: 16,
                marginBottom: 16,
              }}
            >
              {translate('Password changed successfully')}
            </div>
          )}

          {!device.androidNative && !device.iosNative && !device.automation && (
            <GoogleLogin
              clientId={getGoogleClientId()}
              buttonText={translate('Login with Google')}
              onSuccess={this.onResponseFromGoogle}
              onFailure={this.onResponseFromGoogle}
              css={[redButton, { width: '100%', borderRadius: 4 }]}
            />
          )}

          {!device.androidNative && !device.iosNative && !device.automation && (
            <div
              css={{
                lineHeight: '48px',
                textTransform: 'uppercase',
                textAlign: 'center',
                color: '#a9a9a9',
                fontSize: 14,
              }}
            >
              {translate('or')}
            </div>
          )}

          <LoginWithEmailForm
            onSubmit={this.loginWithEmail}
            email={email}
            password={password}
            changeEmail={this.changeEmail}
            changePassword={this.changePassword}
          />

          <div css={{ display: 'flex' }}>
            <div
              onClick={_ => getBrowserHistory().push('forgotpassword')}
              css={[
                clickable,
                singleLine,
                {
                  flexGrow: 1,
                  padding: ' 0 16px',
                  lineHeight: '48px',
                  color: '#666666',
                  textAlign: 'left',
                  textTransform: 'uppercase',
                  fontSize: 14,
                },
              ]}
              data-testid="forgotpassword"
            >
              {translate('Forgot password')}
            </div>
            <div
              onClick={_ => getBrowserHistory().push('signup')}
              css={[
                clickable,
                singleLine,
                {
                  color: 'var(--color-primary)',
                  flexGrow: 1,
                  padding: '0 16px',
                  lineHeight: '48px',
                  fontWeight: 'bold',
                  textAlign: 'right',
                  textTransform: 'uppercase',
                  fontSize: 14,
                },
              ]}
              data-testid="signup"
            >
              {translate('Sign up')}
            </div>
          </div>
        </div>

        {logging && (
          <LoadingDialog
            title={translate('Logging in')}
            message={translate('logging in waiterio')}
          />
        )}
        {error && (
          <ErrorDialog
            title={translate('Error')}
            message={
              error && error.toString()
                ? translate(error.toString())
                : translate('An error occurred')
            }
            close={this.hideError}
          />
        )}
        {restaurants && restaurants.length && (
          <SelectRestaurantDialog
            restaurants={restaurants}
            onSelect={this.onSelectRestaurant}
            close={this.hideRestaurants}
          />
        )}
      </>
    )
  }
}

class LoginWithEmailForm extends PureComponent {
  onSubmit = event => {
    event.preventDefault()
    this.props.onSubmit()
  }

  render() {
    return (
      <form onSubmit={this.onSubmit} autoComplete="on" css={card}>
        <div css={[inputable, field]}>
          <label htmlFor="emailInput" css={fieldLabel}>
            {translate('email')}
          </label>
          <input
            id="emailInput"
            css={fieldValue}
            type="email"
            name="email"
            autoComplete="email"
            autoCapitalize="none"
            autoCorrect="off"
            required
            value={this.props.email || ''}
            onChange={this.props.changeEmail}
          />
        </div>
        <div css={semiDivider} />
        <div css={[inputable, field]}>
          <label htmlFor="passwordInput" css={fieldLabel}>
            {translate('password')}
          </label>
          <input
            id="passwordInput"
            css={fieldValue}
            type="password"
            name="password"
            autoComplete="current-password"
            autoCapitalize="none"
            autoCorrect="off"
            required
            value={this.props.password || ''}
            onChange={this.props.changePassword}
          />
        </div>

        <button data-testid="login-with-email" type="submit" css={greenButton}>
          {translate('Login with Email')}
        </button>
      </form>
    )
  }
}

export default connect((context, ownProps, produce) => {
  const props = context.pages.login
  const { device } = context

  const params = new URLSearchParams(ownProps.location.search)
  const email = params.get('email')
  const password = params.get('password')
  const passwordChanged = params.get('passwordChanged')
  const redirect = params.get('redirect')

  return {
    ...props,
    email: props.email || email,
    password: props.password || password,
    passwordChanged,
    redirect,
    device,
    produce: produce(['pages', 'login']),
  }
})(LoginPage)
