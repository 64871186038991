/* Copyright 2013 - 2024 Waiterio LLC */
import localStorage from 'localStorage'
import localForage from 'localforage'
import { setTokenForApiClient } from '@waiterio/api-client/token.js'
import getCurrentRestaurantId from './getCurrentRestaurantId.js'
import getPreviousAppVersion from './getPreviousAppVersion.js'
import getPreviousRestaurantId from './getPreviousRestaurantId.js'
import setPreviousAppVersion from './setPreviousAppVersion.js'
import setPreviousRestaurantId from './setPreviousRestaurantId.js'

export default function clearSession() {
  let previousAppVersion = getPreviousAppVersion()
  let restaurantId = getCurrentRestaurantId()

  if (!restaurantId) {
    restaurantId = getPreviousRestaurantId()
  }

  localStorage.clear()
  localForage.clear()

  setTokenForApiClient(null)

  if (restaurantId) {
    setPreviousRestaurantId(restaurantId)
  }

  if (previousAppVersion) {
    setPreviousAppVersion(previousAppVersion)
  }
}
