/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import UUID from './UUID.js'

function Terminal(source) {
  if (source instanceof Terminal) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = UUID.generate()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().getTime()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    return Immutable(this, { prototype: Terminal.prototype })
  }
}

Terminal.prototype.constructor = Terminal

Terminal.Connection = {
  BLUETOOTH: 'BLUETOOTH',
  ETHERNET_WIFI: 'ETHERNET_WIFI',
  INTERNET: 'INTERNET',
  TAP_TO_PAY: 'TAP_TO_PAY',
  USB: 'USB',
}

export default Terminal
