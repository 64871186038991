/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { useContext } from '@monorepo/context/index.js'
import updateTheme from '@waiterio/styles/theme/updateTheme.js'
import getColorPrimaryFromFlavor from '../styles/getColorPrimaryFromFlavor.js'

const ThemeInjector = () => {
  let [context] = useContext()

  const flavor = context?.device?.flavor
  const colorPrimary = getColorPrimaryFromFlavor(flavor)
  updateTheme(colorPrimary)

  return null
}

export default ThemeInjector
