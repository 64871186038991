/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { PureComponent } from 'react'
import { css, jsx } from '@emotion/react'
import { connect } from '@monorepo/context/index.js'
import convertStringToColor from '@monorepo/shared/convertStringToColor.js'
import normalizeEmail from '@monorepo/shared/normalizeEmail.js'
import preferCapitalizedEachWord from '@waiterio/shared/preferCapitalizedEachWord.js'
import card from '@waiterio/styles/card.js'
import clickable from '@waiterio/styles/clickable.js'
import field from '@waiterio/styles/field.js'
import fieldLabel from '@waiterio/styles/fieldLabel.js'
import fieldValue from '@waiterio/styles/fieldValue.js'
import greenButton from '@waiterio/styles/greenButton.js'
import inputable from '@waiterio/styles/inputable.js'
import singleLine from '@waiterio/styles/singleLine.js'
import getBrowserHistory from '../getBrowserHistory.js'
import Header from '../components/Header.js'
import ErrorDialog from '../components/ErrorDialog.js'
import LoadingDialog from '../components/LoadingDialog.js'
import SelectRestaurantDialog from '../dialogs/SelectRestaurantDialog.js'
import * as loginManager from '../services/LoginManager.js'
import translate from '../translate/index.js'
import semiDivider from '../styles/semiDivider.js'

class SignupPage extends PureComponent {
  componentWillUnmount() {
    this.reset()
  }

  changeEmail = event => {
    const email = event.target.value
    this.props.produce(draft => {
      draft.email = email
    })
  }

  changePassword = event => {
    const password = event.target.value
    this.props.produce(draft => {
      draft.password = password
    })
  }

  changePin = event => {
    const pin = event.target.value
    this.props.produce(draft => {
      draft.pin = pin
    })
  }

  changeFirstname = event => {
    const firstName = event.target.value
    this.props.produce(draft => {
      draft.firstName = firstName
    })
  }

  changeLastname = event => {
    const lastName = event.target.value
    this.props.produce(draft => {
      draft.lastName = lastName
    })
  }

  hideError = () => {
    this.props.produce(draft => {
      delete draft.error
    })
  }

  hideRestaurants = () => {
    this.props.produce(draft => {
      delete draft.restaurants
    })
  }

  fetchDataByRestaurantId = restaurantId => {
    loginManager
      .fetchData(restaurantId)
      .then(() => {
        getBrowserHistory().push('/orders')
      })
      .catch(this.showError)
  }

  reset = () => {
    this.props.produce()
  }

  selectRestaurant = restaurant => {
    this.fetchDataByRestaurantId(restaurant._id)
  }

  showError = error => {
    this.props.produce(draft => {
      draft.signing = false
      delete draft.restaurants
      draft.error = error
    })
  }

  signup = async event => {
    try {
      event.preventDefault()

      let { email, password, pin, firstName, lastName } = this.props

      email = normalizeEmail(email?.trim())
      firstName = preferCapitalizedEachWord(firstName?.trim())
      lastName = preferCapitalizedEachWord(lastName?.trim())

      this.props.produce(draft => {
        draft.signing = true
      })

      let loginResponse = await loginManager.signup({
        email,
        password,
        pin,
        firstName,
        lastName,
      })

      const restaurantsIds = {}
      const restaurants = []

      for (let i = 0; i < loginResponse.roles.length; i += 1) {
        const role = loginResponse.roles[i]
        const { restaurantId } = role
        if (restaurantId && restaurantId.trim().length > 0) {
          if (!restaurantsIds[restaurantId]) {
            const restaurantName =
              role.restaurantName && role.restaurantName.length > 0
                ? role.restaurantName
                : role.restaurantId

            const restaurant = {
              _id: role.restaurantId,
              name: restaurantName,
              initial: role.restaurantName.charAt(0),
              color: convertStringToColor(role.restaurantId),
            }

            restaurants.push(restaurant)

            restaurantsIds[restaurantId] = true
          }
        }
      }

      const restaurantsIdsKeys = Object.keys(restaurantsIds)

      if (restaurantsIdsKeys.length > 1) {
        this.props.produce(draft => {
          draft.signing = false
          draft.restaurants = restaurants
        })
      } else if (restaurantsIdsKeys.length === 1) {
        this.fetchDataByRestaurantId(restaurantsIdsKeys[0])
      } else {
        throw new Error(
          'the user fetched from server has no roles with a non-empty restaurantId',
        )
      }
    } catch (error) {
      this.showError(error)
    }
  }

  render() {
    const { email, password, pin, firstName, lastName, signing, error } =
      this.props

    return (
      <>
        <Header title={translate('Sign up')} href="landing" />

        <div css={{ padding: 16, maxWidth: 480, margin: '0 auto' }}>
          <form onSubmit={this.signup} autoComplete="on" css={card}>
            <div css={[inputable, field]}>
              <label htmlFor="emailInput" css={fieldLabel}>
                {translate('email')}
              </label>
              <input
                id="emailInput"
                css={fieldValue}
                type="email"
                name="email"
                autoComplete="email"
                autoCapitalize="none"
                autoCorrect="off"
                required
                value={email || ''}
                onChange={this.changeEmail}
              />
            </div>
            <div css={semiDivider} />
            <div css={[inputable, field]}>
              <label htmlFor="passwordInput" css={fieldLabel}>
                {translate('password')}
              </label>
              <input
                id="passwordInput"
                css={fieldValue}
                type="password"
                name="password"
                autoComplete="current-password"
                autoCapitalize="none"
                autoCorrect="off"
                required
                minLength="6"
                value={password || ''}
                onChange={this.changePassword}
              />
            </div>
            <div css={semiDivider} />
            <div css={[inputable, field]}>
              <label htmlFor="pinInput" css={fieldLabel}>
                {translate('PIN')}
              </label>
              <input
                id="pinInput"
                css={fieldValue}
                type="tel"
                name="pin"
                autoComplete="current-pin"
                autoCapitalize="none"
                autoCorrect="off"
                required
                pattern="[0-9]*"
                minLength="4"
                maxLength="4"
                placeholder="1234"
                value={pin || ''}
                onChange={this.changePin}
              />
            </div>
            <div css={semiDivider} />
            <div css={[inputable, field]}>
              <label htmlFor="firstNameInput" css={fieldLabel}>
                {translate('first name')}
              </label>
              <input
                id="firstNameInput"
                css={fieldValue}
                type="text"
                name="fname"
                autoComplete="given-name"
                autoCorrect="off"
                required
                value={firstName || ''}
                onChange={this.changeFirstname}
              />
            </div>
            <div css={semiDivider} />
            <div css={[inputable, field]}>
              <label htmlFor="lastNameInput" css={fieldLabel}>
                {translate('last name')}
              </label>
              <input
                id="lastNameInput"
                css={fieldValue}
                type="text"
                name="lname"
                autoComplete="family-name"
                autoCorrect="off"
                required
                value={lastName || ''}
                onChange={this.changeLastname}
              />
            </div>

            <button type="submit" css={greenButton} data-testid="signup">
              {translate('Sign up')}
            </button>
          </form>
          <div css={{ display: 'flex' }}>
            <div css={{ flex: 1 }}>&nbsp;</div>
            <div
              onClick={_ => getBrowserHistory().push('login')}
              css={[
                clickable,
                singleLine,
                {
                  color: 'var(--color-primary)',
                  flex: 1,
                  padding: '0 16px',
                  lineHeight: '48px',
                  fontWeight: 'bold',
                  textAlign: 'right',
                  textTransform: 'uppercase',
                  fontSize: 14,
                },
              ]}
            >
              {translate('Login')}
            </div>
          </div>
        </div>

        {signing && (
          <LoadingDialog
            title={translate('Signing up')}
            message={translate('logging in waiterio')}
          />
        )}
        {error && (
          <ErrorDialog
            title={translate('Error')}
            message={
              error && error.toString()
                ? translate(error.toString())
                : translate('An error occurred')
            }
            close={this.hideError}
          />
        )}
        {this.props.restaurants && this.props.restaurants.length && (
          <SelectRestaurantDialog
            restaurants={this.props.restaurants}
            onSelect={this.selectRestaurant}
            close={this.hideRestaurants}
          />
        )}
      </>
    )
  }
}

export default connect((context, ownProps, produce) => {
  let props = {
    ...context.pages.signup,
    produce: produce(['pages', 'signup']),
  }

  // console.log(props);

  return props
})(SignupPage)
