/* Copyright 2013 - 2024 Waiterio LLC */
import delete_ from '@waiterio/api-client/delete_.js'

export default function deleteCategory(restaurantId, menuId, categoryId) {
  return delete_({
    url: 'menus/' + menuId + '/categories/' + categoryId,
  }).catch(error => {
    throw new Error(`Couldn't delete category\n${error}`)
  })
}
