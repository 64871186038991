/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'
import clickable from '@waiterio/styles/clickable.js'

export default css`
  label: inputable;
  ${clickable};

  @media (hover: hover) {
    &:not(:disabled):hover {
      cursor: text;
    }
  }

  &:not(:disabled):active {
    cursor: text;
  }
`
