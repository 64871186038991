/* Copyright 2013 - 2024 Waiterio LLC */
/* global metricsmage */
import getSentryBrowser from '@monorepo/telemetry/getSentryBrowser.js'
import domainProduction from '@waiterio/shared/domainProduction.js'
import AnalyticsInternal from './AnalyticsInternal.js'
import getCurrentRestaurantId from '../session/getCurrentRestaurantId.js'
import identifyNative from '../native/identify.js'
import { pickRestaurant } from '../forage/RestaurantsForage.js'
import { pickCurrentSubscription } from '../forage/SubscriptionsForage.js'
import { pickUser } from '../forage/UsersForage.js'

export const identify = () =>
  Promise.all([pickRestaurant(), pickUser(), pickCurrentSubscription()]).then(
    ([restaurant, user, subscription]) => {
      restaurant ||= {}
      user ||= {}
      let nickname = user?.getFullname?.()
      identifyNative({
        analytics: restaurant
          ? 'https://analytics.waiterio.com/restaurants/' + restaurant._id
          : undefined,
        restaurant,
        subscription,
        user: {
          ...user,
          id: user ? user._id : undefined,
          name: nickname,
          email: user ? user.email : undefined,
        },
      })

      // Sentry

      const sentry = getSentryBrowser()

      if (sentry?.configureScope) {
        sentry.configureScope(scope => {
          scope.setUser({
            id: user ? user._id : undefined,
            email: user ? user.email : undefined,
          })

          scope.setExtra('name', nickname)
          scope.setExtra(
            'analytics',
            restaurant
              ? 'https://analytics.waiterio.com/restaurants/' + restaurant._id
              : undefined,
          )
          scope.setExtra('restaurant', restaurant)
          scope.setExtra('subscription', subscription)
          scope.setExtra('user', user)
        })
      }

      // globo
      if (typeof window !== 'undefined') {
        window.$globo ||= []
        window.$globo.push(['showChat'])
        window.$globo.push(['setUserId', user?._id])
        window.$globo.push(['setUserEmail', user?.email])
        window.$globo.push(['setUserAvatarUrl', user?.avatarUrl])
        window.$globo.push(['setUserNickname', nickname])
        window.$globo.push([
          'setMetadata',
          'analytics',
          `https://analytics.${domainProduction}/users/${user._id}`,
        ])

        if (restaurant?._id) {
          window.$globo.push(['setMetadata', 'restaurantId', restaurant?._id])
        }

        if (subscription?.stripeCustomerId) {
          window.$globo.push([
            'setMetadata',
            'stripeCustomerId',
            subscription?.stripeCustomerId,
          ])
        }
        if (subscription?.stripeSubscriptionId) {
          window.$globo.push([
            'setMetadata',
            'stripeSubscriptionId',
            subscription?.stripeSubscriptionId,
          ])
        }
      }

      // Google Tag Manager

      if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'login',
          userId: user ? user._id : undefined,
        })
      }

      // Metricsmage
      if (typeof metricsmage !== 'undefined') {
        metricsmage.identify(user?._id, {
          subscription,
        })
      }
    },
  )

export const track = (event, properties) => {
  AnalyticsInternal.track(event, properties)

  // Metricsmage
  if (typeof metricsmage !== 'undefined') {
    let restaurantId = getCurrentRestaurantId()
    metricsmage.event(event, { restaurantId, ...properties })
  }
}

export const trackVisitedSubscribeToPlanPage = plan => {
  track('Visited Subscribe to plan Page', { planId: plan._id })
}

export const trackSubscribedSuccessfullyToPlan = plan => {
  track('Subscribed successfully to plan', { planId: plan._id })
}
