/* Copyright 2013 - 2024 Waiterio LLC */
import generateTheme from './generateTheme.js'

function updateTheme(colorPrimary) {
  if (typeof document !== 'undefined') {
    const theme = generateTheme({ colorPrimary })

    Object.keys(theme).forEach(themeKey => {
      const themeValue = theme[themeKey]
      document.documentElement.style.setProperty(themeKey, themeValue)
    })
  }
}

export default updateTheme
