/* Copyright 2013 - 2024 Waiterio LLC */
import failureLogin from '@waiterio/api-client/failureLogin.js'
import post from '@waiterio/api-client/post.js'

export default function changePassword(changePasswordRequest) {
  return post(
    { url: 'authentication/changepassword', body: changePasswordRequest },
    failureLogin,
  )
}
