/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import loadable from '@loadable/component'

import ErrorBoundary from '@waiterio/components/ErrorBoundary.js'
import ErrorPage from './pages/ErrorPage.js'
import LandingPage from './pages/LandingPage.js'
import LoginPage from './pages/LoginPage.js'
import SignupPage from './pages/SignupPage.js'
import ToastPage from './pages/ToastPage.js'
import ForgotPasswordPage from './pages/ForgotPasswordPage.js'
import LogoutPage from './pages/LogoutPage.js'
import DownloadsPage from './pages/DownloadsPage.js'
import DocumentationPage from './pages/DocumentationPage.js'
import DocumentPage from './pages/DocumentPage.js'
import DowngradeVersionPage from './pages/DowngradeVersionPage.js'
import UpdateCardPage from './pages/UpdateCardPage.js'

const RoutesWithAuthentication = loadable(
  () =>
    import(
      /* webpackChunkName: 'authenticated' */ './RoutesWithAuthentication.js'
    ),
)

const Routes = () => (
  <Switch>
    <Route exact path="/" component={LandingPage} />
    <Route exact path="/error" component={ErrorPage} />
    <Route exact path="/landing" component={LandingPage} />
    <Route exact path="/login" component={LoginPage} />
    <Route exact path="/signup" component={SignupPage} />
    <Route exact path="/toast" component={ToastPage} />
    <Route exact path="/forgotpassword" component={ForgotPasswordPage} />
    <Route exact path="/logout" component={LogoutPage} />
    <Route exact path="/downloads" component={DownloadsPage} />
    <Route exact path="/downgrade-version" component={DowngradeVersionPage} />
    <Route exact path="/documentation" component={DocumentationPage} />
    <Route
      exact
      path="/documentation/:originalDocumentId"
      render={({
        match: {
          params: { originalDocumentId },
        },
      }) => <DocumentPage originalDocumentId={originalDocumentId} />}
    />
    <Route exact path="/update-card" component={UpdateCardPage} />
    <Redirect from="/version*" to="/" />
    <ErrorBoundary>
      <RoutesWithAuthentication />
    </ErrorBoundary>
  </Switch>
)

export default Routes
