/* Copyright 2013 - 2024 Waiterio LLC */
import put from '@waiterio/api-client/put.js'

export default function updateTerminal(restaurantId, terminal) {
  return put({ url: 'terminals/' + terminal._id, body: terminal }).catch(
    error => {
      throw new Error(`Couldn't update terminal\n${error}`)
    },
  )
}
