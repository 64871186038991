/* Copyright 2013 - 2024 Waiterio LLC */
import androidNative from './androidNative.js'
import chromeos from './chromeos.js'
import electron from './electron.js'
import iosNative from './iosNative.js'
import macosNative from './macosNative.js'

const native = androidNative || chromeos || electron || iosNative || macosNative

export default native
