/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { createRef, PureComponent } from 'react'
import { jsx } from '@emotion/react'
import getDocuments from '@globo/client/getDocuments.js'
import IconBack from '@stiloso/icons/IconBack.js'
import { connect } from '@monorepo/context/index.js'
import throttle from '@monorepo/shared/throttle.js'
import debounce from '@monorepo/shared/debounce.js'
import globoWebsiteId from '@waiterio/shared/globoWebsiteId.js'
import SearchBar from '@waiterio/components/SearchBar.js'
import card from '@waiterio/styles/card.js'
import clickable from '@waiterio/styles/clickable.js'
import singleLine from '@waiterio/styles/singleLine.js'
import whiteButton from '@waiterio/styles/whiteButton.js'
import getBrowserHistory from '../getBrowserHistory.js'
import {
  isCurrentUserTestingAccountSelector,
  languageSelector,
} from '../selectors/AppSelectors.js'
import translate from '../translate/index.js'
import ErrorBoundaryGoBack from '../components/ErrorBoundaryGoBack.js'
import NoInternetConnection from '../components/NoInternetConnection.js'
import NoInternetConnectionFixer from '../components/NoInternetConnectionFixer.js'
import loader from '../styles/loader.js'

const DocumentComponent = ({ document }) => (
  <div
    css={[
      card,
      clickable,
      {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 16px',
        marginBottom: 16,
      },
    ]}
    onClick={() =>
      getBrowserHistory().push(`/documentation/${document.originalDocumentId}`)
    }
    data-testid={`document-${document.slug}`}
  >
    {document.title}
  </div>
)

const SearchedDocsComponent = ({ document }) => (
  <div
    css={{ padding: 15, ':hover': { backgroundColor: '#c2e9f7' } }}
    onClick={() =>
      getBrowserHistory().push(`/documentation/${document.originalDocumentId}`)
    }
    data-testid={`searched-document-${document.slug}`}
  >
    {document.title}
  </div>
)

class DocumentationPage extends PureComponent {
  constructor(props) {
    super(props)
    this.autocompleteSearchThrottled = throttle(500, this.autocompleteSearch)
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch)
    this.searchBarRef = createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)

    this.fetchMissingDocuments()
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  fetchMissingDocuments = async () => {
    const { language, documents, fetchingDocuments } = this.props
    if (language && !documents && !fetchingDocuments) {
      this.props.produce(draft => {
        draft.fetchingDocuments = true
        delete draft.error
      })

      try {
        let documents = await getDocuments({
          locale: language,
          websiteId: globoWebsiteId,
        })

        if (documents?.length === 0) {
          documents = await getDocuments({
            locale: 'en',
            websiteId: globoWebsiteId,
          })
        }

        this.props.produce(draft => {
          draft.fetchingDocuments = false
          draft.documents = documents
        })
      } catch (error) {
        this.props.produce(draft => {
          draft.fetchingDocuments = false
          draft.error = translate('A network error occurred')
        })
      }
    }
  }

  onCancelSearch = () => {
    this.props.produce(draft => {
      draft.searchedDocs = []
    })
  }

  onChange = searchQuery => {
    if (searchQuery.length < 4) {
      this.autocompleteSearchThrottled(searchQuery)
    } else {
      this.autocompleteSearchDebounced(searchQuery)
    }

    if (searchQuery.length < 1) {
      this.props.produce(draft => {
        draft.searchedDocs = []
      })
    }
  }

  autocompleteSearch = async searchQuery => {
    let { language } = this.props

    if (searchQuery.length > 0) {
      let documents = await getDocuments({
        websiteId: globoWebsiteId,
        locale: language,
        search: searchQuery,
        sortField: 'creationTime',
        sortDirection: 'DESC',
      })

      this.props.produce(draft => {
        draft.searchedDocs = documents
      })
    }
  }

  handleClickOutside = event => {
    if (
      this.searchBarRef &&
      !this.searchBarRef.current.contains(event.target)
    ) {
      this.onCancelSearch()
    }
  }

  onClickTitle = () => {
    getBrowserHistory().goBack()
  }

  render() {
    const { documents, fetchingDocuments, searchedDocs, error } = this.props

    return (
      <>
        <div
          css={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 1000,
            height: 56,
            backgroundColor: 'var(--color-primary)',
          }}
        >
          <div
            onClick={this.onClickTitle}
            css={[
              clickable,
              {
                padding: '0px 8px',
                width: 98,
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              },
            ]}
          >
            <IconBack
              width={40}
              height={40}
              css={{ padding: 8, fill: 'white' }}
            />
            <span css={[singleLine, { fontSize: 18, color: 'white' }]}>
              {translate('Help')}
            </span>
          </div>
        </div>

        <div css={{ height: 56, minHeight: 56 }} />

        <div
          css={{
            height: 66,
            backgroundColor: 'var(--color-primary)',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 10,
          }}
        >
          <div css={{ maxWidth: 330, width: '100%' }}>
            <SearchBar
              placeholder={translate('Search documentation')}
              onSearch={this.onChange}
              onCancel={this.onCancelSearch}
              searchBarRef={this.searchBarRef}
            >
              {searchedDocs && (
                <div
                  css={{
                    boxShadow:
                      'rgba(0, 0, 0, 0.24) 0px 1.5px 4px, rgba(0, 0, 0, 0.19) 0px 0.5px 2px',
                    width: '100%',
                    left: 0,
                    top: 50,
                    overflowY: 'scroll',
                    position: 'absolute',
                    cursor: 'pointer',
                    maxWidth: 500,
                    maxHeight: 400,
                    backgroundColor: 'white',
                  }}
                >
                  {searchedDocs.map(document => (
                    <SearchedDocsComponent
                      key={document._id}
                      document={document}
                    />
                  ))}
                </div>
              )}
            </SearchBar>
          </div>
        </div>

        <NoInternetConnection />
        <NoInternetConnectionFixer />

        {fetchingDocuments && <div css={loader} />}

        {!documents && error && (
          <div css={{ position: 'absolute', width: '100%', top: '50%' }}>
            <div css={{ display: 'flex', justifyContent: 'center' }}>
              {error}
            </div>
            <button
              type="button"
              onClick={this.fetchMissingDocuments}
              css={[whiteButton, { margin: '14px auto' }]}
            >
              {translate('Try again')}
            </button>
          </div>
        )}

        {documents && (
          <div css={{ padding: 16 }}>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 500,
                margin: '0 auto',
              }}
            >
              {documents.map(document => (
                <DocumentComponent key={document._id} document={document} />
              ))}
            </div>
          </div>
        )}
      </>
    )
  }
}

const DocumentationPageConnected = connect((context, props, produce) => {
  let { device } = context
  let { documents, fetchingDocuments, error, searchQuery, searchedDocs } =
    context.pages.documentation

  const isCurrentUserTestingAccount =
    isCurrentUserTestingAccountSelector(context)
  const language = languageSelector(context)

  if (isCurrentUserTestingAccount || device.underReview) {
    documents = documents?.filter(
      ({ originalDocumentId }) =>
        originalDocumentId !== '0f0ca39a026b95797add3e00',
    )
    searchedDocs = searchedDocs?.filter(
      ({ originalDocumentId }) =>
        originalDocumentId !== '0f0ca39a026b95797add3e00',
    )
  }

  return {
    documents,
    fetchingDocuments,
    language,
    searchQuery,
    searchedDocs,
    error,
    produce: produce(['pages', 'documentation']),
  }
})(DocumentationPage)

const DocumentationPageWrapper = props => (
  <ErrorBoundaryGoBack>
    <DocumentationPageConnected {...props} />
  </ErrorBoundaryGoBack>
)

export default DocumentationPageWrapper
