/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect } from 'react'
import { jsx } from '@emotion/react'
import { connect } from '@monorepo/context/index.js'
import clearSession from '../session/clearSession.js'
import getBrowserHistory from '../getBrowserHistory.js'
import getPreviousAppVersion from '../session/getPreviousAppVersion.js'
import version from '../version.js'

const DowngradeVersion = () => {
  let previousAppVersion = getPreviousAppVersion()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)

      if (previousAppVersion && previousAppVersion !== version) {
        clearSession()
        window.location.href = `/version${previousAppVersion}.html`
      } else {
        getBrowserHistory().push('/logout')
      }
    }
  })

  return <h1>Downgrade version</h1>
}

export default connect((context, props, produce) => ({}))(DowngradeVersion)
