/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import Modal from '@waiterio/components/Modal.js'
import ModalHeader from '@waiterio/components/ModalHeader.js'
import clickable from '@waiterio/styles/clickable.js'
import translate from '../translate/index.js'

export default ({ close, onSelect, restaurants }) => (
  <Modal close={close}>
    <ModalHeader>{translate('Select restaurant')}</ModalHeader>
    {restaurants.map(restaurant => (
      <RestaurantRow
        key={restaurant._id}
        restaurant={restaurant}
        onSelect={() => {
          onSelect(restaurant)
          close()
        }}
      />
    ))}
  </Modal>
)

function RestaurantRow({ onSelect, restaurant }) {
  return (
    <div
      id={restaurant._id}
      css={[
        clickable,
        {
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid #e4e4e4',
          paddingRight: 8,
          verticalAlign: 'middle',
          fontSize: 16,
        },
      ]}
      onClick={event => {
        event.preventDefault()
        onSelect()
      }}
    >
      <div
        css={{
          display: 'flex',
          background: restaurant.color,
          height: 50,
          minWidth: 50,
          width: 50,
          borderRadius: 50,
          margin: 10,
        }}
      >
        <span
          css={{
            alignSelf: 'center',
            width: '100%',
            textAlign: 'center',
            color: 'white',
            fontSize: '30px',
          }}
        >
          {restaurant.initial}
        </span>
      </div>
      <div
        css={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          fontSize: '20px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxHeight: 45,
          paddingRight: 10,
        }}
      >
        {restaurant.name}
      </div>
    </div>
  )
}
