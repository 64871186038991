/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import ErrorBoundary from '@waiterio/components/ErrorBoundary.js'

const ErrorPage = () => {
  throw new Error('bomb ' + Math.random())
}

const ErrorPageWrapper = () => (
  <ErrorBoundary
    onCatchError={() => {
      /* DO NOTHING ON PURPOSE */
    }}
  >
    <ErrorPage />
  </ErrorBoundary>
)

export default ErrorPageWrapper
