/* Copyright 2013 - 2024 Waiterio LLC */
import put from '@waiterio/api-client/put.js'

export default function updateAvatarOfUser(userId, file) {
  return put({ url: 'users/' + userId + '/avatar', body: [file] }).catch(
    error => {
      throw new Error(`Couldn't update avatar of user\n${error}`)
    },
  )
}
