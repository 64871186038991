/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { PureComponent } from 'react'
import { jsx } from '@emotion/react'
import { connect } from '@monorepo/context/index.js'
import updateCardRest from '@waiterio/api-client/updateCard.js'
import getBrowserHistory from '../getBrowserHistory.js'
import getCurrentRestaurantId from '../session/getCurrentRestaurantId.js'
import { toast } from '../services/Toaster.js'

import CardForm from '../components/CardForm.js'
import ErrorBoundaryGoBack from '../components/ErrorBoundaryGoBack.js'
import ErrorDialog from '../components/ErrorDialog.js'
import Header from '../components/Header.js'
import LoadingDialog from '../components/LoadingDialog.js'

import getStripeTokenUS from '../services/getStripeTokenUS.js'

import translate from '../translate/index.js'

class UpdateCardPage extends PureComponent {
  componentWillUnmount() {
    this.reset()
  }

  hideErrorDialog = () => {
    this.props.produce(draft => {
      delete draft.errorDialog
    })
  }

  hideSendingDialog = () => {
    this.props.produce(draft => {
      delete draft.sendingDialog
    })
  }

  navigateBack = () => {
    getBrowserHistory().push('/subscription')
  }

  reset = () => {
    this.props.produce()
  }

  showErrorDialog = error => {
    this.props.produce(draft => {
      delete draft.sendingDialog
      draft.errorDialog = error ? error.toString() : null
    })
  }

  showSendingDialog = () => {
    this.props.produce(draft => {
      draft.sendingDialog = true
    })
  }

  updateCard = card => {
    this.showSendingDialog()

    return getStripeTokenUS(card)
      .then(cardToken => updateCardRest(this.props.restaurantId, cardToken))
      .then(() => {
        this.hideSendingDialog()
        toast('Card updated successfully')
        this.navigateBack()
      })
      .catch(error => {
        this.hideSendingDialog()
        this.showErrorDialog(error)
      })
  }

  render() {
    const { errorDialog, sendingDialog } = this.props

    return (
      <>
        <Header title={translate('Update card')} href="/subscription" />

        <div css={{ padding: 16 }}>
          <CardForm submitLabel="Update card" onSubmit={this.updateCard} />
        </div>

        {sendingDialog && (
          <LoadingDialog
            title={translate('Updating card')}
            message={translate('in progress')}
          />
        )}
        {errorDialog && (
          <ErrorDialog message={errorDialog} close={this.hideErrorDialog} />
        )}
      </>
    )
  }
}

const UpdateCardPageConnected = connect((context, ownProps, produce) => {
  const searchParams = new URLSearchParams(window.location.search)

  let restaurantId =
    getCurrentRestaurantId() || searchParams.get('restaurantId')

  if (!restaurantId) {
    getBrowserHistory().push('/logout?redirect=%2Fupdate-card')
  }

  let props = {
    ...context.pages.updateCard,
    restaurantId,
    produce: produce(['pages', 'updateCard']),
  }

  // console.log(props);

  return props
})(UpdateCardPage)

const UpdateCardPageWrapper = props => (
  <ErrorBoundaryGoBack>
    <UpdateCardPageConnected {...props} />
  </ErrorBoundaryGoBack>
)

export default UpdateCardPageWrapper
