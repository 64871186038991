/* Copyright 2013 - 2024 Waiterio LLC */
import { produce } from '@monorepo/context/index.js'
import compareSemanticVersions from '../device/compareSemanticVersions.js'
import fetchVersion from '../fetch/fetchVersion.js'
import oldVersion from '../version.js'

export function updatesVersionAvailable() {
  return fetchVersion()
    .then(version => {
      let versionUpdateAvailable = false
      if (
        version &&
        version !== oldVersion &&
        compareSemanticVersions(version, oldVersion) > 0
      ) {
        versionUpdateAvailable = version
        produce(draft => {
          draft.versionUpdateAvailable = versionUpdateAvailable
        })
      }
      return versionUpdateAvailable
    })
    .catch(
      /* istanbul ignore next */
      (/* error */) => {
        // DO NOTHING ON PURPOSE
      },
    )
}

export default function listenForUpdates() {
  setInterval(updatesVersionAvailable, 60000)
  updatesVersionAvailable()
}
