/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'

function Account(source) {
  Object.keys(source || {}).forEach(key => {
    this[key] = source[key]
  })

  if (!this.creationTime) {
    this.creationTime = 0
  }

  if (!this.lastEditTime) {
    this.lastEditTime = this.creationTime || 0
  }

  if (!this.type) {
    this.type = Account.Type.STRIPE_ACCOUNT
  }

  if (!this.legalEntity) {
    this.legalEntity = Account.LegalEntity.COMPANY
  }

  return Immutable(this, { prototype: Account.prototype })
}

Account.prototype.constructor = Account

Account.Type = {}
Account.Type.STRIPE_ACCOUNT = 'STRIPE_ACCOUNT'

Account.LegalEntity = {}
Account.LegalEntity.INDIVIDUAL = 'INDIVIDUAL'
Account.LegalEntity.COMPANY = 'COMPANY'

export default Account
