/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { css, Global } from '@emotion/react'
import defaults from '@waiterio/styles/defaults.js'
import generateTheme from '@waiterio/styles/theme/generateTheme.js'

const GlobalStyles = ({ colorPrimary, colorSecondary }) => {
  let theme = generateTheme({ colorPrimary, colorSecondary })

  const themeCssVariables = Object.keys(theme).reduce((string, themeKey) => {
    const themeValue = theme[themeKey]

    string += `${themeKey}: ${themeValue};`

    return string
  }, '')

  const styles = css`
    :root {
      ${themeCssVariables}
    }

    ${defaults}
  `

  return <Global styles={styles} />
}

export default GlobalStyles
