/* Copyright 2013 - 2024 Waiterio LLC */
import addCategoryRest from '@waiterio/api-client/addCategory.js'
import addInviteRest from '@waiterio/api-client/addInvite.js'
import addItemRest from '@waiterio/api-client/addItem.js'
import addMealRest from '@waiterio/api-client/addMeal.js'
import addPrinterRest from '@waiterio/api-client/addPrinter.js'
import addPaymentRest from '@waiterio/api-client/addPayment.js'
import addRoleRest from '@waiterio/api-client/addRole.js'
import addSubscriptionRest from '@waiterio/api-client/addSubscription.js'
import addTerminalRest from '@waiterio/api-client/addTerminal.js'
import cancelSubscriptionRest from '@waiterio/api-client/cancelSubscription.js'
import deleteAvatarOfUserRest from '@waiterio/api-client/deleteAvatarOfUser.js'
import deleteCategoryRest from '@waiterio/api-client/deleteCategory.js'
import deleteCoverOfRestaurantRest from '@waiterio/api-client/deleteCoverOfRestaurant.js'
import deleteInviteRest from '@waiterio/api-client/deleteInvite.js'
import deleteItemRest from '@waiterio/api-client/deleteItem.js'
import deleteMealRest from '@waiterio/api-client/deleteMeal.js'
import deletePaymentRest from '@waiterio/api-client/deletePayment.js'
import deletePrinterRest from '@waiterio/api-client/deletePrinter.js'
import deleteRoleRest from '@waiterio/api-client/deleteRole.js'
import deleteTerminalRest from '@waiterio/api-client/deleteTerminal.js'
import updateAccountRest from '@waiterio/api-client/updateAccount.js'
import updateAvatarOfUserRest from '@waiterio/api-client/updateAvatarOfUser.js'
import updateCategoryRest from '@waiterio/api-client/updateCategory.js'
import updateCoverOfRestaurantRest from '@waiterio/api-client/updateCoverOfRestaurant.js'
import updateMapRest from '@waiterio/api-client/updateMap.js'
import updateMealRest from '@waiterio/api-client/updateMeal.js'
import updateMenuRest from '@waiterio/api-client/updateMenu.js'
import updateMenuWithFileRest from '@waiterio/api-client/updateMenuWithFile.js'
import updatePaymentRest from '@waiterio/api-client/updatePayment.js'
import updatePrinterRest from '@waiterio/api-client/updatePrinter.js'
import updateRestaurantRest from '@waiterio/api-client/updateRestaurant.js'
import updateSubscriptionRest from '@waiterio/api-client/updateSubscription.js'
import updateTerminalRest from '@waiterio/api-client/updateTerminal.js'
import updateUserRest from '@waiterio/api-client/updateUser.js'
import getCurrentRestaurantId from '../session/getCurrentRestaurantId.js'
import getCurrentUserId from '../session/getCurrentUserId.js'
import getDefaultMenuId from '../session/getDefaultMenuId.js'

import { plantAccount } from '../forage/AccountsForage.js'
import { plantInvite, burnInvite } from '../forage/InvitesForage.js'
import { plantMap } from '../forage/MapsForage.js'
import { plantMeal, burnMeal } from '../forage/MealsForage.js'
import {
  burnCategory,
  burnItem,
  pickItem,
  pickCategory,
  plantItem,
  plantCategory,
  plantMenu,
} from '../forage/MenusForage.js'
import { plantPayment, burnPayment } from '../forage/PaymentsForage.js'
import { plantPrinter, burnPrinter } from '../forage/PrintersForage.js'
import { plantRestaurant } from '../forage/RestaurantsForage.js'
import { plantRole, burnRole } from '../forage/RolesForage.js'
import { plantSubscription } from '../forage/SubscriptionsForage.js'
import { plantTerminal, burnTerminal } from '../forage/TerminalsForage.js'
import { plantUser } from '../forage/UsersForage.js'

export const updateAccount = async account => {
  account.set('lastEditTime', new Date().getTime())
  const accountUpdatedEvent = await updateAccountRest(
    getCurrentRestaurantId(),
    account._id,
    account,
  )
  return plantAccount(accountUpdatedEvent.account)
}

export const updateItem = (categoryId, newItem, oldItem) => {
  newItem = newItem.set('lastEditTime', new Date().getTime())
  return plantItem(getDefaultMenuId(), categoryId, newItem).then(newItem => {
    if (newItem) {
      return addItemRest(
        getCurrentRestaurantId(),
        getDefaultMenuId(),
        categoryId,
        newItem,
      ).catch(error => {
        plantItem(getDefaultMenuId(), categoryId, oldItem)

        throw error
      })
    }
  })
}

export const updateCategory = (newCategory, oldCategory) => {
  newCategory = newCategory.set('lastEditTime', new Date().getTime())
  return plantCategory(getDefaultMenuId(), newCategory).then(newCategory => {
    if (newCategory) {
      return updateCategoryRest(
        getCurrentRestaurantId(),
        getDefaultMenuId(),
        newCategory,
      ).catch(error => {
        plantCategory(getDefaultMenuId(), oldCategory)

        throw error
      })
    }
  })
}

export const addAccount = () =>
  // TODO
  Promise.resolve()

export const addCategory = category =>
  plantCategory(getDefaultMenuId(), category).then(category =>
    addCategoryRest(
      getCurrentRestaurantId(),
      getDefaultMenuId(),
      category,
    ).catch(error => {
      burnCategory(getDefaultMenuId(), category._id)

      throw error
    }),
  )

export const addInvite = invite =>
  plantInvite(invite).then(invite =>
    addInviteRest(invite)
      .then(inviteAddedEvent => {
        if (
          inviteAddedEvent &&
          inviteAddedEvent.invite &&
          inviteAddedEvent.invite.userId
        ) {
          burnInvite(invite._id)
        }

        return inviteAddedEvent
      })
      .catch(error => {
        burnInvite(invite._id)

        throw error
      }),
  )

export const addItem = (categoryId, item) =>
  plantItem(getDefaultMenuId(), categoryId, item).then(item =>
    addItemRest(
      getCurrentRestaurantId(),
      getDefaultMenuId(),
      categoryId,
      item,
    ).catch(error => {
      burnItem(getDefaultMenuId(), categoryId, item._id)

      throw error
    }),
  )

export const addExtraToItem = (categoryId, itemId, newExtra) =>
  pickItem(getDefaultMenuId(), categoryId, itemId).then(oldItem => {
    if (oldItem) {
      const newItem = oldItem.addOrUpdateExtra(newExtra)

      return updateItem(categoryId, newItem, oldItem)
    }
  })

export const addExtraToCategory = (categoryId, newExtra) =>
  pickCategory(getDefaultMenuId(), categoryId).then(oldCategory => {
    if (oldCategory) {
      const newCategory = oldCategory.addOrUpdateExtra(newExtra)

      return updateCategory(newCategory, oldCategory)
    }
  })

export const addMeal = meal =>
  plantMeal(meal).then(meal =>
    addMealRest(getCurrentRestaurantId(), meal).catch(error => {
      burnMeal(meal._id)

      throw error
    }),
  )

export const updateMenuWithFile = file =>
  updateMenuWithFileRest(getDefaultMenuId(), file)
    .then(menu => plantMenu(menu))
    .catch(error => {
      throw error
    })

export const addPayment = payment =>
  plantPayment(payment).then(payment =>
    addPaymentRest(getCurrentRestaurantId(), payment).catch(error => {
      burnPayment(payment._id)

      throw error
    }),
  )

export const addPrinter = printer =>
  plantPrinter(printer).then(printer =>
    addPrinterRest(getCurrentRestaurantId(), printer).catch(error => {
      burnPrinter(printer._id)

      throw error
    }),
  )

export const addRole = role =>
  plantRole(role).then(role =>
    addRoleRest(role).catch(error => {
      burnRole(role._id)

      throw error
    }),
  )

export const addSubscription = (cardToken, subscription) =>
  addSubscriptionRest(getCurrentRestaurantId(), cardToken, subscription).then(
    subscriptionAddedEvent =>
      plantSubscription(subscriptionAddedEvent.subscription).then(
        subscription => subscription,
      ),
  )

export const addTerminal = printer =>
  plantTerminal(printer).then(printer =>
    addTerminalRest(getCurrentRestaurantId(), printer).catch(error => {
      burnTerminal(printer._id)

      throw error
    }),
  )

export const deleteAvatarOfUser = () =>
  deleteAvatarOfUserRest(getCurrentUserId())
    .then(user => plantUser(user))
    .catch(error => {
      throw error
    })

export const deleteCategory = category =>
  burnCategory(getDefaultMenuId(), category.id).then(_ =>
    deleteCategoryRest(
      getCurrentRestaurantId(),
      getDefaultMenuId(),
      category.id,
    ).catch(error => {
      plantCategory(getDefaultMenuId(), category)

      throw error
    }),
  )

export const deleteCoverOfRestaurant = () =>
  deleteCoverOfRestaurantRest(getCurrentRestaurantId())
    .then(restaurant => plantRestaurant(restaurant))
    .catch(error => {
      throw error
    })

export const deleteInvite = invite =>
  burnInvite(invite._id).then(_ =>
    deleteInviteRest(invite._id).catch(error => {
      plantInvite(invite)

      throw error
    }),
  )

export const deleteItem = (categoryId, item) =>
  burnItem(getDefaultMenuId(), categoryId, item.id).then(_ =>
    deleteItemRest(
      getCurrentRestaurantId(),
      getDefaultMenuId(),
      categoryId,
      item.id,
    ).catch(itemDeletedFailureEvent => {
      plantItem(getDefaultMenuId(), categoryId, item)

      throw itemDeletedFailureEvent
    }),
  )

export const deleteExtraOfItem = (categoryId, itemId, extra) =>
  pickItem(getDefaultMenuId(), categoryId, itemId).then(oldItem => {
    if (oldItem) {
      const newItem = oldItem.removeExtra(extra.id)

      return updateItem(categoryId, newItem, oldItem)
    }
  })

export const deleteExtraOfCategory = (categoryId, extra) =>
  pickCategory(getDefaultMenuId(), categoryId).then(oldCategory => {
    if (oldCategory) {
      const newCategory = oldCategory.removeExtra(extra.id)

      return updateCategory(newCategory, oldCategory)
    }
  })

export const deleteMeal = meal =>
  burnMeal(meal._id).then(_ =>
    deleteMealRest(getCurrentRestaurantId(), meal._id).catch(error => {
      plantMeal(meal)

      throw error
    }),
  )

export const deletePayment = payment =>
  burnPayment(payment._id).then(_ =>
    deletePaymentRest(getCurrentRestaurantId(), payment._id).catch(error => {
      plantPayment(payment)

      throw error
    }),
  )

export const deletePrinter = printer =>
  burnPrinter(printer._id).then(_ =>
    deletePrinterRest(getCurrentRestaurantId(), printer._id).catch(error => {
      plantPrinter(printer)

      throw error
    }),
  )

export const deleteRole = role =>
  burnRole(role._id).then(_ =>
    deleteRoleRest(role._id).catch(error => {
      plantRole(role)

      throw error
    }),
  )

export const deleteTerminal = terminal =>
  burnTerminal(terminal._id).then(_ =>
    deleteTerminalRest(getCurrentRestaurantId(), terminal._id).catch(error => {
      plantTerminal(terminal)

      throw error
    }),
  )

export const updateAvatarOfUser = photo =>
  updateAvatarOfUserRest(getCurrentUserId(), photo).then(user =>
    plantUser(user),
  )

export const updateCoverOfRestaurant = photo =>
  updateCoverOfRestaurantRest(getCurrentRestaurantId(), photo).then(
    restaurant => plantRestaurant(restaurant),
  )

export const updateExtraOfItem = (categoryId, itemId, newExtra) => {
  newExtra = newExtra.set('lastEditTime', new Date().getTime())
  return pickItem(getDefaultMenuId(), categoryId, itemId).then(oldItem => {
    if (oldItem) {
      const newItem = oldItem.addOrUpdateExtra(newExtra)

      return updateItem(categoryId, newItem, oldItem)
    }
  })
}

export const updateExtraOfCategory = (categoryId, newExtra) => {
  newExtra = newExtra.set('lastEditTime', new Date().getTime())
  return pickCategory(getDefaultMenuId(), categoryId).then(oldCategory => {
    if (oldCategory) {
      const newCategory = oldCategory.addOrUpdateExtra(newExtra)

      return updateCategory(newCategory, oldCategory)
    }
  })
}

export const updateMeal = (newMeal, oldMeal) => {
  newMeal = newMeal.set('lastEditTime', new Date().getTime())
  return plantMeal(newMeal).then(newMeal => {
    if (newMeal) {
      return updateMealRest(getCurrentRestaurantId(), newMeal).catch(error => {
        plantMeal(oldMeal)

        throw error
      })
    }
  })
}

export const updateMap = (newMap, oldMap) => {
  newMap = newMap.set('lastEditTime', new Date().getTime())
  return plantMap(newMap).then(newMap => {
    if (newMap) {
      return updateMapRest(getCurrentRestaurantId(), newMap).catch(error => {
        plantMap(oldMap)

        throw error
      })
    }
  })
}

export const updateMenu = (newMenu, oldMenu) => {
  newMenu = newMenu.set('lastEditTime', new Date().getTime())
  return plantMenu(newMenu).then(newMenu => {
    if (newMenu) {
      return updateMenuRest(getCurrentRestaurantId(), newMenu).catch(error => {
        plantMenu(oldMenu)

        throw error
      })
    }
  })
}

export const updatePayment = (newPayment, oldPayment) => {
  newPayment = newPayment.set('lastEditTime', new Date().getTime())
  return plantPayment(newPayment).then(newPayment => {
    if (newPayment) {
      return updatePaymentRest(getCurrentRestaurantId(), newPayment).catch(
        error => {
          plantPayment(oldPayment)

          throw error
        },
      )
    }
  })
}

export const updatePrinter = (newPrinter, oldPrinter) => {
  newPrinter = newPrinter.set('lastEditTime', new Date().getTime())
  return plantPrinter(newPrinter).then(newPrinter => {
    if (newPrinter) {
      return updatePrinterRest(getCurrentRestaurantId(), newPrinter).catch(
        error => {
          plantPrinter(oldPrinter)

          throw error
        },
      )
    }
  })
}

export const updateRestaurant = (newRestaurant, oldRestaurant) => {
  newRestaurant = newRestaurant.set('lastEditTime', new Date().getTime())
  return plantRestaurant(newRestaurant).then(newRestaurant => {
    if (newRestaurant) {
      return updateRestaurantRest(newRestaurant).catch(error => {
        plantRestaurant(oldRestaurant)

        throw error
      })
    }
  })
}

export const updateTerminal = (newTerminal, oldTerminal) => {
  newTerminal = newTerminal.set('lastEditTime', new Date().getTime())
  return plantTerminal(newTerminal).then(newTerminal => {
    if (newTerminal) {
      return updateTerminalRest(getCurrentRestaurantId(), newTerminal).catch(
        error => {
          plantTerminal(oldTerminal)

          throw error
        },
      )
    }
  })
}

export const cancelSubscription = async subscription => {
  subscription.lastEditTime = new Date().getTime()
  const subscriptionUpdatedEvent = await cancelSubscriptionRest(subscription)
  return plantSubscription(subscriptionUpdatedEvent.subscription)
}

export const updateSubscription = async (newPlanId, subscription) => {
  subscription.lastEditTime = new Date().getTime()
  const subscriptionUpdatedEvent = await updateSubscriptionRest(
    getCurrentRestaurantId(),
    subscription._id,
    newPlanId,
    subscription,
  )
  return plantSubscription(subscriptionUpdatedEvent.subscription)
}

export const updateUser = async (newUser, oldUser) => {
  newUser = newUser.set('lastEditTime', new Date().getTime())
  newUser = await plantUser(newUser)

  return (
    newUser &&
    updateUserRest(newUser).catch(error => {
      plantUser(oldUser)

      throw error
    })
  )
}
