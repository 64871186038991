/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import Room from './Room.js'
import UUID from './UUID.js'

function Map_(source) {
  if (source instanceof Map_) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = UUID.generate()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().getTime()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (!this.rooms) {
      this.rooms = []
    }

    if (this.rooms) {
      for (let i = 0; i < this.rooms.length; i += 1) {
        this.rooms[i] = new Room(this.rooms[i])
      }
    }

    return Immutable(this, { prototype: Map_.prototype })
  }
}

Map_.prototype.constructor = Map_

Map_.prototype.addOrUpdateRoom = function addOrUpdateRoom(room) {
  let map_ = this

  if (!room.id) {
    map_ = map_.setIn(
      ['rooms', map_.rooms.length],
      new Room({
        ...room,
        mapId: map_.id,
        restaurantId: map_.restaurantId,
      }),
    )
  } else {
    const index = map_.getRoomIndex(room.id)

    if (index || index === 0) {
      map_ = map_.setIn(['rooms', index], room)
    } else {
      map_ = map_.setIn(['rooms', map_.rooms.length], room)
    }
  }

  return map_
}

Map_.prototype.addOrUpdateTable = function addOrUpdateTable(roomId, table) {
  let map_ = this

  let room = map_.getRoom(roomId)

  if (room) {
    room = room.addOrUpdateTable(table)
    map_ = map_.addOrUpdateRoom(room)
  }

  return map_
}

Map_.prototype.getRoomIndex = function getRoomIndex(roomId) {
  let index = null
  let found = false
  let currentIndex = 0

  while (currentIndex < this.rooms.length && !found) {
    if (this.rooms[currentIndex].id === roomId) {
      found = true
      index = currentIndex
    }

    currentIndex += 1
  }

  return index
}

Map_.prototype.getRoom = function getRoom(roomId) {
  let room = null

  if (this.rooms) {
    let i = 0

    while (i < this.rooms.length && !room) {
      if (this.rooms[i].id === roomId) {
        room = this.rooms[i]
      }

      i += 1
    }
  }

  return room
}

Map_.prototype.getAllTablesJoiningRooms = function getAllTablesJoiningRooms() {
  const tables = []

  for (let roomIndex = 0; roomIndex < this.rooms.length; roomIndex += 1) {
    const room = this.rooms[roomIndex]

    for (let tableIndex = 0; tableIndex < room.tables.length; tableIndex += 1) {
      tables.push(room.tables[tableIndex])
    }
  }

  return tables
}

Map_.prototype.getTable = function getTable(roomId, tableId) {
  const room = this.getRoom(roomId)

  return room ? room.getTable(tableId) : null
}

Map_.prototype.removeRoom = function removeRoom(room) {
  return this.removeRoomById(room.id)
}

Map_.prototype.removeRoomById = function removeRoomById(roomId) {
  return this.set(
    'rooms',
    this.rooms.filter(room => room.id !== roomId),
  )
}

Map_.prototype.removeTable = function removeTable(roomId, tableId) {
  let map_ = this

  let room = this.getRoom(roomId)

  if (room) {
    room = room.removeTable(tableId)
    map_ = map_.addOrUpdateRoom(room)
  }

  return map_
}

export default Map_
