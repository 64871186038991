/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { connect } from '@monorepo/context/index.js'
import card from '@waiterio/styles/card.js'
import clickable from '@waiterio/styles/clickable.js'
import singleLine from '@waiterio/styles/singleLine.js'
import getBrowserHistory from '../getBrowserHistory.js'
import translate from '../translate/index.js'

const noInternetConnection = css`
  ${card};
  ${clickable};
  ${singleLine};
  line-height: 35px;
  height: 35px;
  min-height: 35px;
  text-align: center;
  color: white;
  background: #626262;
  padding: 0 16px;
`

const NoInternetConnection = ({ online }) => {
  if (!online) {
    return (
      <div
        onClick={_ =>
          getBrowserHistory().push('/documentation/can-i-use-it-offline')
        }
        css={noInternetConnection}
      >
        {translate('No Internet connection')}
      </div>
    )
  } else {
    return null
  }
}

export default connect((context, props) => ({
  online: context.connection.online,
}))(NoInternetConnection)
