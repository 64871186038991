/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { PureComponent } from 'react'
import { css, jsx } from '@emotion/react'
import IconChat from '@stiloso/icons/IconChat.js'
import { connect } from '@monorepo/context/index.js'
import getDocuments from '@globo/client/getDocuments.js'
import globoWebsiteId from '@waiterio/shared/globoWebsiteId.js'
import card from '@waiterio/styles/card.js'
import getBrowserHistory from '../getBrowserHistory.js'
import { languageSelector } from '../selectors/AppSelectors.js'
import ErrorBoundaryGoBack from '../components/ErrorBoundaryGoBack.js'
import Header from '../components/Header.js'
import floatingActionButton from '../styles/floatingActionButton.js'
import loader from '../styles/loader.js'

const paper = css`
  ${card};
  max-width: 640px;
  margin: 0 auto;
  padding: 16px;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 21.6px;
  line-height: 1.2;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
`

const style = `

    h2, h3, p, li, ul, i, b {
        font-size: 21.6px;
        line-height: 1.2;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
    }

    h1 {
        font-size: 25.2px;
        line-height: 1.2;
        padding: 16px 0px;
    }

    @media (max-width: 768px) {

        .paper {
            padding: 16px;
            font-size: 24px;
        }

        h1, h2, h3, p, li, ul, i, b, a {
            font-size: 24px;
        }

    }

    a {
        color: var(--color-primary) !important;
        text-decoration: underline !important;
        cursor: pointer;
    }

    ol , ul {
        padding-left:0px;
    }

    ol {
        counter-reset:item
    }

    ol li {
        counter-increment:item;
        list-style:none inside;
        list-style-position: inside;
        text-indent: -35px;
        padding-left: 49.5px;
        padding-bottom: 16px;
    }

    ol li:before {
        content:counter(item) ")";
        padding-right: 16px;
    }

    ul li{
        counter-increment:item;
        list-style-position: inside;
        padding-left: 16px;
        padding-bottom: 16px;
    }

    .videoWrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
    }
    .videoWrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .card :first-child { margin-top: 0 !important; margin-left: 0 !important; }
    .card :last-child { margin-bottom: 0 !important; margin-right: 0 !important; }

`

class DocumentPage extends PureComponent {
  componentDidMount() {
    this.fetchMissingDocument()
  }

  componentDidUpdate() {
    this.fetchMissingDocument()
  }

  componentWillUnmount() {
    this.reset()
  }

  fetchMissingDocument = async () => {
    const { originalDocumentId, language, size, document, fetchingDocument } =
      this.props

    if (language && !document && !fetchingDocument) {
      this.props.produce(draft => {
        draft.fetchingDocument = true
      })

      let documents = await getDocuments({
        originalDocumentId,
        locale: language,
        websiteId: globoWebsiteId,
      })

      if (documents?.length === 0) {
        documents = await getDocuments({
          originalDocumentId,
          locale: 'en',
          websiteId: globoWebsiteId,
        })
      }

      let document = documents?.length ? documents[0] : null

      while (document.content.indexOf('{slug}') > -1) {
        document.content = document.content.replace(
          '{slug}',
          'get-started-tutorial-admin',
        )
      }

      while (document.content.indexOf('{size}') > -1) {
        document.content = document.content.replace('{size}', size)
      }

      while (document.content.indexOf('{locale}') > -1) {
        document.content = document.content.replace('{locale}', language)
      }

      this.props.produce(draft => {
        draft.fetchingDocument = false
        draft.document = document
      })
    }
  }

  reset = () => {
    this.props.produce()
  }

  render() {
    const { document, slug } = this.props

    return (
      <>
        <style>{style}</style>
        <Header title={(document && document.title) || slug} />
        {document ? (
          <div css={{ padding: 16 }}>
            <div css={paper}>
              <h1>{(document && document.title) || slug}</h1>
              <div dangerouslySetInnerHTML={{ __html: document.content }} />
            </div>
          </div>
        ) : (
          <div css={loader} />
        )}
        <div
          onClick={() => {
            if (window.$globo) {
              window.$globo.push(['showChat'])
              window.$globo.push(['openChat'])
            } else {
              window.open('mailto:info@waiterio.com', '_blank')
            }
          }}
          css={[
            floatingActionButton,
            { position: 'fixed', right: 16, bottom: 16 },
          ]}
        >
          <IconChat
            width={60}
            height={60}
            css={{ padding: 18, fill: 'white' }}
          />
        </div>
      </>
    )
  }
}

const DocumentPageConnected = connect((context, props, produce) => {
  const { document, fetchingDocument } = context.pages.document
  const { originalDocumentId } = props
  const { isSmartphoneSize, isDesktopSize } = context.display
  const language = languageSelector(context)

  let size = 'tablet'

  if (isSmartphoneSize) {
    size = 'smartphone'
  } else if (isDesktopSize) {
    size = 'computer'
  }

  return {
    isSmartphoneSize,
    originalDocumentId,
    document,
    fetchingDocument,
    size,
    language,
    produce: produce(['pages', 'document']),
  }
})(DocumentPage)

const DocumentPageWrapper = props => (
  <ErrorBoundaryGoBack>
    <DocumentPageConnected {...props} />
  </ErrorBoundaryGoBack>
)

export default DocumentPageWrapper
