/* Copyright 2013 - 2024 Waiterio LLC */
import Device from '@waiterio/model/Device.js'
import UUID from '@waiterio/model/UUID.js'
import android from '../device/android.js'
import browser from '../device/browser.js'
import chromeos from '../device/chromeos.js'
import ios from '../device/ios.js'
import ipad from '../device/ipad.js'
import linux from '../device/linux.js'
import macos from '../device/macos.js'
import windows from '../device/windows.js'
import getDisplay from './getDisplay.js'

export default function getDeviceForLoginRequest() {
  const device = new Device({
    _id: UUID.generate(),
    creationTime: new Date().getTime(),
    lastEditTime: new Date().getTime(),
  })

  let display = getDisplay()

  if (browser) {
    device.browser = true
  }

  if (android) {
    device.os = Device.OS.ANDROID

    if (display.xs) {
      device.size = Device.Size.SMARTPHONE
      device.type = Device.Type.ANDROID_SMARTPHONE
    } else {
      device.size = Device.Size.TABLET
      device.type = Device.Type.ANDROID_TABLET
    }
  } else if (ios) {
    device.os = Device.OS.IOS

    if (ipad) {
      device.size = Device.Size.TABLET
      device.type = Device.Type.IPAD
    } else {
      device.size = Device.Size.SMARTPHONE
      device.type = Device.Type.IPHONE
    }
  } else if (macos) {
    device.os = Device.OS.MACOS
    device.size = Device.Size.DESKTOP
  } else if (windows) {
    device.os = Device.OS.WINDOWS
    device.size = Device.Size.DESKTOP
  } else if (linux) {
    device.os = Device.OS.LINUX
    device.size = Device.Size.DESKTOP
  } else if (chromeos) {
    device.os = Device.OS.CHROME_OS
    device.size = Device.Size.DESKTOP
  }

  return device
}
