/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconApple = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-apple'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M18.648 16.272q-0.504 1.68-1.632 3.336-1.728 2.64-3.456 2.64-0.648 0-1.872-0.432-1.152-0.432-2.016-0.432-0.816 0-1.896 0.456t-1.776 0.456q-2.040 0-4.032-3.48-1.968-3.504-1.968-6.744 0-3.048 1.512-4.992t3.816-1.944q0.96 0 2.352 0.408t1.848 0.408q0.6 0 1.92-0.456 1.368-0.456 2.328-0.456 1.584 0 2.856 0.864 0.696 0.48 1.392 1.344-1.056 0.888-1.536 1.584-0.864 1.248-0.864 2.76 0 1.656 0.912 3t2.112 1.68zM13.632 0.552q0 0.816-0.408 1.824-0.384 1.008-1.248 1.848-0.72 0.72-1.44 0.96-0.48 0.168-1.392 0.24 0.048-1.992 1.056-3.432 0.984-1.44 3.336-1.992 0.024 0.048 0.048 0.144t0.024 0.144 0 0.144 0.024 0.12z" />
    </svg>
  )
}

export default IconApple
