/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import Plan from '@waiterio/model/Plan.js'

const prefix = 'waiterio.plans.'

export function plantPlan(plan) {
  return localForage.setItem(prefix + plan._id, plan).then(plan => {
    plan = new Plan(plan)

    produce(draft => {
      draft.data.plans[plan._id] = plan
    })

    return plan
  })
}

export function burnPlan(planId) {
  return localForage.removeItem(prefix + planId).then(() => {
    produce(draft => {
      delete draft.data.plans[planId]
    })

    return planId
  })
}

export function burnPlans() {
  return localForage.keys().then(keyss =>
    Promise.all(
      keyss
        .filter(key => key.indexOf(prefix) > -1)
        .map(key =>
          localForage.removeItem(key).then((/* plan */) => {
            let planId = key.replace(prefix, '')

            if (planId) {
              produce(draft => {
                delete draft.data.plans[planId]
              })
            }

            return true
          }),
        ),
    ),
  )
}

export function pickPlan(planId) {
  return localForage
    .getItem(prefix + planId)
    .then(plan => (plan ? new Plan(plan) : null))
}

export function pickPlans() {
  return localForage
    .keys()
    .then(keyss =>
      Promise.all(
        keyss
          .filter(key => key.indexOf(prefix) > -1)
          .map(key => localForage.getItem(key).then(plan => new Plan(plan))),
      ),
    )
}
