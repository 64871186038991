/* Copyright 2013 - 2024 Waiterio LLC */
import { createSelector } from 'reselect'
import values from 'ramda/src/values.js'
import addDays from 'date-fns/add_days/index.js'
import addHours from 'date-fns/add_hours/index.js'
import getTime from 'date-fns/get_time/index.js'
import startOfDay from 'date-fns/start_of_day/index.js'
import { TABLE, TAKEAWAY, DELIVERY } from '@waiterio/model/Meal.js'
import Plan from '@waiterio/model/Plan.js'
import Role from '@waiterio/model/Role.js'
import Subscription from '@waiterio/model/Subscription.js'
import getTapToPayCapability from '../native/getTapToPayCapability.js'

const rolesIncludesRolesTypes = (roles, rolesTypes) =>
  roles.some(role => rolesTypes.indexOf(role.type) > -1)

const device = context => context.device
const display = context => context.display
const browserLanguage = context => context.language

const accounts = context => context.data.accounts
const invites = context => context.data.invites
const maps = context => context.data.maps
const meals = context => context.data.meals
const menus = context => context.data.menus
const payments = context => context.data.payments
const plans = context => context.data.plans
const printers = context => context.data.printers
const restaurants = context => context.data.restaurants
const roles = context => context.data.roles
const subscriptions = context => context.data.subscriptions
const terminals = context => context.data.terminals
const users = context => context.data.users

export const currentRestaurantId = context =>
  context.session.currentRestaurantId
export const currentUserId = context => context.session.currentUserId

export const defaultMenuId = context => context.session.defaultMenuId

export const currentRestaurantSelector = createSelector(
  [currentRestaurantId, restaurants],
  (currentRestaurantId, restaurants) => restaurants[currentRestaurantId],
)

export const currentRolesSelector = createSelector(
  [currentRestaurantId, currentUserId, roles],
  (currentRestaurantId, currentUserId, roles) =>
    values(roles).filter(
      role =>
        role.restaurantId === currentRestaurantId &&
        role.userId === currentUserId,
    ),
)

export const currentSubscriptionSelector = createSelector(
  subscriptions,
  subscriptions => {
    subscriptions = values(subscriptions).filter(
      subscription => Subscription.Status.CANCELED !== subscription.status,
    )

    return subscriptions.length > 0 ? subscriptions[0] : null
  },
)

export const currentUserSelector = createSelector(
  [currentUserId, users],
  (currentUserId, users) => users[currentUserId],
)

export const defaultAccountSelector = createSelector(
  accounts,
  accounts => values(accounts)[0],
)

export const defaultMapSelector = createSelector(maps, maps => values(maps)[0])

export const defaultMenuSelector = createSelector(
  [defaultMenuId, menus],
  (defaultMenuId, menus) => menus[defaultMenuId],
)

export const defaultServiceSelector = createSelector(
  [currentRestaurantSelector],
  restaurant => {
    const {
      deliveryServiceOffered,
      takeawayServiceOffered,
      tableServiceOffered,
    } = restaurant

    return (
      (tableServiceOffered && TABLE) ||
      (takeawayServiceOffered && TAKEAWAY) ||
      (deliveryServiceOffered && DELIVERY) ||
      TABLE
    )
  },
)

export const defaultCurrencyCodeSelector = createSelector(
  defaultMenuSelector,
  menu => menu.currency,
)

export const categoryIdProps = (context, props) => props.categoryId
export const categoryIdAndItemId = (context, categoryIdAndItemId) =>
  categoryIdAndItemId
export const categoryIdAndItemIdAndExtraId = (
  context,
  categoryIdAndItemIdAndExtraId,
) => categoryIdAndItemIdAndExtraId

export const mealIdProps = (context, props) => props.mealId
export const paymentIdProps = (context, props) => props.paymentId
export const planIdProps = (context, props) => props.planId
export const printerIdProps = (context, props) => props.printerId
export const terminalIdProps = (context, props) => props.terminalId
export const userIdProps = (context, props) => props.userId

export const categorySelector = createSelector(
  [defaultMenuSelector, categoryIdProps],
  (menu, categoryId) => menu.getCategory(categoryId),
)

export const extraSelector = createSelector(
  [defaultMenuSelector, categoryIdAndItemIdAndExtraId],
  (menu, categoryIdAndItemIdAndExtraId) =>
    menu.getExtra(
      categoryIdAndItemIdAndExtraId.categoryId,
      categoryIdAndItemIdAndExtraId.itemId,
      categoryIdAndItemIdAndExtraId.extraId,
    ),
)

export const invitesSelector = createSelector([invites], invites =>
  values(invites),
)

export const itemSelector = createSelector(
  [defaultMenuSelector, categoryIdAndItemId],
  (menu, categoryIdAndItemId) =>
    menu.getItem(categoryIdAndItemId.categoryId, categoryIdAndItemId.itemId),
)

export const mealSelector = createSelector(
  [meals, mealIdProps],
  (meals, mealId) => meals[mealId],
)

export const mealsSelector = createSelector([meals], meals => values(meals))

export const activeMealsSelector = createSelector(mealsSelector, meals =>
  meals.filter(meal => {
    let time = Math.max(
      ...[meal.lastEditTime || 0, meal.pickupTime || 0, meal.deliveryTime || 0],
    )

    let now = new Date().getTime()
    let todayAt6am = getTime(addHours(startOfDay(now), 6))
    let yesterdayAt6am = getTime(addHours(startOfDay(addDays(now, -1)), 6))
    let last6am

    if (now > todayAt6am) {
      last6am = todayAt6am
    } else {
      last6am = yesterdayAt6am
    }

    return time > last6am
  }),
)

export const paymentSelector = createSelector(
  [payments, paymentIdProps],
  (payments, paymentId) => payments[paymentId],
)

export const paymentsSelector = createSelector([payments], payments =>
  values(payments),
)

export const paymentsByMealIdSelector = createSelector(
  [payments, mealIdProps],
  (payments, mealId) =>
    values(payments).filter(payment => payment.mealId === mealId),
)

export const planSelector = createSelector(
  [plans, planIdProps],
  (plans, planId) => plans[planId],
)

export const plansSelector = createSelector([plans], plans =>
  values(plans).sort((a, b) => a.amount - b.amount),
)

export const printerSelector = createSelector(
  [printers, printerIdProps],
  (printers, printerId) => printers[printerId],
)

export const printersSelector = createSelector([printers], printers =>
  values(printers),
)

export const rolesSelector = createSelector(
  [currentRestaurantId, roles],
  (currentRestaurantId, roles) =>
    values(roles).filter(role => role.restaurantId === currentRestaurantId),
)

export const rolesByUserIdSelector = createSelector(
  [roles, currentRestaurantId, userIdProps],
  (roles, currentRestaurantId, userId) =>
    values(roles).filter(
      role =>
        role.restaurantId === currentRestaurantId && role.userId === userId,
    ),
)

export const terminalSelector = createSelector(
  [terminals, terminalIdProps],
  (terminals, terminalId) => terminals[terminalId],
)

export const terminalsSelector = createSelector([terminals], terminals =>
  values(terminals),
)

export const userSelector = createSelector(
  [users, userIdProps],
  (users, userId) => users[userId],
)

export const usersSelector = createSelector([users], users => values(users))

export const currentPlanSelector = createSelector(
  [defaultCurrencyCodeSelector, currentSubscriptionSelector, plansSelector],
  (currencyCode, subscription, plans) => {
    let plan

    if (plans && subscription) {
      plan = plans.find(plan => subscription.planId === plan._id)
    }

    return (
      plan ||
      new Plan({
        name: Plan.Name.FREE,
        amount: 0,
        currencyCode,
        maxNumberOfOrders: 100,
      })
    )
  },
)

export const isCurrentUserBarman = createSelector(
  currentRolesSelector,
  currentRolesSelector =>
    rolesIncludesRolesTypes(currentRolesSelector, [Role.BARMAN]),
)

export const isCurrentUserCashier = createSelector(
  currentRolesSelector,
  currentRolesSelector =>
    rolesIncludesRolesTypes(currentRolesSelector, [Role.CASHIER]),
)

export const isCurrentUserCook = createSelector(
  currentRolesSelector,
  currentRolesSelector =>
    rolesIncludesRolesTypes(currentRolesSelector, [Role.COOK]),
)

export const isCurrentUserWaiter = createSelector(
  currentRolesSelector,
  currentRolesSelector =>
    rolesIncludesRolesTypes(currentRolesSelector, [Role.WAITER]),
)

export const isCurrentUserOwnerOrAdmin = createSelector(
  currentRolesSelector,
  currentRolesSelector =>
    rolesIncludesRolesTypes(currentRolesSelector, [Role.OWNER, Role.ADMIN]),
)

export const isCurrentUserOwnerOrAdminOrManager = createSelector(
  currentRolesSelector,
  currentRolesSelector =>
    rolesIncludesRolesTypes(currentRolesSelector, [
      Role.OWNER,
      Role.ADMIN,
      Role.MANAGER,
    ]),
)

export const isCurrentUserNew = createSelector(
  currentUserSelector,
  currentUser =>
    (currentUser?.creationTime ?? 0) >
    new Date().getTime() - 24 * 60 * 60 * 1000,
)

export const isCurrentUserAuthorizedToEditStaff = isCurrentUserOwnerOrAdmin

export const isCurrentUserAuthorizedToEditMenuOrMap =
  isCurrentUserOwnerOrAdminOrManager

export const isTapToPayCapableSelector = context => {
  let { isTapToPayCapable } = context

  if (isTapToPayCapable === undefined || isTapToPayCapable === null) {
    getTapToPayCapability()
  }

  return !!isTapToPayCapable
}

export const deviceSelector = createSelector(device, device => device)

export const displaySelector = createSelector(display, display => display)

export const languageSelector = createSelector(
  [currentUserSelector, browserLanguage],
  (user, browserLanguage) =>
    user && user.language ? user.language : browserLanguage,
)

export const isSmartphoneSizeSelector = createSelector(
  display,
  display => display.isSmartphoneSize,
)

export const isCurrentUserTestingAccountSelector = createSelector(
  currentUserId,
  currentUserId => currentUserId === '2fc95e156fcaa5598eb51873f7224a50',
)
