/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint prefer-destructuring: 0 */
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import getCookie from '@monorepo/shared/getCookie.js'
import signupRest from '@waiterio/api-client/signup.js'
import loginRest from '@waiterio/api-client/login.js'
import loginWithPINRest from '@waiterio/api-client/loginWithPIN.js'
import { setToken as setTokenRest } from '@waiterio/api-client/token.js'
import User from '@waiterio/model/User.js'
import LoginRequest from '@waiterio/model/LoginRequest.js'
import { identify } from './Analytics.js'
import getAllLoginData from './getAllLoginData.js'
import getDeviceForLoginRequest from './getDeviceForLoginRequest.js'
import fetchAllButLoginData from '../fetch/fetchAllButLoginData.js'
import logIP from './logIP.js'
import clearSession from '../session/clearSession.js'
import getPreviousRestaurantId from '../session/getPreviousRestaurantId.js'
import storeNewSession from '../session/storeNewSession.js'
import setWaiterioToken from '../session/setWaiterioToken.js'

let device
let map
let menu
let restaurant
let roles
let subscriptions
let user

// let inviteToken
let googleOauth2Token
let waiterioToken

export async function signup({ email, password, pin, firstName, lastName }) {
  reset()
  await clearSession()
  let previousRestaurantId = getPreviousRestaurantId()

  let loginRequest = createLoginRequest({
    authenticationType: LoginRequest.BASIC_AUTHENTICATION,
    authenticationCredentials: btoa(email + ':' + password + ':' + pin),
    previousRestaurantId,
    user: {
      email,
      firstName,
      lastName,
    },
  })

  const loginResponse = await signupRest(loginRequest)
  onLoginSucceed(loginResponse)

  return loginResponse
}

export async function loginWithEmail(email, password) {
  reset()
  await clearSession()

  let loginRequest = createLoginRequest({
    authenticationType: LoginRequest.BASIC_AUTHENTICATION,
    authenticationCredentials: btoa(email + ':' + password),
  })

  const loginResponse = await loginRest(loginRequest)
  onLoginSucceed(loginResponse)

  return loginResponse
}

export async function loginWithPin(
  oldUserId,
  oldUserWaiterioToken,
  newUserId,
  newUserPin,
) {
  reset()

  let loginRequest = createLoginRequest({
    authenticationType: LoginRequest.PIN_AUTHENTICATION,
    authenticationCredentials: btoa(
      oldUserId +
        ':' +
        oldUserWaiterioToken +
        ':' +
        newUserId +
        ':' +
        newUserPin,
    ),
  })

  const loginResponse = await loginWithPINRest(loginRequest)
  onLoginSucceed(loginResponse)

  return loginResponse
}

export async function loginWithGoogle(googleOauth2Token_) {
  reset()
  await clearSession()
  let previousRestaurantId = getPreviousRestaurantId()

  googleOauth2Token = googleOauth2Token_

  let loginRequest = createLoginRequest({
    authenticationType: LoginRequest.GOOGLE_AUTHENTICATION,
    authenticationCredentials: btoa(googleOauth2Token),
    previousRestaurantId,
  })

  const loginResponse = await loginRest(loginRequest)
  onLoginSucceed(loginResponse)

  return loginResponse
}

export async function onLoginSucceed(loginResponse) {
  device = loginResponse.device
  user = loginResponse.user
  roles = loginResponse.roles
  waiterioToken = loginResponse.waiterioToken
  // inviteToken = loginResponse.inviteToken

  setWaiterioToken(loginResponse.waiterioToken)
  setTokenRest(loginResponse.waiterioToken)
}

export function createLoginRequest(loginRequest) {
  let referrer = getCookie('referrer')
  let referrers = getCookie('referrers')
  // alert('getCookie(referrer)', referrer)
  // alert('getCookie(referrers)', referrers)

  return new LoginRequest({
    ...loginRequest,
    device: getDeviceForLoginRequest(),
    referrer,
    referrers,
    user: new User({
      ...loginRequest.user,
      language: getBrowserLanguage(),
      // TODO inviteToken: ReferrerBroadcastReceiver.getReferrer(waiterioApplication),
    }),
  })
}

export async function reset() {
  device = null
  map = null
  menu = null
  restaurant = null
  roles = null
  subscriptions = null
  user = null
  // inviteToken = null
  googleOauth2Token = null
  waiterioToken = null
}

export async function fetchData(restaurantId) {
  const data = await getAllLoginData(restaurantId)

  restaurant = data.restaurant
  menu = data.menus[0]
  subscriptions = data.subscriptions
  map = data.maps[0]

  await storeNewSession({
    device,
    map,
    menu,
    restaurant,
    roles,
    subscriptions,
    user,
    waiterioToken,
    googleOauth2Token,
  })

  fetchAllButLoginData(restaurantId)

  identify()

  logIP({
    operation: 'login',
  })
}
