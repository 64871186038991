/* Copyright 2013 - 2024 Waiterio LLC */
import put from '@waiterio/api-client/put.js'

export default function updateCard(restaurantId, cardToken) {
  return put({
    url: `subscriptions/update-card?cardToken=${cardToken}&restaurantId=${restaurantId}`,
  }).catch(error => {
    throw new Error(error?.message || "Couldn't update card")
  })
}
