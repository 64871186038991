/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import Restaurant from '@waiterio/model/Restaurant.js'

const prefix = 'waiterio.restaurants.'

export function plantRestaurant(restaurant) {
  return localForage
    .setItem(prefix + restaurant._id, restaurant)
    .then(restaurant => {
      restaurant = new Restaurant(restaurant)

      produce(draft => {
        draft.data.restaurants[restaurant._id] = restaurant
      })

      return restaurant
    })
}

export function burnRestaurant(restaurantId) {
  return localForage.removeItem(prefix + restaurantId).then(() => {
    produce(draft => {
      delete draft.data.restaurants[restaurantId]
    })

    return restaurantId
  })
}

export function pickRestaurant(restaurantId) {
  if (restaurantId) {
    return localForage
      .getItem(prefix + restaurantId)
      .then(restaurant => (restaurant ? new Restaurant(restaurant) : null))
  } else {
    return pickRestaurants().then(restaurants =>
      restaurants && restaurants.length > 0 ? restaurants[0] : null,
    )
  }
}

export function pickRestaurants() {
  return localForage
    .keys()
    .then(keyss =>
      Promise.all(
        keyss
          .filter(key => key.indexOf(prefix) > -1)
          .map(key =>
            localForage
              .getItem(key)
              .then(restaurant => new Restaurant(restaurant)),
          ),
      ),
    )
}
