/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { connect } from '@monorepo/context/index.js'
import IconAndroid from '@stiloso/icons/IconAndroid.js'
import IconApple from '@stiloso/icons/IconApple.js'
import IconWindows from '@stiloso/icons/IconWindows.js'
import ErrorBoundaryGoBack from '../components/ErrorBoundaryGoBack.js'
import Header from '../components/Header.js'
import translate from '../translate/index.js'

const link = css({
  textDecoration: 'underline',
  position: 'relative',
  left: '0.5em',
  ':hover': {
    color: 'var(--color-primary)',
    fill: 'var(--color-primary)',
  },
})

const DownloadsPage = () => (
  <>
    <Header title={translate('Downloads')} href="landing" />
    <div css={{ padding: 16 }}>
      <div css={{ maxWidth: 380, margin: '0 auto' }}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: 'white',
            padding: 16,
            marginBottom: 16,
          }}
        >
          <br />
          <a href="https://www.waiterio.com/install/windows/latest" css={link}>
            Windows
            <IconWindows width="18" height="18" css={{ marginLeft: '0.5em' }} />
          </a>
          <br />
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href="https://www.waiterio.com/install/macos/latest"
            css={link}
            target="_blank"
          >
            macOS
            <IconApple width="18" height="18" css={{ marginLeft: '0.5em' }} />
          </a>
          <br /> {/* eslint-disable-line react/jsx-no-target-blank */}
          <br />
          <a href="https://www.waiterio.com/install/android/latest" css={link}>
            Android
            <IconAndroid width="18" height="18" css={{ marginLeft: '0.5em' }} />
          </a>
          <br />
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href="https://www.waiterio.com/install/ios/latest"
            css={link}
            target="_blank"
          >
            iOS
            <IconApple width="18" height="18" css={{ marginLeft: '0.5em' }} />
          </a>
          <br /> {/* eslint-disable-line react/jsx-no-target-blank */}
        </div>
      </div>
    </div>
  </>
)

const DownloadsPageConnected = connect((context, ownProps, produce) => ({
  ...context.pages.downloads,
  produce: produce(['pages', 'downloads']),
}))(DownloadsPage)

const DownloadsPageWrapper = props => (
  <ErrorBoundaryGoBack>
    <DownloadsPageConnected {...props} />
  </ErrorBoundaryGoBack>
)

export default DownloadsPageWrapper
