/* Copyright 2013 - 2024 Waiterio LLC */
import put from '@waiterio/api-client/put.js'

export default function updateRestaurant(restaurant) {
  return put({ url: 'restaurants/' + restaurant._id, body: restaurant }).catch(
    error => {
      throw new Error(`Couldn't update restaurant\n${error}`)
    },
  )
}
