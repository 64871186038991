/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import Menu from '@waiterio/model/Menu.js'

const prefix = 'waiterio.menus.'

export const plantMenu = menu =>
  localForage.setItem(prefix + menu._id, menu).then(menu => {
    menu = new Menu(menu)

    produce(draft => {
      draft.data.menus[menu._id] = menu
    })

    return menu
  })

export const burnMenu = menuId =>
  localForage.removeItem(prefix + menuId).then(() => {
    produce(draft => {
      delete draft.data.menus[menuId]
    })

    return menuId
  })

export const pickMenus = () =>
  localForage
    .keys()
    .then(keys_ =>
      Promise.all(
        keys_
          .filter(key => key.indexOf(prefix) > -1)
          .map(key => localForage.getItem(key).then(menu => new Menu(menu))),
      ),
    )

export const pickMenu = menuId => {
  if (menuId) {
    return localForage
      .getItem(prefix + menuId)
      .then(menu => (menu ? new Menu(menu) : null))
  } else {
    return pickMenus().then(menus =>
      menus && menus.length > 0 ? menus[0] : null,
    )
  }
}

export const pickCategory = (menuId, categoryId) =>
  pickMenu(menuId).then(menu => {
    if (menu) {
      return menu.getCategory(categoryId)
    }
  })

export const plantCategory = (menuId, category) =>
  pickMenu(menuId).then(menu => {
    if (menu) {
      menu = menu.addOrUpdateCategory(category)

      return plantMenu(menu).then(menu => menu.getCategory(category.id))
    }
  })

export const burnCategory = (menuId, categoryId) =>
  pickMenu(menuId).then(menu => {
    if (menu) {
      menu = menu.removeCategory(categoryId)

      return plantMenu(menu)
    }
  })

export const plantItem = (menuId, categoryId, item) =>
  pickMenu(menuId).then(menu => {
    if (menu) {
      menu = menu.addOrUpdateItem(categoryId, item)

      return plantMenu(menu).then(menu => menu.getItem(categoryId, item.id))
    }
  })

export const pickItem = (menuId, categoryId, itemId) =>
  pickMenu(menuId).then(menu => {
    if (menu) {
      return menu.getItem(categoryId, itemId)
    }
  })

export const burnItem = (menuId, categoryId, itemId) =>
  pickMenu(menuId).then(menu => {
    if (menu) {
      menu = menu.removeItem(categoryId, itemId)

      return plantMenu(menu)
    }
  })
