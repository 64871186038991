/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import Account from '@waiterio/model/Account.js'
import UUID from '@waiterio/model/UUID.js'

const prefix = 'waiterio.accounts.'

export function plantAccount(account) {
  return new Promise((resolve, reject) => {
    if (!account._id) {
      account._id = UUID.generate()
      account.lastEditTime = new Date().getTime()
    }

    if (account.creationTime === 0) {
      account.creationTime = new Date().getTime()
      account.lastEditTime = account.creationTime
    }

    resolve(localForage.setItem(prefix + account._id, account))
  }).then(account => {
    account = new Account(account)

    produce(draft => {
      draft.data.accounts[account._id] = account
    })

    return account
  })
}

export function burnAccount(accountId) {
  return localForage.removeItem(prefix + accountId).then(() => {
    produce(draft => {
      delete draft.data.accounts[accountId]
    })

    return accountId
  })
}

export function pickAccount(accountId) {
  return localForage
    .getItem(prefix + accountId)
    .then(account => (account ? new Account(account) : null))
}

export function pickAccounts() {
  return localForage
    .keys()
    .then(keyss =>
      Promise.all(
        keyss
          .filter(key => key.indexOf(prefix) > -1)
          .map(key =>
            localForage.getItem(key).then(account => new Account(account)),
          ),
      ),
    )
}
