/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'
import clickable from '@waiterio/styles/clickable.js'

export default css`
  label: floating-action-button;
  ${clickable};
  width: 60px;
  min-width: 60px;
  height: 60px;
  min-height: 60px;
  border-radius: 60px;
  background-color: var(--color-primary);
`
