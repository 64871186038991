/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import Terminal from '@waiterio/model/Terminal.js'

const prefix = 'waiterio.terminals.'

export function plantTerminal(terminal) {
  return localForage.setItem(prefix + terminal._id, terminal).then(terminal => {
    terminal = new Terminal(terminal)

    produce(draft => {
      draft.data.terminals[terminal._id] = terminal
    })

    return terminal
  })
}

export function burnTerminal(terminalId) {
  return localForage.removeItem(prefix + terminalId).then(() => {
    produce(draft => {
      delete draft.data.terminals[terminalId]
    })

    return terminalId
  })
}

export function pickTerminal(terminalId) {
  return localForage
    .getItem(prefix + terminalId)
    .then(terminal => (terminal ? new Terminal(terminal) : null))
}

export function pickTerminals() {
  return localForage
    .keys()
    .then(keyss =>
      Promise.all(
        keyss
          .filter(key => key.indexOf(prefix) > -1)
          .map(key =>
            localForage.getItem(key).then(terminal => new Terminal(terminal)),
          ),
      ),
    )
}
