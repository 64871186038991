/* Copyright 2013 - 2024 Waiterio LLC */
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import device from './device/index.js'
import getDisplay from './services/getDisplay.js'

export default function getInitialContext() {
  return {
    connection: {
      online: navigator.onLine,
    },
    data: {
      accounts: {},
      invites: {},
      maps: {},
      meals: {},
      menus: {},
      payments: {},
      plans: {},
      printers: {},
      restaurants: {},
      roles: {},
      subscriptions: {},
      terminals: {},
      users: {},
    },
    dialogs: {
      rateApp: {},
      restaurantName: {},
      restaurantAddress: {},
    },
    device,
    display: getDisplay(),
    language: getBrowserLanguage(),
    pages: {
      landing: {},
      login: {},
      loginWithPin: {},
      forgotpassword: {},
      signup: {},
      orders: {},
      tables: {},
      menu: {},
      staff: {},
      reports: {},
      printing: {},
      restaurant: {},
      terminals: {},
      website: {},
      account: {},
      subscription: {},
      language: {},
      category: {},
      currency: {},
      country: {},
      blog: {},
      article: {},
      bankAccount: {},
      document: {},
      documentation: {},
      extra: {},
      googleMaps: {},
      importMenu: {},
      invite: {},
      item: {},
      legalEntity: {},
      map: {},
      order: {},
      payment: {},
      payments: {},
      plans: {},
      printer: {},
      report: {},
      scanMenu: {},
      serviceCharge: {},
      subscribe: {},
      support: {},
      takePhoto: {},
      tax: {},
      terminal: {},
      translate: {},
      updateCard: {},
      user: {},
    },
    session: {},
    countries: {},
    translations: {},
  }
}
