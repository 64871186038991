/* Copyright 2013 - 2024 Waiterio LLC */
import userAgent from './userAgent.js'

let nativeVersion = null

if (/Waiterio/i.test(userAgent)) {
  let splits = userAgent.split(' ')
  let group = splits.filter(s => s.includes('Waiterio'))[0]
  if (group) {
    nativeVersion = group.split('/')?.[1]
  }
}

export default nativeVersion
