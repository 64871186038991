/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'
import elevated from './elevated.js'

export default css([
  elevated,
  {
    label: 'card',
    backgroundColor: 'white',
    display: 'inherit',
  },
])
