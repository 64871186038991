/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'

import Role from '@waiterio/model/Role.js'

const prefix = 'waiterio.roles.'

export function plantRole(role) {
  return localForage.setItem(prefix + role._id, role).then(role => {
    role = new Role(role)

    produce(draft => {
      draft.data.roles[role._id] = role
    })

    return role
  })
}

export function burnRole(roleId) {
  return localForage.removeItem(prefix + roleId).then(() => {
    produce(draft => {
      delete draft.data.roles[roleId]
    })

    return roleId
  })
}

export function pickRole(roleId) {
  return localForage
    .getItem(prefix + roleId)
    .then(role => (role ? new Role(role) : null))
}

export function pickRoles() {
  return localForage
    .keys()
    .then(keys_ =>
      Promise.all(
        keys_
          .filter(key => key.indexOf(prefix) > -1)
          .map(key => localForage.getItem(key).then(role => new Role(role))),
      ),
    )
}

export function pickRolesByUserAndByRestaurant(userId, restaurantId) {
  return pickRoles().then(roles =>
    roles.filter(
      role => role.restaurantId === restaurantId && role.userId === userId,
    ),
  )
}
