/* Copyright 2013 - 2024 Waiterio LLC */
function Plan(source) {
  Object.keys(source || {}).forEach(key => {
    this[key] = source[key]
  })

  if (!this.creationTime) {
    this.creationTime = 0
  }

  if (!this.lastEditTime) {
    this.lastEditTime = 0
  }

  if (!this.maxNumberOfOrders) {
    this.maxNumberOfOrders = Number.MAX_VALUE
  }
}

Plan.prototype.constructor = Plan

Plan.BillingFrequency = {
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
}

Plan.Currency = {
  AED: 'AED',
  ALL: 'ALL',
  AOA: 'AOA',
  ARS: 'ARS',
  AUD: 'AUD',
  BAM: 'BAM',
  BDT: 'BDT',
  BGN: 'BGN',
  BOB: 'BOB',
  BRL: 'BRL',
  BWP: 'BWP',
  CAD: 'CAD',
  CHF: 'CHF',
  CLP: 'CLP',
  CNY: 'CNY',
  COP: 'COP',
  CRC: 'CRC',
  CZK: 'CZK',
  DKK: 'DKK',
  DOP: 'DOP',
  DZD: 'DZD',
  EGP: 'EGP',
  ETB: 'ETB',
  EUR: 'EUR',
  GBP: 'GBP',
  GTQ: 'GTQ',
  HKD: 'HKD',
  HNL: 'HNL',
  HRK: 'HRK',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  INR: 'INR',
  JMD: 'JMD',
  JPY: 'JPY',
  KRW: 'KRW',
  KZT: 'KZT',
  LKR: 'LKR',
  MAD: 'MAD',
  MRU: 'MRU',
  MXN: 'MXN',
  MYR: 'MYR',
  NAD: 'NAD',
  NGN: 'NGN',
  NIO: 'NIO',
  NOK: 'NOK',
  NPR: 'NPR',
  NZD: 'NZD',
  PEN: 'PEN',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  PYG: 'PYG',
  QAR: 'QAR',
  RON: 'RON',
  RSD: 'RSD',
  RUB: 'RUB',
  SAR: 'SAR',
  SEK: 'SEK',
  SGD: 'SGD',
  THB: 'THB',
  TRY: 'TRY',
  TWD: 'TWD',
  UAH: 'UAH',
  USD: 'USD',
  UYU: 'UYU',
  VND: 'VND',
  XOF: 'XOF',
  ZAR: 'ZAR',
}

Plan.Type = {
  FREE: 'FREE',
  EXTRA_SMALL: 'EXTRA_SMALL',
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  EXTRA_LARGE: 'EXTRA_LARGE',
}

Plan.Version = {
  MAY_2014: 'MAY_2014',
  JUNE_2015: 'JUNE_2015',
}

Plan.Name = {
  FREE: 'Free',
  EXTRA_SMALL: 'Extra Small',
  SMALL: 'Small',
  MEDIUM: 'Medium',
  LARGE: 'Large',
  EXTRA_LARGE: 'Extra Large',
}

export default Plan
