/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import Meal from '@waiterio/model/Meal.js'

const prefix = 'waiterio.meals.'

const CURRENT_MEAL_THREESHOLD = 21600000 // SIX_HOURS

export function plantMeal(meal) {
  return localForage.setItem(prefix + meal._id, meal).then(meal => {
    meal = new Meal(meal)

    produce(draft => {
      draft.data.meals[meal._id] = meal
    })

    return meal
  })
}

export function burnMeal(mealId) {
  return localForage.removeItem(prefix + mealId).then(() => {
    produce(draft => {
      delete draft.data.meals[mealId]
    })

    return mealId
  })
}

export function pickMeal(mealId) {
  return localForage
    .getItem(prefix + mealId)
    .then(meal => (meal ? new Meal(meal) : null))
}

export function pickMeals() {
  return localForage
    .keys()
    .then(keyss =>
      Promise.all(
        keyss
          .filter(key => key.indexOf(prefix) > -1)
          .map(key => localForage.getItem(key).then(meal => new Meal(meal))),
      ),
    )
}

export function pickActiveMeals() {
  return localForage.keys().then(keyss =>
    Promise.all(
      keyss
        .filter(key => key.indexOf(prefix) > -1)
        .map(key => localForage.getItem(key).then(meal => new Meal(meal))),
    ).then(meals =>
      meals.filter(meal => {
        if (
          meal.lastEditTime <
          new Date().getTime() - CURRENT_MEAL_THREESHOLD
        ) {
          burnMeal(meal._id)
          return false
        } else {
          return true
        }
      }),
    ),
  )
}
