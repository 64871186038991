/* Copyright 2013 - 2024 Waiterio LLC */
// import 'md-gum-polyfill';
import 'core-js/stable/index.js'
import 'regenerator-runtime/runtime.js'
import React from 'react'
import { createRoot } from 'react-dom/client' // eslint-disable-line import/extensions
import { Router } from 'react-router-dom'
import ErrorBoundary from '@waiterio/components/ErrorBoundary.js'
import allowSubdomainsCrossOrigin from '@monorepo/shared/allowSubdomainsCrossOrigin.js'
import getSentryBrowser from '@monorepo/telemetry/getSentryBrowser.js'
import App from './App.js'
import getBrowserHistory from './getBrowserHistory.js'
import getInitialContext from './getInitialContext.js'
import defineGlobalNative from './native/defineGlobalNative.js'

const initialContext = getInitialContext()

allowSubdomainsCrossOrigin()
defineGlobalNative()

createRoot(document.getElementById('root')).render(
  <ErrorBoundary>
    <Router history={getBrowserHistory()}>
      <App initialContext={initialContext} />
    </Router>
  </ErrorBoundary>,
)

getSentryBrowser()
