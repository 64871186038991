/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import UUID from './UUID.js'

function Printer(source) {
  if (source instanceof Printer) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = UUID.generate()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().getTime()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (!this.protocol) {
      this.protocol = Printer.Protocol.ESC_POS
    }

    if (!this.connection) {
      this.connection = Printer.Connection.ETHERNET_WIFI
    }

    if (!this.fontSize) {
      this.fontSize = Printer.FontSize.NORMAL
    }

    if (!this.codeTable) {
      this.codeTable = Printer.CodeTable.PC437.code
    }

    if (!this.ip) {
      this.ip = '192.168.'
    }

    if (!this.charactersPerLine) {
      this.charactersPerLine = 32
    }

    if (!this.printOnlyOrdersWithItemsFromCategoriesIds) {
      this.printOnlyOrdersWithItemsFromCategoriesIds = []
    }

    return Immutable(this, { prototype: Printer.prototype })
  }
}

Printer.prototype.constructor = Printer

Printer.Type = {
  COMPUTER_DRIVER_PRINTER: 'computer_driver_printer',
  EPSON_BLUETOOTH_PRINTER: 'epson_bluetooth_printer',
  EPSON_TCP_PRINTER: 'epson_tcp_printer',
  EPSON_USB_PRINTER: 'epson_usb_printer',
  ESCPOS_BLUETOOTH_PRINTER: 'escpos_bluetooth_printer',
  ESCPOS_TCP_PRINTER: 'escpos_tcp_printer',
  ESCPOS_USB_PRINTER: 'escpos_usb_printer',
  STAR_BLUETOOTH_PRINTER: 'star_bluetooth_printer',
  STAR_TCP_PRINTER: 'star_tcp_printer',
  STAR_USB_PRINTER: 'star_usb_printer',
}

Printer.PaperWidth = {
  TWO_INCHES_58_MM: 'TWO_INCHES_58_MM',
  THREE_INCHES_78_MM: 'THREE_INCHES_78_MM',
  FOUR_INCHES_112_MM: 'FOUR_INCHES_112_MM',
}

Printer.Protocol = {
  COMPUTER_DRIVER: 'COMPUTER_DRIVER',
  EPSON: 'EPSON',
  ESC_POS: 'ESC_POS',
  STAR_MICRONICS: 'STAR_MICRONICS',
}

Printer.Connection = {
  BLUETOOTH: 'BLUETOOTH',
  ETHERNET_WIFI: 'ETHERNET_WIFI',
  USB: 'USB',
}

Printer.FontSize = {
  NORMAL: 'NORMAL',
  EMPHASIZE: 'EMPHASIZE',
  DOUBLE_HEIGHT: 'DOUBLE_HEIGHT',
  DOUBLE_WIDTH: 'DOUBLE_WIDTH',
}

Printer.CodeTable = {
  PC437: { code: 'PC437', description: 'PC437 USA:Standard Europe' },
  PC720: { code: 'PC720', description: 'PC720 Arabic' },
  PC737: { code: 'PC737', description: 'PC737 Greek' },
  PC862: { code: 'PC862', description: 'PC862 Hebrew' },
  PC858: { code: 'PC858', description: 'PC858 Euro' },
  PC866: { code: 'PC866', description: 'PC866 Cyrillic' },
  PC874: { code: 'PC874', description: 'PC874 Thai' },
  PC932: { code: 'PC932', description: 'PC932 Japanese' },
  PC936: { code: 'PC936', description: 'PC936 Simplified Chinese' },
  PC949: { code: 'PC949', description: 'PC949 Korean' },
  PC950: { code: 'PC950', description: 'PC950 Traditional Chinese' },

  KATAKANA: { code: 'KATAKANA', description: 'KATAKANA Japanese' },
  HIRAGANA: { code: 'HIRAGANA', description: 'HIRAGANA Japanese' },

  // PC850: {code: 'PC850', description: 'PC850 Multilingual'},
  // PC852: {code: 'PC852', description: 'PC852 Latin 2'},
  // PC860: {code: 'PC860', description: 'PC860 Portuguese'},
  // PC863: {code: 'PC863', description: 'PC863 Canadian-French'},
  // PC865: {code: 'PC865', description: 'PC865 Nordic'},
  // WPC1252 = {code: 'WPC1252', description: 'WPC1252'},
}

export default Printer
