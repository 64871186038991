/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css`
  label: field;
  display: flex;
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
`
