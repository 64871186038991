/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Component } from 'react'
import { jsx } from '@emotion/react'
import captureExceptionBrowser from '@monorepo/telemetry/captureExceptionBrowser.js'

const DefaultFallbackComponent = ({ error, info }) => {
  let url
  let again

  if (typeof window !== 'undefined') {
    url = new URL(window.location.href)
    again = url.searchParams.get('again')
  }

  let againTryAgain = !again
  let againLogout = again && again !== 'downgrade'
  let againDowngrade = again && again === 'downgrade'

  return (
    <div css={{ padding: 16 }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          margin: '0 auto',
          maxWidth: 500,
        }}
      >
        {againTryAgain && (
          <h1 css={{ textAlign: 'center' }}>Oops, something went wrong</h1>
        )}
        {againLogout && (
          <h1 css={{ textAlign: 'center' }}>Oops, I did it again</h1>
        )}
        {againDowngrade && (
          <h1 css={{ textAlign: 'center' }}>Hit me baby one more time</h1>
        )}
        <p>
          The error:
          <br />
          {error.toString()}
        </p>
        {againTryAgain && (
          <form>
            <input type="hidden" name="again" value="true" />
            <button
              type="submit"
              css={{
                width: '100%',
                lineHeight: '48px',
                textAlign: 'center',
                marginTop: 8,
                textTransform: 'uppercase',
                cursor: 'pointer',
                backgroundColor: 'var(--color-green, LimeGreen)',
                color: 'white',
                fontSize: 20,
                fontWeight: 'bold',
              }}
            >
              Try again
            </button>
          </form>
        )}
        {againLogout && (
          <form action="/logout">
            <input type="hidden" name="again" value="downgrade" />
            <button
              type="submit"
              css={{
                width: '100%',
                lineHeight: '48px',
                textAlign: 'center',
                marginTop: 8,
                textTransform: 'uppercase',
                cursor: 'pointer',
                backgroundColor: 'var(--color-primary, DodgerBlue)',
                color: 'white',
                fontSize: 20,
                fontWeight: 'bold',
              }}
            >
              Logout
            </button>
          </form>
        )}
        {againDowngrade && (
          <form action="/downgrade-version">
            <button
              type="submit"
              css={{
                width: '100%',
                lineHeight: '48px',
                textAlign: 'center',
                marginTop: 8,
                textTransform: 'uppercase',
                cursor: 'pointer',
                backgroundColor: 'var(--color-red, Tomato)',
                color: 'white',
                fontSize: 20,
                fontWeight: 'bold',
              }}
            >
              Downgrade version
            </button>
          </form>
        )}
        <p css={{ overflowWrap: 'break-word' }}>
          Where it occured:
          <br />
          {info.componentStack}
        </p>
      </div>
    </div>
  )
}

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    const { onCatchError } = this.props

    if (onCatchError) {
      onCatchError(error)
    } else {
      captureExceptionBrowser(error)
    }

    this.setState({
      hasError: true,
      error,
      info,
    })
  }

  render() {
    const { hasError, error, info } = this.state
    const { FallbackComponent } = this.props

    if (hasError) {
      if (FallbackComponent) {
        return <FallbackComponent error={error} info={info} />
      } else {
        return <DefaultFallbackComponent error={error} info={info} />
      }
    }

    return this.props.children
  }
}
