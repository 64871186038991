/* Copyright 2013 - 2024 Waiterio LLC */
import { toast } from '../services/Toaster.js'
import { pickRoles } from '../forage/RolesForage.js'
import getBrowserHistory from '../getBrowserHistory.js'
import clearSession from './clearSession.js'
import getCurrentUserId from './getCurrentUserId.js'
import getCurrentRestaurantId from './getCurrentRestaurantId.js'
import getDefaultMenuId from './getDefaultMenuId.js'
import getPreviousAppVersion from './getPreviousAppVersion.js'
import setPreviousAppVersion from './setPreviousAppVersion.js'
import version from '../version.js'

export default function isLoggedInSession() {
  let isLoggedIn = false

  const currentUserId = getCurrentUserId()
  const currentRestaurantId = getCurrentRestaurantId()
  const defaultMenuId = getDefaultMenuId()
  const previousAppVersion = getPreviousAppVersion()

  if (!previousAppVersion) {
    setPreviousAppVersion(version)
  }

  const validCurrentUserId = currentUserId && currentUserId.length > 0
  const validCurrentRestaurantId =
    currentRestaurantId && currentRestaurantId.length > 0
  const validDefaultMenuId = defaultMenuId && defaultMenuId.length > 0

  if (validCurrentUserId && validCurrentRestaurantId && validDefaultMenuId) {
    pickRoles().then(roles => {
      if (
        roles.filter(
          role =>
            role.restaurantId === currentRestaurantId &&
            role.userId === currentUserId,
        ).length === 0
      ) {
        getBrowserHistory().push('/logout')
        toast('You have been logged out due to a change in your roles')
      }
    })

    isLoggedIn = true
  }

  if (!isLoggedIn) {
    clearSession()
  }

  return isLoggedIn
}
