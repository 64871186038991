/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'

function Payment(source) {
  Object.keys(source || {}).forEach(key => {
    this[key] = source[key]
  })

  if (!this.creationTime) {
    this.creationTime = 0
  }

  if (!this.lastEditTime) {
    this.lastEditTime = this.creationTime || 0
  }

  if (!this.type) {
    this.type = Payment.Type.STRIPE_PAYMENT_INTENT
  }

  return Immutable(this, { prototype: Payment.prototype })
}

Payment.prototype.constructor = Payment

Payment.Type = {}
Payment.Type.STRIPE_PAYMENT_INTENT = 'STRIPE_PAYMENT_INTENT'

Payment.Status = {
  CANCELED: 'CANCELED',
  FAILED: 'FAILED',
  SUCCEEDED: 'SUCCEEDED',
}

Payment.Error = {
  CARD_DECLINED: 'CARD_DECLINED',
  GENERIC_DECLINE: 'GENERIC_DECLINE',
  INCORRECT_PIN: 'INCORRECT_PIN',
  OFFLINE_PIN_REQUIRED: 'OFFLINE_PIN_REQUIRED',
  ONLINE_OR_OFFLINE_PIN_REQUIRED: 'ONLINE_OR_OFFLINE_PIN_REQUIRED',
  PIN_TRY_EXCEEDED: 'PIN_TRY_EXCEEDED',
  WITHDRAWAL_COUNT_LIMIT_EXCEEDED: 'WITHDRAWAL_COUNT_LIMIT_EXCEEDED',
}

export default Payment
