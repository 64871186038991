/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'
import button from '@waiterio/styles/button.js'
import red from '@waiterio/styles/red.js'

export default css`
  ${button};
  color: white;
  background-color: ${red};
`
