/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { PureComponent } from 'react'
import Hls from 'hls.js/dist/hls.light.min.js'
import { css, jsx } from '@emotion/react'
import IconPapillon from '@stiloso/icons/IconPapillon.js'
import { connect } from '@monorepo/context/index.js'
import singleLine from '@waiterio/styles/singleLine.js'
import button from '@waiterio/styles/button.js'
import unselectable from '@waiterio/styles/unselectable.js'
import getBrowserHistory from '../getBrowserHistory.js'
import isLoggedInSession from '../session/isLoggedInSession.js'
import { translateSelector } from '../selectors/LocaleSelectors.js'

const shadow = css`
  text-shadow:
    -0.5px -0.5px 0 #0b61a4,
    0.5px -0.5px 0 #0b61a4,
    -0.5px 0.5px 0 #0b61a4,
    0.5px 0.5px 0 #0b61a4;
`

const button_ = css`
  ${button};
  ${singleLine};
  border-width: 1px !important;
  border-color: white !important;
  border-style: solid !important;
  border-radius: 5px !important;
  line-height: 48px !important;
  box-shadow: none !important;
  width: 50%;
  margin: 5px;
  &:not(:disabled):hover {
    background-color: white;
    color: var(--color-primary);
  }
  &:not(:disabled):active {
    background-color: #b2d3ed;
    color: white;
  }
`

class LandingPage extends PureComponent {
  componentDidMount() {
    if (isLoggedInSession()) {
      getBrowserHistory().push('/orders')
    }

    let video = document.getElementById('player')

    if (video) {
      video.setAttribute('webkit-playsinline', true)

      if (Hls.isSupported()) {
        let hls = new Hls()
        hls.loadSource(
          this.props.display.landscape
            ? 'https://videos.waiterio.com/landing-landscape.36e762d81458ade3c18911b70e736695.m3u8'
            : 'https://videos.waiterio.com/landing-portrait.2b19976bc4fdf1b9d75b206752f436f4.m3u8',
        )
        hls.attachMedia(video)
        hls.on(Hls.Events.MANIFEST_PARSED, async () => {
          try {
            await video.play()
          } catch (error) {
            // ignore it
          }
        })
      }
    }
  }

  render() {
    const { device, display, translate } = this.props

    let querystring = ''

    if (typeof window !== 'undefined') {
      querystring = window.location.search
    }

    return (
      <div css={{ width: '100%', height: '100%' }}>
        <video
          id="player"
          css={{
            width: '100vw',
            height: '100vh',
            objectFit: 'cover',
            position: 'fixed',
            backgroundColor: 'var(--color-primary)',
            opacity: 0.35,
          }}
          autoPlay
          preload="auto"
          playsInline
          muted
          loop
        >
          <source
            src={
              display.landscape
                ? 'https://videos.waiterio.com/landing-landscape.36e762d81458ade3c18911b70e736695.m3u8'
                : 'https://videos.waiterio.com/landing-portrait.2b19976bc4fdf1b9d75b206752f436f4.m3u8'
            }
            type="application/x-mpegURL"
          />
        </video>
        <div
          css={[
            unselectable,
            {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              height: '100%',
              backgroundColor: 'var(--color-primary)',
              width: '100vw',
              color: 'white',
              zIndex: 1,
            },
          ]}
        >
          <div
            css={[
              shadow,
              {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 60,
                paddingTop: 20,
                zIndex: 1,
              },
            ]}
          >
            <IconPapillon width="60" height="60" css={{ fill: 'white' }} />
          </div>
          <div
            css={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              zIndex: 1,
              maxWidth: '48ch',
              margin: '0 auto',
              padding: '0 16px 60px 16px',
            }}
          >
            <div
              css={[
                shadow,
                {
                  textAlign: 'center',
                  fontSize: '6vmin',
                  fontWeight: 'bold',
                  marginTop: 20,
                },
              ]}
            >
              {translate('Handle orders faster')}
            </div>
          </div>
          <div
            css={[
              shadow,
              {
                display: 'flex',
                alignItems: 'stretch',
                width: '100%',
                maxWidth: 500,
                margin: '0 auto',
                padding: 5,
                boxSizing: 'border-box',
                zIndex: 1,
              },
            ]}
          >
            <div
              css={button_}
              onClick={_ => getBrowserHistory().push('signup' + querystring)}
              data-testid="signup"
            >
              {translate('Sign up')}
            </div>
            <div
              css={button_}
              onClick={_ => getBrowserHistory().push('login' + querystring)}
              data-testid="login"
            >
              {translate('Login')}
            </div>
          </div>
          {device.iosNative && (
            <div
              css={{
                height: 20,
                width: '100%',
                backgroundColor: 'var(--color-primary)',
              }}
            />
          )}
        </div>
      </div>
    )
  }
}

export default connect((context, props, produce) => ({
  device: context.device,
  display: context.display,
  translate: translateSelector(context),
}))(LandingPage)
