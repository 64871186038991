/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'

const prefix = 'waiterio.translations.'

export function plantTranslation(locale, translation) {
  return localForage.setItem(prefix + locale, translation).then(translation => {
    produce(draft => {
      draft.translations[translation.locale] = translation
    })

    return translation
  })
}

export function burnTranslation(locale) {
  return localForage.removeItem(prefix + locale).then(() => {
    produce(draft => {
      delete draft.translations[locale]
    })

    return locale
  })
}

export function pickTranslation(locale) {
  return localForage.getItem(prefix + locale)
}

export function pickTranslations() {
  return localForage
    .keys()
    .then(keys_ =>
      Promise.all(
        keys_
          .filter(key => key.indexOf(prefix) > -1)
          .map(key => localForage.getItem(key)),
      ),
    )
}
